<template>
  <div class="search-bar">
    <div class="wrapper">
      <img src="../assets/icons/ic_search.png" alt="" />
      <input
        type="search"
        :placeholder="placeholder || 'type in something to search'"
        :value="modelValue"
        v-on:keyup.enter="onEnter"
        @input="onChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    placeholder: String,
    modelValue: String,
  },
  methods: {
    onEnter() {
      this.$emit("onEnter", this.modelValue);
    },
    onChange(e) {
      console.log("this.modelValue = ", this.modelValue);
      this.$emit("onEnter", e.target.value);
      this.$emit("update:modelValue", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  width: 100%;
  background: var(--clr-nine-green-light);
  padding: 1rem;

  .wrapper {
    width: 100%;
    height: 4rem;
    background: white;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    padding: 1rem;

    img {
      height: 1.5rem;
      filter: invert(94%) sepia(95%) saturate(7147%) hue-rotate(84deg)
        brightness(100%) contrast(83%);
    }

    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      margin-left: 1rem;
      font-size: 1.4rem;
      font-family: "P-300", sans-serif;
    }

    input[type="search"]::-webkit-search-cancel-button {
      filter: invert(0%) sepia(6%) saturate(24%) hue-rotate(268deg)
        brightness(107%) contrast(107%);
      margin-left: 0.5rem;
    }
  }
}
</style>
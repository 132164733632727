<template>
  <div>
    <router-view />

    <div class="notification" id="notificationBar">
      <p id="notificationMsg"></p>
    </div>

    <div class="loading-container" id="loading">
      <div class="card">
        <div class="spinner"></div>
        Loading ...
      </div>
    </div>
  </div>
</template>




<style lang="scss">
@font-face {
  font-family: "P-100";
  src: url("./assets/fonts/Poppins-Thin.ttf");
}

@font-face {
  font-family: "P-200";
  src: url("./assets/fonts/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: "P-300";
  src: url("./assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "P-400";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "P-500";
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "P-600";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "P-700";
  src: url("./assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "P-800";
  src: url("./assets/fonts/Poppins-Bold.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  font-family: "P-300", sans-serif;

  --bg-card: white;
  --card-border: 0.4rem;
  --btn-radius: 0.4rem;
  --shadow: 0rem 0rem 1rem 0.1rem rgba(0, 0, 0, 0.1);
  --btn-height: 5rem;
  --logo-radius: 1rem;
  --page-padding: 2rem;

  --clr-nine-green: #1de9b6;
  --clr-nine-green-light: #1de9b638;
  --clr-nine-green-very-light: rgba(29, 233, 182, 0.082);
  --nav-bg: rgba(248, 231, 215);
  --clr-skin: rgba(248, 231, 215);
  --clr-skin-light: rgba(248, 231, 215, 0.5);
  --red: #ff1744;
  --yellow: #000;

  .dp__theme_light {
    --dp-background-color: #ffffff;
    --dp-text-color: black;
    --dp-hover-color: #f4f4f4;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: var(--clr-nine-green);
    --dp-primary-text-color: black;
    --dp-secondary-color: lightgray;
    --dp-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;

    .dp__icon {
      width: 2rem;
      height: 2rem;
    }
  }

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  #logo {
    width: 10rem;
    border: 0.3rem solid black;
    border-radius: var(--logo-radius);
    box-shadow: var(--shadow);
  }

  h1 {
    font-size: 2rem;
    font-family: "P-600", sans-serif;
  }

  h2 {
    font-size: 1.8rem;
    font-family: "P-600", sans-serif;
  }
  select {
    height: 3.4rem;
    border: none;
    border-bottom: 0.1rem solid lightgray;
    background: white;

    outline: none;

    font-size: 1.4rem;
    color: black;
    font-family: "P-300", sans-serif;
    text-align: left;
  }
}

.notification {
  background: rgb(50, 172, 50);
  position: absolute;
  bottom: 0%;
  right: -200%;
  max-width: calc(100vw - 4rem);
  max-height: calc(100vh - 4rem);
  z-index: 1000;
  /* padding: 1.4rem 1.8rem; */
  border-radius: 0.6rem;
  overflow-y: scroll;
  box-shadow: var(--shadow);
  transition: all 1s ease-in;

  p {
    color: white;
    font-family: "P-400", sans-serif;
    font-size: 1.3rem;
  }
}

.loading-container {
  position: absolute;
  top: -100%;
  left: -100%;
  width: 100vw;
  height: 100vh;
  background: rgba(128, 128, 128, 0.385);
  z-index: 60;
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    background: white;
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-family: "P-500", sans-serif;
    border-radius: 0.6rem;
    padding: 3rem 4rem;

    .spinner {
      border-top: 0.3rem solid var(--clr-ylw);
      border-left: 0.3rem solid var(--clr-ylw);
      border-right: 0.3rem solid var(--clr-ylw);
      border-bottom: 0.3rem solid transparent;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      animation: spinner 1s linear infinite;
      margin-bottom: 2rem;
    }
  }
}

.no-data {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-size: 1.2rem;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
  opacity: 1;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.switches {
  height: 4rem;

  .inner {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    .switch {
      height: 3.8rem;
      line-height: 3.8rem;
      font-size: 1.3rem;
      font-family: "P-500";
      transition: 0.25s all ease-in-out;
      text-align: center;
      width: 10rem !important;
      cursor: pointer;
      color: gray;

      &:hover {
        color: var(--clr-nine-green);
      }
    }

    .selected {
      color: black;
      font-family: "P-700";
    }
  }

  .line {
    width: 50%;
    height: 0.3rem;
    border-radius: 1rem;
    background: var(--clr-nine-green);
    transition: 0.25s all ease-in-out;
  }

  .line-selected {
    transform: translateX(100%);
  }
}
</style>

<template>
  <div class="full-page flex-column page-padding">
    <div class="flex-row" style="margin-bottom: 2rem">
      <div class="page-nav" @click="$router.back()">
        <img
          src="../../../assets/icons/ic_arrow_down.png"
          alt="Back"
          class="back-btn"
        />
        <span class="page-title">Create New Event</span>
        <div class="processing" v-if="processing"></div>
      </div>

      <div class="page-actions" style="margin-left: auto">
        <button @click="onClickSave">Save</button>
        <button @click="$router.back()" class="negative">ABORT</button>
      </div>
    </div>

    <div class="wrapper">
      <div class="top">
        <div class="top-left">
          <div class="card flex-column">
            <span class="page-title">DETAILS</span>
            <div class="inner">
              <inputView
                id="title"
                name="Title"
                v-model="data.title"
                class="input"
                :enableInput="!processing"
                placeholder="Event event title"
                type="string"
              ></inputView>

              <inputView
                id="location"
                name="Location"
                v-model="data.location"
                class="input"
                :enableInput="!processing"
                placeholder="Event event location"
                type="string"
              ></inputView>

              <inputView
                id="startTime"
                name="Start Time"
                v-model="data.startTime"
                class="input"
                :enableInput="!processing"
                placeholder="Event event start time"
                type="datetime-local"
              ></inputView>

              <inputView
                id="userNumber"
                name="Maximum # User Allowed"
                v-model="data.userNumber"
                class="input"
                :enableInput="!processing"
                placeholder="Event event location"
                type="number"
              ></inputView>

              <inputView
                id="evenlimitUserTypetType"
                name="User Type Allowed"
                v-model="data.limitUserType"
                class="input"
                :enableInput="!processing"
                placeholder="Users type allowed"
                type="select"
                :options="userTypeOptions"
              ></inputView>

              <inputView
                id="eventType"
                name="event Type"
                v-model="data.eventType"
                class="input"
                :enableInput="!processing"
                placeholder="Online or Offline"
                type="select"
                :options="eventTypeOptions"
              ></inputView>

              <inputView
                id="eventStatus"
                name="Event Status"
                v-model="data.eventStatus"
                class="input"
                :enableInput="!processing"
                placeholder="In Progress , Started or Ended"
                type="select"
                :options="eventStatusTypes"
              ></inputView>

              <inputView
                id="publishStatus"
                name="Publish Status"
                v-model="data.publishStatus"
                class="input"
                :enableInput="!processing"
                placeholder="UnPublished or Published"
                type="select"
                :options="publishStatuses"
              ></inputView>

              <inputView
                id="allowSignUp"
                name="Allow SignUp"
                v-model="data.allowSignUp"
                class="input"
                :enableInput="!processing"
                placeholder="Yes or No"
                type="select"
                :options="allowSignUps"
              ></inputView>
            </div>

            <div class="content-address">
              <div class="ta-view">
                <label class="contentLabel" for="address">Address</label>
                <textarea
                  id="address"
                  class="notes"
                  v-model="data.address"
                  @input="onAddressChange"
                  placeholder="Event address ... ✌"
                ></textarea>
              </div>

              <div class="ta-view">
                <label class="contentLabel" for="address">Content</label>

                <textarea
                  id="content"
                  class="notes"
                  v-model="data.content"
                  placeholder="Enter event content helike ... ✌"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="top-right">
          <div class="card flex-column">
            <span class="page-title">Host</span>

            <div class="host">
              <div class="content">
                <div class="left">
                  <div class="fields">
                    <inputView
                      id="nickName"
                      type="text"
                      placeholder="Enter a host name"
                      v-model="userSearchString"
                      class="input"
                      @onUpdate="onEnterSearch"
                      :enableInput="!processing"
                      :needBubble="false"
                    ></inputView>

                    <buttonView title="Search" @click="getUsers"></buttonView>
                  </div>
                </div>
                <div class="right">
                  <div
                    class="processing"
                    v-if="processing"
                    style="margin: auto; margin-top: 80%"
                  ></div>
                  <div v-else style="height: 100%">
                    <div v-if="filteredUser && filteredUser.length > 0">
                      <div v-for="user in filteredUser" v-bind:key="user.id">
                        <div
                          class="user"
                          @click="onClickUser(user)"
                          :class="{
                            selected:
                              selectedUser && selectedUser.id == user.id,
                          }"
                        >
                          <div class="check">
                            <div
                              class="checked"
                              v-if="selectedUser && selectedUser.id == user.id"
                            ></div>
                          </div>
                          <img
                            v-if="user.realPicture"
                            :src="user.realPicture"
                            alt=""
                            class="avatar"
                          />
                          <div v-if="!user.realPicture" class="no-avatar">
                            {{ user.nickName ? user.nickName[0] : "" }}
                          </div>
                          <div class="info">
                            <span class="name">{{ user.nickName }}</span>
                            <span class="gender">{{ user.gender }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="no-data">
                      <img src="../../../assets/svg/empty.svg" alt="" />
                      {{
                        !userSearchString
                          ? "enter user nickname to saerch"
                          : "No user found"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card flex-column" style="margin-top: 3rem">
        <span class="page-title">Images</span>
        <div class="images">
          <div v-for="(image, index) in files" :key="image">
            <div class="add-image" v-if="index == 0">
              <img src="../../../assets/icons/ic_add.png" alt="" />
              <span>Click here to select images</span>
              <input
                type="file"
                multiple
                accept="image/png, image/gif, image/jpeg"
                @change="addImage"
              />
            </div>

            <div class="preview" v-else>
              <img class="pre" :src="image.url" alt="" />
              <div class="overlay">
                <div class="delete" @click="deleteImage(index)">
                  <img
                    class="del"
                    src="../../../assets/icons/ic_delete.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uploading" v-if="saving">
      <div class="processing"></div>
      <span>{{ uploading_msgs }}</span>
    </div>
  </div>
</template>

<script>
import InputView from "../../../components/InputView.vue";
import ButtonView from "../../../components/ButtonView.vue";

export default {
  name: "CreateEvent",
  components: {
    InputView,
    buttonView: ButtonView,
  },
  data() {
    return {
      processing: false,
      data: {},
      userTypeOptions: [
        {
          key: 0,
          value: "All",
        },
        {
          key: 1,
          value: "Verified",
        },
        {
          key: 2,
          value: "VIP",
        },
      ],
      eventTypeOptions: [
        {
          key: 1,
          value: "Online",
        },
        {
          key: 2,
          value: "Offline",
        },
      ],
      eventStatusTypes: [
        {
          key: "in_progress",
          value: "In Progress",
        },
        {
          key: "started",
          value: "Started",
        },
        {
          key: "ended",
          value: "Ended",
        },
      ],
      publishStatuses: [
        {
          key: "unpublished",
          value: "UnPublished",
        },
        {
          key: "published",
          value: "Published",
        },
      ],
      allowSignUps: [
        {
          key: true,
          value: "Yes",
        },
        {
          key: false,
          value: "No",
        },
      ],
      files: [""],
      rawData: [],
      userSearchString: "",
      processing: false,
      users: [],
      filteredUser: [],
      selectedUser: null,
      uploading_msgs: "Uploading...",
      saving: false,
    };
  },
  methods: {
    deleteImage(index) {
      console.log(index);
      this.files.splice(index, 1);
    },
    addImage(e) {
      const tmpFiles = e.target.files;
      console.table(tmpFiles);
      if (tmpFiles.length === 0) {
        return false;
      }
      const self = this;

      for (var f in tmpFiles) {
        const file = tmpFiles[f];

        this.files.push({
          file: file,
          url: URL.createObjectURL(file),
        });

        // const reader = new FileReader();
        // reader.onload = function (e) {
        //   self.rawData.push(e.target.result);
        // };
        // reader.readAsDataURL(file);
      }
    },
    onClickUser(user) {
      this.selectedUser = user;
    },
    getUsers() {
      console.log("this.userSearchString = ", this.userSearchString);
      if (!this.userSearchString) {
        this.$Notifications.showNotification(
          "warning",
          "Please enter a nickname"
        );
        return;
      }
      this.processing = true;
      var order = `createdAt DESC`;
      let d = {
        nickName: {
          like: this.userSearchString,
          options: "i",
        },
      };
      this.$HTTP
        .post(
          `${this.$URLS.USERS.LIST}?order=${order}&page=1&results_per_page=25`,
          d
        )
        .then((res) => {
          this.users = res.data.data.users;
          this.filteredUser = res.data.data.users;
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onEnterSearch(e) {
      this.userSearchString = e.value;
    },
    async onClickSave() {
      if (!this.data.title) {
        this.$Notifications.showNotification(
          "danger",
          "Please enter event title"
        );
        return;
      }

      if (!this.data.address) {
        this.$Notifications.showNotification(
          "danger",
          "Please enter event address"
        );
        return;
      }

      if (!this.data.location) {
        this.$Notifications.showNotification(
          "danger",
          "Please enter event location"
        );
        return;
      }

      if (!this.data.startTime) {
        this.$Notifications.showNotification(
          "danger",
          "Please enter event start time"
        );
        return;
      }

      if (!this.data.userNumber) {
        this.$Notifications.showNotification(
          "danger",
          "Please enter a number for max attendees allowed"
        );
        return;
      }

      if (!this.data.limitUserType) {
        this.$Notifications.showNotification(
          "danger",
          "Please select a type for users allowed"
        );
        return;
      }

      if (!this.data.eventType) {
        this.$Notifications.showNotification(
          "danger",
          "Please select a type for the event"
        );
        return;
      }

      if (!this.data.eventStatus) {
        this.data.eventStatus = "in_progress";
      }

      if (!this.data.content) {
        this.$Notifications.showNotification(
          "danger",
          "Please enter some event content"
        );
        return;
      }

      if (!this.selectedUser) {
        this.$Notifications.showNotification(
          "danger",
          "Please select a host for the event"
        );
        return;
      }

      if (!this.files || this.files.length <= 1) {
        this.$Notifications.showNotification(
          "danger",
          "Please select atleast one image for the event"
        );
        return;
      }

      this.saving = true;
      let uploads = await this.uploadImages();
      this.data.hostId = this.selectedUser.id;
      this.data.eventImages = uploads;

      this.$HTTP
        .post(this.$URLS.EVENTS.ADD, this.data)
        .then((res) => {
          console.log(res.data);
          this.saving = false;
          this.$router.back();
        })
        .catch((err) => {
          this.saving = false;
        });
    },
    uploadImages() {
      return new Promise(async (resolve, reject) => {
        let index = 1;
        let uploadedFiles = [];
        while (index < this.files.length) {
          const { ok, data, err } = await this.uploadFile(this.files[index]);
          uploadedFiles.push(data.result);
          console.log(data);
          index++;
        }
        resolve(uploadedFiles);
      });
    },
    uploadFile(file) {
      console.log(file);
      return new Promise((resolve, reject) => {
        var formData = new FormData();
        formData.append("file", file.file);
        formData.append("imgType", "events");
        this.$HTTP
          .post(this.$URLS.UPLOAD, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            resolve({
              ok: true,
              data: res.data,
            });
          })
          .catch((err) => {
            resolve({
              ok: false,
              data: null,
              err: err,
            });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.uploading {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .processing {
    width: 10rem !important;
    height: 10rem !important;
  }
  span {
    margin-top: 3rem;
    color: white;
    font-size: 1.4rem;
  }
}
.top {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 60rem;
  gap: 3rem;

  .top-right {
    width: 100%;
  }
}
.wrapper {
  width: 100%;
  height: 89vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 0.2rem;

  .inner {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    padding-top: 3rem;
  }

  .content-address {
    width: 100%;
    display: grid;
    grid-template-columns: 33.5% 1fr;
    gap: 1rem;
    margin-top: 3rem;

    .ta-view {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .contentLabel {
      font-size: 1.2rem;
      color: gray;
      font-family: "P-500", sans-serif;
      text-transform: uppercase;
      padding-left: 0.2rem;
      height: 2.5rem;
    }
  }

  .notes {
    resize: none;
    max-width: 100vw;
    height: 20rem;
    padding: 1rem;
    border: 0.1rem solid lightgray !important;
  }

  .images {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    padding-top: 3rem;
    align-items: center;

    .preview {
      position: relative;
      border-radius: 0.4rem;
      overflow: hidden;
      box-shadow: var(--shadow);

      .pre {
        width: 100%;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        padding: 2rem;
        display: flex;
        width: 100%;
        height: 100%;
        transition: 0.15s all ease-in-out;

        &:hover {
          background: rgba($color: #fff, $alpha: 0.6);
        }

        .delete {
          width: 3rem !important;
          height: 3rem !important;
          margin-left: auto;
          border-radius: 50%;
          border: 0.4rem solid red;
          padding: 0.4rem;
          transition: 0.15s all ease-in-out;

          .del {
            width: 100%;
          }

          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }
    .add-image {
      width: 100%;
      height: 20rem;
      background: var(--clr-nine-green-very-light);
      border: 0.4rem dashed var(--clr-nine-green);
      border-radius: 0.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: 0.15s all ease-in-out;

      &:hover {
        background: var(--clr-nine-green-light);
      }

      img {
        width: 4rem;
        height: 4rem;
        margin-bottom: 3rem;
      }

      span {
        font-size: 1.4rem;
        font-family: "P-500";
      }

      input {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent;
        opacity: 0;
      }
    }
  }
}

.host {
  width: 100%;
  height: 51.8rem;
  .content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 7rem 1fr;

    .left {
      padding-right: 2rem;
      width: 100%;
      min-height: 100%;
      display: flex;
      .fields {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3rem;
      }
    }

    .right {
      width: 100%;
      background: #f5f5f5;
      margin-right: -20rem;
      border-top-right-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
      overflow-y: scroll;

      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 1.2rem;
        color: gray;
        font-family: "P-600";
        text-transform: lowercase;

        img {
          margin-bottom: 2rem;
          height: 60%;
        }
      }
      .selected {
        background: #e0f2f1;
      }
      .user {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        border-bottom: 0.1rem solid #e0e0e0;
        transition: 0.1s all ease-in-out;

        &:hover {
          background: #eeeeee;
        }

        .check {
          width: 2rem;
          height: 2rem;
          border: 0.2rem solid lightgrey;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .checked {
            width: 1.2rem;
            height: 1.2rem;
            background: var(--clr-nine-green);
            border-radius: 50%;
          }
        }

        .avatar {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          overflow-y: hidden;
          margin: 0rem 2rem;
          border: 0.1rem solid lightgrey;
        }

        .no-avatar {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          overflow-y: hidden;
          margin: 0rem 2rem;
          background: var(--clr-skin);
          border: 0.1rem solid var(--clr-nine-green);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.6rem;
          font-family: "P-600";
          text-transform: uppercase;
        }

        .info {
          display: flex;
          flex-direction: column;

          .name {
            width: 100%;
            font-size: 1.4rem;
            font-family: "P-500";
            color: black;
          }

          .gender {
            width: 100%;
            font-size: 1.2rem;
            font-family: "P-400";
            color: gray;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="popup">
    <div class="card w-30">
      <h1 class="card-title">Change Password</h1>

      <div class="card-content">
        <h3>
          You are about to change your admin account password. Please enter new
          password and repeat it to make sure.
        </h3>

        <div class="current">
          <h4>Your account details :</h4>
          <div class="grid">
            <div class="flex-column">
              <span class="key">ID</span>
              <span class="val">{{ id }}</span>
            </div>
            <div class="flex-column">
              <span class="key">Email</span>
              <span class="val">{{ email }}</span>
            </div>
          </div>
        </div>

        <div class="inputs">
          <inputView
            id="password"
            type="password"
            placeholder="Please enter new password"
            name="New Password"
            v-model="password"
            class="input"
            :enableInput="!processing"
            :needBubble="false"
          ></inputView>
          <inputView
            id="repeatPassword"
            type="password"
            placeholder="Please enter new password again"
            name="Repeat new Password"
            v-model="repeat"
            class="input"
            :enableInput="!processing"
            :needBubble="false"
          ></inputView>
        </div>
      </div>
      <div class="actions">
        <button class="bt-simple" @click="$emit('onAbort')">Abort</button>
        <buttonView
          title="Save"
          :processing="processing"
          :enabled="password === repeat"
          @click="onClickSave"
        ></buttonView>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "./Popup.vue";
import InputView from "../InputView.vue";
import ButtonView from "../ButtonView.vue";

export default {
  name: "UpdateAdminPasswordPopup",
  components: {
    popup: Popup,
    inputView: InputView,
    buttonView: ButtonView,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      processing: false,
      code: null,
      randomCode: null,
      key: null,
      id: null,
      email: null,
      password: "",
      repeat: null,
    };
  },
  methods: {
    processSave() {
      return true;
    },
    onClickSave() {
      if (this.password != this.repeat) return;
      this.processing = true;

      this.$HTTP
        .patch(this.$URLS.ADMIN.CHANGE_PASSWORD, { password: this.password })
        .then((res) => {
          console.log("res");
          console.log(res);
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            "Password changed successfully !"
          );
          this.$emit("onSuccess");
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
          this.processing = false;
        });
    },
    onUpdateCode(e) {
      console.log(`The code entered is ${e}`);
    },
  },
  mounted() {
    this.id = localStorage.getItem("id");
    this.email = localStorage.getItem("email");
    console.log(this.email);
  },
};
</script>

<style lang="scss" scoped>
.inputs {
  margin: 2rem 0rem;
  display: grid;
  gap: 1rem;
}
</style>

<template>
  <div class="container">
    <div class="inner">
      <div class="web" v-html="h5"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HtmlView",
  props: ["h5"],
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 345px;
  height: 709px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  overflow: hidden;

  .inner {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    overflow-y: scroll;
    border: 1rem solid black;
    box-shadow: 0rem 0rem 1rem 0rem gray;

    .web {
      width: 100%;
    }
  }
}
</style>

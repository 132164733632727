<template>
  <div class="popup">
    <div class="card w-60">
      <div class="content">
        <div class="left">
          <h1 class="card-title">Start New Chat</h1>
          <h3>Search user by nickname</h3>
          <div class="fields">
            <inputView
              id="nickName"
              type="text"
              placeholder="Enter a user nickanem"
              name="User Nickname"
              v-model="userSearchString"
              class="input"
              @onUpdate="onEnterSearch"
              :enableInput="!processing"
              :needBubble="false"
            ></inputView>

            <buttonView title="Search" @click="getUsers"></buttonView>
          </div>

          <div class="actions">
            <button class="bt-simple" @click="$emit('abort')">Abort</button>
            <buttonView
              :enabled="selectedUser"
              title="Chat"
              @click="onChat"
            ></buttonView>
          </div>
        </div>
        <div class="right">
          <div
            class="processing"
            v-if="processing"
            style="margin: auto; margin-top: 80%"
          ></div>
          <div v-else style="height: 100%">
            <div v-if="filteredUser && filteredUser.length > 0">
              <div v-for="user in filteredUser" v-bind:key="user.id">
                <div
                  class="user"
                  @click="onClickUser(user)"
                  :class="{
                    selected: selectedUser && selectedUser.id == user.id,
                  }"
                >
                  <div class="check">
                    <div
                      class="checked"
                      v-if="selectedUser && selectedUser.id == user.id"
                    ></div>
                  </div>
                  <img
                    v-if="user.realPicture"
                    :src="user.realPicture"
                    alt=""
                    class="avatar"
                  />
                  <div v-if="!user.realPicture" class="no-avatar">
                    {{ user.nickName ? user.nickName[0] : "" }}
                  </div>
                  <div class="info">
                    <span class="name">{{ user.nickName }}</span>
                    <span class="gender">{{ user.gender }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="no-data">
              <img src="../../assets/svg/empty.svg" alt="" />
              {{
                !userSearchString
                  ? "enter user nickname to saerch"
                  : "No user found"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputView from "../InputView.vue";
import ButtonView from "../ButtonView.vue";

export default {
  name: "Popup",
  components: {
    inputView: InputView,
    buttonView: ButtonView,
  },
  data() {
    return {
      userSearchString: "",
      processing: false,
      users: [],
      filteredUser: [],
      selectedUser: null,
    };
  },
  methods: {
    onChat() {
      if (!this.selectedUser) {
        return;
      }
      this.$emit("success", this.selectedUser);
    },
    onClickUser(user) {
      this.selectedUser = user;
    },
    getUsers() {
      console.log("this.userSearchString = ", this.userSearchString);
      if (!this.userSearchString) {
        this.$Notifications.showNotification(
          "warning",
          "Please enter a nickname"
        );
        return;
      }
      this.processing = true;
      var order = `createdAt DESC`;
      let d = {
        nickName: {
          like: this.userSearchString,
          options: "i",
        },
      };
      this.$HTTP
        .post(
          `${this.$URLS.USERS.LIST}?order=${order}&page=1&results_per_page=25`,
          d
        )
        .then((res) => {
          this.users = res.data.data.users;
          this.filteredUser = res.data.data.users;
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onEnterSearch(e) {
      this.userSearchString = e.value;
      console.log(this.userSearchString);
      return;
      if (!this.userSearchString || this.userSearchString == "") {
        this.filteredUser = this.users;
        return;
      }
      this.filteredUser = this.users.filter((item) => {
        return (
          item.nickName &&
          item.nickName
            .toLowerCase()
            .includes(this.userSearchString.toLowerCase())
        );
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 60vh;
  display: flex;

  .left {
    padding-right: 2rem;
    width: 45%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .actions {
      margin-top: auto;
    }
  }

  .right {
    width: 55%;
    min-height: 100%;
    background: #f5f5f5;
    margin-right: -20rem;
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    overflow-y: scroll;

    .no-data {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-size: 1.2rem;
      color: gray;
      font-family: "P-600";
      text-transform: lowercase;

      img {
        margin-bottom: 2rem;
        width: 60%;
      }
    }
    .selected {
      background: #e0f2f1;
    }
    .user {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 2rem;
      border-bottom: 0.1rem solid #e0e0e0;
      transition: 0.1s all ease-in-out;

      &:hover {
        background: #eeeeee;
      }

      .check {
        width: 2rem;
        height: 2rem;
        border: 0.2rem solid lightgrey;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .checked {
          width: 1.2rem;
          height: 1.2rem;
          background: var(--clr-nine-green);
          border-radius: 50%;
        }
      }

      .avatar {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        overflow-y: hidden;
        margin: 0rem 2rem;
        border: 0.1rem solid lightgrey;
      }

      .no-avatar {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        overflow-y: hidden;
        margin: 0rem 2rem;
        background: var(--clr-skin);
        border: 0.1rem solid var(--clr-nine-green);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        font-family: "P-600";
        text-transform: uppercase;
      }

      .info {
        display: flex;
        flex-direction: column;

        .name {
          width: 100%;
          font-size: 1.4rem;
          font-family: "P-500";
          color: black;
        }

        .gender {
          width: 100%;
          font-size: 1.2rem;
          font-family: "P-400";
          color: gray;
        }
      }
    }
  }
}
</style>
<template>
  <div class="sorter">
    <iv
      id="sortBy"
      type="select"
      placeholder="sort by field"
      name="SORT BY"
      v-model="sortBy"
      :options="schema.sortByOptions"
      @onUpdate="onUpdate"
      :hideReset="true"
      :enableInput="true"
      :needBubble="false"
    ></iv>
    <div class="bottom">
      <button
        @click="onToggle(schema.orderOptions[0])"
        style="margin-right: auto"
        :class="{
          disabled: selected != schema.orderOptions[0].key,
          enabled: selected == schema.orderOptions[0].key,
        }"
      >
        {{ schema.orderOptions[0].val }}
      </button>
      <button
        @click="onToggle(schema.orderOptions[1])"
        style="margin-left: auto"
        :class="{
          disabled: selected != schema.orderOptions[1].key,
          enabled: selected == schema.orderOptions[1].key,
        }"
      >
        {{ schema.orderOptions[1].val }}
      </button>
    </div>
  </div>
</template>

<script>
import InputView from "./InputView.vue";

export default {
  name: "Toggler",
  props: {
    schema: Object,
    preIndex: String,
    preSortBy: String,
  },
  components: {
    iv: InputView,
  },
  watch: {
    preIndex: function (newVal, oldVal) {
      this.preSelect(newVal);
    },
    preSortBy: function (newVal, oldVal) {
      this.preSelectSortBy(newVal);
    },
  },
  data() {
    return {
      selected: "",
      sortBy: "",
    };
  },
  methods: {
    onUpdate(item) {
      this.$emit("onToggleSortBy", item.value);
    },
    onToggle(option) {
      this.selected = option.key;
      this.$emit("onToggleOrder", option.key);
    },
    preSelect(key) {
      this.schema.orderOptions.every((element) => {
        if (element.key == key) {
          this.selected = element.key;
          return false;
        }
        return true;
      });
    },
    preSelectSortBy(key) {
      this.schema.sortByOptions.every((element) => {
        if (element.key == key) {
          this.sortBy = element.key;
          return false;
        }
        return true;
      });
    },
  },
  mounted() {
    if (this.schema) {
      this.selected = this.schema.orderOptions[0].key;
    }
    if (this.preIndex) {
      this.preSelect(this.preIndex);
    }
    if (this.preSortBy) {
      this.preSelectSortBy(this.preSortBy);
    }
  },
};
</script>

<style lang="scss" scoped>
.sorter {
  display: grid;
  grid-template-columns: 20rem 10rem;
  gap: 1rem;
}
.bottom {
  flex-direction: column;
  border-radius: 2px !important;

  button {
    border-radius: 0 !important;
    width: 100%;
  }
}
</style>
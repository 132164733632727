<template>
  <div
    class="full-page flex-column page-padding"
    style="padding-top: 0rem !important"
  >
    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-row">
          <span class="page-title">Verified Users With Updates</span>
          <div class="processing" v-if="processing"></div>
        </div>
        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
          style="margin-left: auto"
        ></pager>
        <div
          class="processing"
          style="margin-top: 0.5rem"
          v-if="downloading"
        ></div>
        <div class="download" @click="downloadData" v-else>
          <img src="../../../assets/icons/ic_download.png" />
          <span>Download</span>
        </div>
      </div>
      <div class="list-wrapper" v-if="users.length > 0">
        <table>
          <thead>
            <tr class="header">
              <th class="index">ID</th>
              <th>Creation Date (CST)</th>
              <th>Real Picture</th>
              <th>Nickname</th>
              <th>Gender</th>
              <th>Location</th>
              <th>Registration Status</th>
              <th style="width: 350px">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in users">
              <td class="index" style="width: 10%">
                {{ item.id || "--" }}
              </td>
              <td>
                {{
                  item.createdAt
                    ? $momentTZ(item.createdAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                <img
                  :src="item.realPicture"
                  :key="item.id"
                  class="listRealPic"
                />
              </td>
              <td>
                {{ item.nickName || "--" }}
              </td>
              <td style="text-transform: capitalize">
                {{ item.gender || "--" }}
              </td>
              <td>
                {{ item.location || "--" }}
              </td>
              <td style="text-transform: capitalize">
                {{
                  (item.registrationStatus
                    ? item.registrationStatus.replace("_", " ")
                    : "--") || "--"
                }}
              </td>
              <td>
                <div class="cell-actions">
                  <button @click="onClickDetail(item)">Details</button>
                  <button @click="onClickUpdateStatus(item)">
                    Update Status
                  </button>
                  <button @click="onClickTakeOff(item)">
                    Take Off the List
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no users to show here...</span>
      </div>
    </div>

    <transition>
      <updatePoup
        v-if="showUpdatePopup"
        :model="updateModel"
        @onAbort="
          showUpdatePopup = false;
          updateModel = {};
        "
        @onSuccess="
          showUpdatePopup = false;
          updateModel = {};
          getAllUsers();
        "
      ></updatePoup>
    </transition>
  </div>
</template>

<script>
import Pager from "../../../components/Pager.vue";
import UpdateUserStatusPopup from "../../../components/popups/UpdateUserStatusPopup.vue";
const ExcelExporter = require("../../../utils/excel");

export default {
  name: "VerifiedUsers",
  components: {
    pager: Pager,
    updatePoup: UpdateUserStatusPopup,
  },
  data() {
    return {
      downloading: false,
      showUpdatePopup: false,
      updateModel: {},
      processing: false,
      users: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      userToTakeOff: null,
    };
  },
  methods: {
    downloadData() {
      this.downloading = true;
      if (this.count == this.users.length) {
        ExcelExporter.JSONToCSVConvertor(
          this.getParsedList(this.users),
          "Verified Users",
          true
        );
        this.downloading = false;
      } else {
        console.log("The count is not same");
        var order = `updatedAt DESC`;

        var data = {
          updatedVerified: true,
        };

        this.$HTTP
          .post(
            `${this.$URLS.USERS.LIST}?order=${order}&page=1&results_per_page=${this.count}&type=${this.paymentType}`,
            data
          )
          .then((res) => {
            let users = res.data.data.users;
            ExcelExporter.JSONToCSVConvertor(
              this.getParsedList(users),
              `${this.paymentType} Orders`,
              true
            );
            this.downloading = false;
          })
          .catch(() => {
            this.downloading = false;
          });
      }
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          ID: item.id || "--",
          "Creation Date": item.createdAt
            ? this.$momentTZ(item.createdAt)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          Nickname: `${item.nickName}` || "--",
          Gender: item.gender || "--",
          Location: item.location || "--",
          "Registration Status": item.registrationStatus || "--",
        });
      });
      return parsed;
    },

    onClickTakeOff(item) {
      this.processing = true;
      var data = {
        updatedVerified: false,
        registrationStatus: "fully_verified",
      };
      this.$HTTP
        .patch(`${this.$URLS.USERS.UPDATE}?id=${item.id}`, data)
        .then((res) => {
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            "User updated successful !"
          );
          this.getAllUsers();
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
          this.processing = false;
        });
    },
    onClickDetail(item) {
      this.$router.push({
        name: "UserDetails",
        query: { id: item.id },
      });
    },
    onClickUpdateStatus(item) {
      this.updateModel = item;
      this.showUpdatePopup = true;
    },
    onPageUpdated(page) {
      this.page = page;
      this.getAllUsers();
    },
    getAllUsers() {
      var data = {
        updatedVerified: true,
      };
      this.processing = true;
      this.$HTTP
        .post(
          `${this.$URLS.USERS.LIST}?page=${this.page}&results_per_page=${this.pageSize}`,
          data
        )
        .then((res) => {
          this.users = res.data.data.users;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 30);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    this.getAllUsers();
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="full-page flex-column">
    <filters
      :fields="filters"
      :processing="processing"
      @onFiltersToggle="onFiltersToggle"
      @onApplyFilters="onApplyFilters"
      @onClearFilters="onClearFilters"
    ></filters>

    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-centered">
          <span class="page-title">All</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <toggler
          :preIndex="order"
          :schema="sortingTogglerData"
          @onToggle="onToggleOrder"
        />

        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
        ></pager>
        <div
          class="processing"
          style="margin-top: 0.5rem"
          v-if="downloading"
        ></div>
        <div class="download" @click="downloadData" v-else>
          <img src="../../../assets/icons/ic_download.png" />
          <span>Download</span>
        </div>
      </div>
      <div class="list-wrapper" v-if="users.length > 0">
        <table>
          <thead>
            <tr class="header">
              <th>Date (CST)</th>
              <th>Deleted By</th>
              <th>Deletee</th>
              <th>Reason</th>
              <th>Reason Code</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in users">
              <td style="padding: 1rem">
                {{
                  item.updatedAt
                    ? $momentTZ(item.updatedAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                {{ item.deletedBy || "--" }}
              </td>
              <td>
                {{ item.deletee || "--" }}
              </td>
              <td style="text-transform: capitalize">
                {{ getReason(item.reason) || "--" }}
              </td>
              <td>
                {{ item.reason || "--" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no users to show here...</span>
      </div>
    </div>

    <transition>
      <updatePoup
        v-if="showUpdatePopup"
        :model="updateModel"
        @onAbort="
          showUpdatePopup = false;
          updateModel = {};
        "
        @onSuccess="
          showUpdatePopup = false;
          updateModel = {};
          getAllUsers();
        "
      ></updatePoup>
    </transition>
  </div>
</template>

<script>
import Filters from "../../../components/Filters.vue";
import Pager from "../../../components/Pager.vue";
import UpdateUserStatusPopup from "../../../components/popups/UpdateUserStatusPopup.vue";
const ExcelExporter = require("../../../utils/excel");
import Toggler from "../../../components/Toggler.vue";

export default {
  name: "UnFrienders",
  components: {
    filters: Filters,
    pager: Pager,
    updatePoup: UpdateUserStatusPopup,
    toggler: Toggler,
  },
  data() {
    return {
      order: "ASC",
      showUpdatePopup: false,
      updateModel: {},
      processing: false,
      filters: [
        {
          id: "deletedBy",
          name: "Deleted By ID",
          type: "text",
          placeholder: "ID of the user who deleted the friend",
          val: "",
          required: false,
        },
        {
          id: "deletee",
          name: "Deletee ID",
          type: "text",
          placeholder: "ID of the user who got deleted",
          val: "",
          required: false,
        },

        {
          id: "start",
          name: "Date Range Start",
          type: "date",
          placeholder: "Select the start date for date range",
          val: "",
          required: false,
        },
        {
          id: "end",
          name: "Date Range End",
          type: "date",
          placeholder: "Select the end date for date range",
          val: "",
          required: false,
        },
      ],
      users: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      filtersData: {},
      downloading: false,
      sortingTogglerData: {
        title: "SORT BY",
        desc: "Creation Date",
        options: [
          {
            key: "ASC",
            val: "Ascending",
          },
          {
            key: "DESC",
            val: "Descending",
          },
        ],
      },
    };
  },
  methods: {
    getReason(c) {
      let code = String(c);
      if (code == 1) {
        return "Poor Match";
      } else if (code == 2) {
        return "I feel uncomfrtable";
      } else if (code == 3) {
        return "I felt unsafe";
      } else if (code == 4) {
        return "The person was a scam";
      } else {
        return "Other reason";
      }
    },
    downloadData() {
      this.downloading = true;
      if (this.count === this.users.length) {
        ExcelExporter.JSONToCSVConvertor(
          this.getParsedList(this.users),
          "DeletedFriends",
          true
        );
        this.downloading = false;
      } else {
        console.log("The count is not same");
        var order = `updatedAt ${this.order}`;
        this.$HTTP
          .post(
            `${this.$URLS.USERS.UNFRIENDERS}?order=${order}&page=1&results_per_page=${this.count}`,
            {}
          )
          .then((res) => {
            let users = res.data.data.users;
            ExcelExporter.JSONToCSVConvertor(
              this.getParsedList(users),
              "DeletedFriends",
              true
            );
            this.downloading = false;
          })
          .catch(() => {
            this.downloading = false;
          });
      }
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          "Delete At": item.updatedAt
            ? this.$momentTZ(item.updatedAt)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          "Deleted By": item.deletedBy || "--",
          Deletee: item.deletee || "--",
          Reason: this.getReason(item.reason) || "--",
          "Reason Code": item.reason || "--",
        });
      });
      return parsed;
    },
    onToggleOrder(e) {
      this.order = e;
      localStorage.setItem("unfriendersOrder", e);
      this.getAllUsers();
    },
    onClearFilters() {
      localStorage.setItem("filters", null);
      this.filtersData = {};
      this.getAllUsers();
    },
    onApplyFilters() {
      var data = {};
      console.table(this.filters);

      if (
        (this.filters[2].val && this.filters[3].val == "") ||
        (this.filters[2].val == "" && this.filters[3].val)
      ) {
        this.$Notifications.showNotification(
          "warning",
          "Please select both start and end dates"
        );
        return;
      }

      this.filters.forEach((item) => {
        if (item.val) {
          if (item.type == "text") {
            data[item.id] = {
              like: item.val,
              options: "i",
            };
          } else if (item.type != "date") {
            data[item.id] = item.val;
          }
        }
      });

      if (this.filters[2].val != "" && this.filters[3].val != "") {
        let start = new Date(this.filters[2].val);
        let end = new Date(this.filters[3].val);
        end.setHours(28, 59, 59);
        if (start.getTime() > end.getTime()) {
          this.$Notifications.showNotification(
            "warning",
            "Start date cannot be later then end date"
          );
          return;
        }
        data["and"] = [
          { updatedAt: { gt: start } },
          { updatedAt: { lt: end } },
        ];
      }

      this.filtersData = data;
      console.log(JSON.parse(JSON.stringify(data)));
      this.getAllUsers();
      localStorage.setItem("filters", JSON.stringify(this.filters));
    },
    onClickDetail(item) {
      this.$router.push({
        name: "UserDetails",
        query: { id: item.id },
      });
    },
    onClickUpdateStatus(item) {
      this.updateModel = item;
      this.showUpdatePopup = true;
    },
    onPageUpdated(page) {
      this.page = page;
      localStorage.setItem("unfriendersPage", parseInt(page));
      this.getAllUsers();
    },
    getAllUsers(data) {
      this.processing = true;
      var order = `updatedAt ${this.order}`;
      console.log("FILTER");
      console.log(JSON.parse(JSON.stringify(this.filtersData)));
      this.$HTTP
        .post(
          `${this.$URLS.USERS.UNFRIENDERS}?order=${order}&page=${this.page}&results_per_page=${this.pageSize}`,
          this.filtersData || {}
        )
        .then((res) => {
          this.users = res.data.data.users;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onFiltersToggle(opened) {
      setTimeout(() => {
        this.processListHeight();
      }, 300);
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 30);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    let page = localStorage.getItem("unfriendersPage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("unfriendersOrder") || "ASC";
    this.order = order;

    let filters = localStorage.getItem("filters");
    if (filters && filters != "null") {
      this.filters = JSON.parse(filters);
      this.onApplyFilters();
    } else {
      this.getAllUsers();
    }
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
.download {
  height: 4rem;
  border: 0.1rem solid var(--clr-nine-green);
  border-radius: 0.2rem;
  display: flex;
  transition: all 0.3s ease-in-out;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  img {
    height: 2rem;
    width: 2rem;
    filter: invert(94%) sepia(95%) saturate(7147%) hue-rotate(84deg)
      brightness(100%) contrast(83%);
  }

  span {
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    color: transparent;
  }

  &:hover {
    background: var(--clr-nine-green);
    filter: none;
    padding: 0rem 1rem;

    span {
      width: auto;
      margin-left: 0.5rem;
      font-size: 1.2rem;
      color: white;
      font-family: "P-500";
      opacity: 1;
      visibility: visible;
    }

    img {
      filter: invert(100%) sepia(2%) saturate(0%) hue-rotate(134deg)
        brightness(104%) contrast(102%);
    }
  }
}
</style>
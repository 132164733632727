<template>
  <popup
    title="Take Off List"
    :desc="desc"
    :processing="processing"
    :fields="fields"
    :current="current"
    posBtnTitle="Yes,Take off list"
    @abort="$emit('onAbort')"
    @onClickPosBtn="onClickUpdate"
  ></popup>
</template>

<script>
import Popup from "./Popup.vue";

export default {
  name: "ApproveSuspiciousUser",
  components: {
    popup: Popup,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      desc: "You are about to take the following user off the list for suspicious users who were friended by others frequentley for reason 2 , 3 and 4",
      processing: false,
      current: {
        for: [],
      },
      fields: [],
    };
  },
  methods: {
    onClickUpdate() {
      this.processing = true;

      this.$HTTP
        .patch(`${this.$URLS.USERS.UPDATE}?id=${this.model.id}`, {
          specialUnFriendCount_active: 0,
        })
        .then((res) => {
          console.log(
            "🚀 ~ file: ApproveSuspiciousUser.vue ~ line 46 ~ .then ~ res",
            res
          );
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            "User updated successful !"
          );
          this.$emit("onSuccess");
        })
        .catch((err) => {
          console.log(
            "🚀 ~ file: ApproveSuspiciousUser.vue ~ line 55 ~ onClickUpdate ~ err",
            err
          );
          this.processing = false;
        });
    },
  },
  mounted() {
    this.current.for = [
      { key: "ID", val: this.model.id },
      { key: "Nickname", val: this.model.nickName },
      { key: "Gender", val: this.model.gender },
      { key: "Comulative Count", val: this.model.specialUnFriendCount_total },
    ];
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <popup
    title="Update User Status"
    :desc="desc"
    :processing="processing"
    :fields="fields"
    :current="current"
    posBtnTitle="Update"
    @abort="$emit('onAbort')"
    @onClickPosBtn="onClickUpdate"
  ></popup>
</template>

<script>
import Popup from "./Popup.vue";
import {
  REGISTRATION_STATUS,
  VERIFICATION_SCORE,
} from "../../assets/Constants";

export default {
  name: "UpdateUserStatusPopup",
  components: {
    popup: Popup,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      desc: "You can update user's registration status and also their status for updated & verified",
      processing: false,
      current: {
        for: [],
      },
      fields: [REGISTRATION_STATUS, VERIFICATION_SCORE],
    };
  },
  methods: {
    onClickUpdate() {
      console.log("save update");
      console.log(JSON.parse(JSON.stringify(this.model)));
      this.processing = true;
      let registrationStatus = this.fields[0].val;
      let verificationScore = this.fields[1].val;

      var data = {};
      if (registrationStatus) {
        data.registrationStatus = registrationStatus;
        data.updatedVerified = false;
      }

      if (verificationScore) {
        data.verificationScore = verificationScore;
      }

      this.$HTTP
        .patch(`${this.$URLS.USERS.UPDATE}?id=${this.model.id}`, data)
        .then((res) => {
          console.log("res");
          console.log(res);
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            "User updated successful !"
          );
          this.$emit("onSuccess");
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
          this.processing = false;
        });
    },
  },
  mounted() {
    console.log("mounted");
    console.log(JSON.parse(JSON.stringify(this.model)));
    console.log("this.model.id = ", this.model.id);
    this.current.for = [
      { key: "ID", val: this.model.id },
      { key: "Nickname", val: this.model.nickName },
    ];
    this.current.current = [
      {
        key: "Registration Status",
        val: this.model.registrationStatus.replace("_", " ") || "--",
      },
      {
        key: "Verfication Score",
        val: this.model.verificationScore || "--",
      },
    ];
  },
};
</script>

<style lang="scss" scoped></style>

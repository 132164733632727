<template>
  <div class="full-page flex-column page-padding">
    <div class="wrodlist-container">
      <div class="left">
        <div
          class="card list"
          id="list"
          ref="list"
          style="margin-top: 0rem !important"
        >
          <div class="title-container">
            <div class="flex-centered">
              <span class="page-title">Prohibtied Words List</span>
              <div class="processing" v-if="processing"></div>
            </div>
          </div>
          <div class="page-desc">
            These words will be used to mark users as
            <strong>UnVerified+</strong> when they are creating their profile.
            If their <strong>workPosition</strong> , <strong>work</strong> ,
            <strong>company</strong> or <strong>school</strong> is from this
            list , they will be marked as
            <strong>UnVerified+</strong>
          </div>
          <div class="list-wrapper" v-if="wordlist.length > 0">
            <table>
              <thead>
                <tr class="header">
                  <th>Created At (CST)</th>
                  <th>Field</th>
                  <th>Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-bind:key="index" v-for="(item, index) in wordlist">
                  <td>
                    {{
                      item.createdAt
                        ? $momentTZ(item.createdAt)
                            .tz("Asia/Shanghai")
                            .format("DD-MM-YYYY HH:mm a")
                        : "--"
                    }}
                  </td>
                  <td>
                    {{ item.field || "--" }}
                  </td>
                  <td>
                    {{ item.value || "--" }}
                  </td>
                  <td>
                    <div class="cell-actions">
                      <button class="red" @click="deleteWord(item)">
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="loading-history"
            :class="{ 'loading-history-anim': processing }"
            v-else
          >
            <img
              src="../../../assets/svg/empty.svg"
              style="width: 30% !important"
              alt=""
            />
            <span>no words to show here...</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="filter-container">
          <filters
            :fields="filters"
            :processing="processing"
            @onFiltersToggle="onFiltersToggle"
            @onApplyFilters="onApplyFilters"
            @onClearFilters="onClearFilters"
          ></filters>
        </div>

        <div class="card add-new">
          <div class="flex-row flex-h-centered">
            <img
              src="../../../assets/icons/ic_create.png"
              alt=""
              class="card-icon"
            />
            <span class="page-title">Add New Word</span>
            <div class="processing" v-if="savingWord"></div>
          </div>

          <span class="desc">
            Add a new prohibited word. Please select a field from user model and
            enter the prohibited word. <br />These words will be used to set the
            user to UnVerified+ status.
          </span>

          <div class="fields">
            <inputView
              id="field"
              :type="USER_FIELDS.type"
              :placeholder="USER_FIELDS.placeholder"
              name="Field"
              :options="USER_FIELDS.options"
              class="input"
              :enableInput="!savingWord"
              @onUpdate="onUpdateAddNewWordField"
              :needBubble="false"
              v-model="field"
            ></inputView>
            <inputView
              id="field"
              type="Value"
              placeholder="Enter the prohibited word"
              name="Word"
              options="field.options"
              class="input"
              :enableInput="!savingWord"
              @onUpdate="onUpdateAddNewWordField"
              :needBubble="false"
              v-model="val"
            ></inputView>
          </div>

          <div class="fields">
            <button
              class="bt-simple"
              @click="
                field = null;
                val = null;
              "
              :class="{
                'bt-simple-disabled': !field && !val,
              }"
            >
              Clear
            </button>
            <buttonView
              :enabled="enableSave"
              :processing="savingWord"
              title="Save"
              @click="onClickSaveWord"
            ></buttonView>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filters from "../../../components/Filters.vue";
import { USER_FIELDS } from "../../../assets/Constants";
import InputView from "../../../components/InputView.vue";
import ButtonView from "../../../components/ButtonView.vue";

export default {
  name: "Prohibited Wordlist",
  components: {
    filters: Filters,
    inputView: InputView,
    buttonView: ButtonView,
  },
  data() {
    return {
      field: null,
      val: null,
      processing: false,
      wordlist: [],
      filtersData: {},
      USER_FIELDS: USER_FIELDS,
      enableSave: false,
      savingWord: false,
      filters: [
        USER_FIELDS,
        {
          id: "value",
          name: "Word",
          type: "text",
          placeholder: "Prohibited word",
          val: "",
          required: false,
        },
      ],
    };
  },
  methods: {
    onClickSaveWord() {
      if (!this.field || !this.val) {
        return;
      }
      this.processing = true;
      this.savingWord = true;
      this.$HTTP
        .post(this.$URLS.WORDLIST.ADD, [
          {
            field: this.field,
            value: this.val,
          },
        ])
        .then((res) => {
          this.$Notifications.showNotification(
            "success",
            "Word added successfully"
          );
          this.processing = false;
          this.savingWord = false;
          this.field = null;
          this.val = null;
          this.getWordlist();
        })
        .catch(() => {
          this.processing = false;
        });

      console.log(`Field = ${this.field} , Value = ${this.val}`);
    },
    onUpdateAddNewWordField() {
      if (
        this.field &&
        this.field.length > 0 &&
        this.val &&
        this.val.length > 0
      ) {
        this.enableSave = true;
      } else {
        this.enableSave = false;
      }
    },
    getWordlist(data) {
      this.processing = true;
      this.$HTTP
        .post(this.$URLS.WORDLIST.LIST, this.filtersData || {})
        .then((res) => {
          this.wordlist = res.data.data;
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onClearFilters() {
      this.filtersData = {};
      this.getWordlist();
    },
    onApplyFilters() {
      var data = {};
      this.filters.forEach((item) => {
        if (item.val) {
          if (item.type == "text") {
            data[item.id] = {
              like: item.val,
              options: "i",
            };
          } else {
            data[item.id] = item.val;
          }
        }
      });
      this.filtersData = data;
      this.getWordlist();
    },
    onFiltersToggle(opened) {
      setTimeout(() => {
        this.processListHeight();
      }, 300);
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 30);
      document.getElementById("list").style.height = height + "px";
    },
    deleteWord(item) {
      this.processing = true;
      this.$HTTP
        .delete(this.$URLS.WORDLIST.BY_ID.replace("%id", item.id))
        .then((res) => {
          this.$Notifications.showNotification(
            "success",
            "Word delete successfully"
          );
          this.processing = false;
          this.getWordlist();
        })
        .catch(() => {
          this.processing = false;
        });
    },
  },
  mounted() {
    let page = localStorage.getItem("usersPage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("usersOrder") || "DESC";
    this.order = order;

    let filters = localStorage.getItem("filters");
    if (filters && filters != "null") {
      this.filters = JSON.parse(filters);
      this.onApplyFilters();
    } else {
      this.getWordlist();
    }
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
.wrodlist-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: calc(60% - 1rem) calc(40% - 1rem);

  .left {
    width: 100%;
    height: 100%;
  }

  .right {
    width: 100%;
    margin-left: 2rem;

    .filter-container {
      width: 100%;
      border-left: 0.2rem solid var(--clr-nine-green);
    }

    .add-new {
      margin-top: 2rem;
      border-left: 0.2rem solid var(--clr-nine-green);
      display: flex;
      flex-direction: column;

      .desc {
        font-family: "P-300";
        font-size: 1.2rem;
        color: gray;
        padding-top: 0.4rem;
      }

      .fields {
        margin-top: 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
      }
    }
  }
}
</style>
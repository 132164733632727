<template>
  <div class="popup">
    <div class="card w-75">
      <h1 class="card-title">{{ schemetics.title }}</h1>
      <div class="card-content">
        <h3>{{ schemetics.desc }}</h3>

        <div class="content">
          <div class="left">
            <div class="top">
              <inputView
                id="expireAt"
                type="datetime-local"
                placeholder="Popover expire time"
                name="Expire At"
                v-model="popover.expireAt"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>

              <inputView
                id="startTime"
                type="datetime-local"
                placeholder="Popover start time"
                name="Start Time"
                v-model="popover.startTime"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>

              <inputView
                id="endTime"
                type="datetime-local"
                placeholder="Popover end time"
                name="End Time"
                v-model="popover.endTime"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>

              <inputView
                id="conditionType"
                type="select"
                placeholder="Popover condition type"
                name="Condition Type"
                v-model="popover.conditionType"
                :options="POPOVER_CONDITION_TYPES.options"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>

              <inputView
                id="confirmBtnType"
                type="select"
                placeholder="Popover confirm button type"
                name="Confirm Button Type"
                v-model="popover.confirmBtnType"
                :options="POPOVER_CONFIRM_BTN_TYPES.options"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>

              <inputView
                id="closeBtnType"
                type="select"
                placeholder="Popover close button type"
                name="Close Button Type"
                v-model="popover.closeBtnType"
                :options="POPOVER_CLOSE_BTN_TYPES.options"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>
            </div>
            <div class="center">
              <inputView
                id="confirmTextCn"
                type="text"
                placeholder="Confirm Text (CN)"
                name="Confirm button text in chinese"
                v-model="popover.confirmTextCn"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>

              <inputView
                id="confirmTextEn"
                type="text"
                placeholder="Confirm Text (EN)"
                name="Confirm button text in english"
                v-model="popover.confirmTextEn"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>

              <inputView
                id="closeTextCn"
                type="text"
                placeholder="Close Text (CN)"
                name="Close button text in chinese"
                v-model="popover.closeTextCn"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>

              <inputView
                id="closeTextEn"
                type="text"
                placeholder="Close Text (EN)"
                name="Close button text in english"
                v-model="popover.closeTextEn"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>

              <inputView
                id="version"
                type="text"
                placeholder="Version"
                name="Version for the popover"
                v-model="popover.version"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>

              <inputView
                id="remark"
                type="text"
                placeholder="Remarks"
                name="Remarks for this popover"
                v-model="popover.remark"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>
            </div>
            <div class="bottom">
              <inputView
                id="payload"
                type="text"
                placeholder="Payload for popover"
                name="Payload"
                v-model="popover.payload"
                class="input"
                :enableInput="!processing"
                :needBubble="false"
              ></inputView>
            </div>
          </div>
          <div class="right">
            <div class="top">
              <label>Image</label>

              <div class="add-image">
                <span>Select Image</span>
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  @change="onSelectImage"
                />
              </div>
            </div>
            <img v-if="selectImage" class="img" :src="selectImage.url" alt="" />
            <img
              v-else
              class="placeholder"
              src="../../assets/svg/empty.svg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="actions">
        <button class="bt-simple" @click="$emit('onAbort')">Abort</button>
        <buttonView
          title="Save"
          :processing="processing"
          :enabled="true"
          @click="onClickSave"
        ></buttonView>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "./Popup.vue";
import InputView from "../InputView.vue";
import ButtonView from "../ButtonView.vue";

import VOtpInput from "vue3-otp-input";

import {
  POPOVER_CONDITION_TYPES,
  GET_POPOVER_CONDITION_TYPE,
  POPOVER_TYPES,
  GET_POPOVER_TYPES,
  POPOVER_CONFIRM_BTN_TYPES,
  GET_POPOVER_CONFIRM_BTN_TYPES,
  POPOVER_CLOSE_BTN_TYPES,
  GET_POPOVER_CLOSE_BTN_TYPES,
} from "../../assets/Constants";

export default {
  name: "PopoverPopup",
  components: {
    popup: Popup,
    inputView: InputView,
    buttonView: ButtonView,
    "v-otp-input": VOtpInput,
  },
  props: {
    model: Object,
    type: Number,
  },
  data() {
    return {
      POPOVER_CONDITION_TYPES: POPOVER_CONDITION_TYPES,
      POPOVER_CONFIRM_BTN_TYPES: POPOVER_CONFIRM_BTN_TYPES,
      POPOVER_CLOSE_BTN_TYPES: POPOVER_CLOSE_BTN_TYPES,
      popover: {},
      processing: false,
      schemetics: {
        title: "",
        desc: "",
      },
      selectImage: null,
      requiredFields: [
        "conditionType",
        "confirmBtnType",
        "closeBtnType",
        "payload",
        "expireAt",
        "startTime",
        "endTime",
        "closeTextEn",
        "closeTextCn",
        "confirmTextCn",
        "confirmTextEn",
        "version",
        "remark",
      ],
    };
  },
  methods: {
    onSelectImage(e) {
      const file = e.target.files[0];
      this.selectImage = {
        file: file,
        url: URL.createObjectURL(file),
      };
    },
    onClickSave() {
      this.type == 1 ? this.createNew() : this.update();
    },
    async createNew() {
      let haveAllFields = this.checkAllFields();
      if (!haveAllFields) return;
      this.popover["popoverType"] = 1;
      console.table(this.popover);
      let { ok, url } = await this.uploadImage();
      if (!ok) return;
      this.popover["image"] = url;
      this.fixTypes();
      this.$HTTP
        .post(this.$URLS.POPOVERS.ADD, this.popover)
        .then((res) => {
          console.log("SUCCESS");
          console.log(res);
          this.$Notifications.showNotification(
            "success",
            "Popover created succcessfully"
          );
          this.$emit("onSuccess", res.data.data);
          this.processing = false;
        })
        .catch((err) => {
          console.log("Failed to create popover");
          console.log(err);
          this.processing = false;
        });
    },
    async update() {
      console.log("Update");
      let haveAllFields = this.checkAllFields();
      if (!haveAllFields) return;
      this.popover["popoverType"] = 1;
      console.table(this.popover);
      let { ok, url } = await this.uploadImage();
      if (!ok) return;
      this.popover["image"] = url;
      this.fixTypes();
      this.$HTTP
        .patch(
          this.$URLS.POPOVERS.GET_BY_ID.replace("%id", this.model.id),
          this.popover
        )
        .then((res) => {
          console.log("SUCCESS");
          console.log(res);
          this.$Notifications.showNotification(
            "success",
            "Popover updated succcessfully"
          );
          this.$emit("onSuccess", res.data.data);
          this.processing = false;
        })
        .catch((err) => {
          console.log("Failed to create popover");
          console.log(err);
          this.processing = false;
        });
    },
    fixTypes() {
      try {
        this.popover["version"] = parseInt(this.popover.version);
        this.popover["conditionType"] = parseInt(this.popover.conditionType);
        this.popover["confirmBtnType"] = parseInt(this.popover.confirmBtnType);
        this.popover["closeBtnType"] = parseInt(this.popover.closeBtnType);
      } catch (error) {
        console.log("cannot fix types");
        console.log(error);
      }
    },
    checkAllFields() {
      let fieldsInObj = Object.keys(this.popover);
      if (
        fieldsInObj.length <= 0 ||
        fieldsInObj.length < this.requiredFields.length
      ) {
        this.$Notifications.showNotification(
          "warning",
          "PLease enter all requried information"
        );
        return false;
      }
      return true;
    },
    uploadImage() {
      return new Promise((resolve, reject) => {
        if (
          this.type == 2 &&
          this.selectImage.url == this.popover.image &&
          this.selectImage.file == null
        ) {
          resolve({ ok: true, url: this.selectImage.url });
          return;
        }
        if (!this.selectImage || !this.selectImage.file) {
          this.$Notifications.showNotification(
            "warning",
            "Please select an image for the popover"
          );
          resolve({ ok: false, url: null });
          return;
        }

        this.processing = true;
        var formData = new FormData();
        formData.append("file", this.selectImage.file);
        formData.append("imgType", "popovers");
        this.$HTTP
          .post(this.$URLS.UPLOAD, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log("THEN");
            console.log(res);
            resolve({
              ok: true,
              url: res.data.result,
            });
          })
          .catch((err) => {
            console.log("CATCH");
            console.log(err);
            resolve({
              ok: false,
              data: null,
              err: err,
            });
          });
      });
    },
  },
  mounted() {
    if (this.type == 1) {
      this.schemetics = {
        title: "Create New Popover",
        desc: "Please enter all the information below to create new Popover",
      };
    } else {
      this.schemetics = {
        title: "Edit Popover",
        desc: "Please edit or change the required data for this popover below",
      };
      this.popover = this.model;
      if (this.model.expireAt) {
        this.popover["expireAt"] = new Date(this.model.expireAt)
          .toISOString()
          .slice(0, 16);
      }
      if (this.model.startTime) {
        this.popover["startTime"] = new Date(this.model.startTime)
          .toISOString()
          .slice(0, 16);
      }
      if (this.model.endTime) {
        this.popover["endTime"] = new Date(this.model.endTime)
          .toISOString()
          .slice(0, 16);
      }
      this.selectImage = {
        file: null,
        url: this.model.image,
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.actions {
  width: 35rem;
  margin-left: auto;
}
.content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 35rem;
  margin: 2rem 0rem;
  gap: 1rem;

  .left {
    display: flex;
    flex-direction: column;
    .top {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      row-gap: 2rem;
    }

    .center {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-top: 2rem;
    }

    .bottom {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    border-left: 0.2rem dashed lightgray;
    padding-left: 1rem;

    .top {
      display: flex;

      margin-bottom: 2rem;
      label {
        font-size: 1.2rem;
        color: gray;
        font-family: "P-500", sans-serif;
        text-transform: uppercase;
        padding-left: 0.2rem;
        height: 3rem;
        line-height: 3rem;
      }

      .add-image {
        margin-left: auto;
        height: 3rem;
        width: 12rem;
        background: transparent;

        position: relative;
        display: flex;
        overflow: hidden;
        border-radius: 0.4rem;
        transition: 0.25s all ease-in-out;

        span {
          width: 100%;
          height: 100%;
          color: var(--clr-nine-green);
          font-family: "P-500";
          text-align: center;
          line-height: 3rem;
          font-size: 1.3rem;
        }

        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          opacity: 0;
        }

        &:hover {
          background: var(--clr-nine-green);

          span {
            color: white;
          }
        }
      }
    }

    .img {
      width: 100%;
    }

    .placeholder {
      padding: 4rem;
      margin: auto 0;
    }
  }
}
</style>

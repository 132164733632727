<template>
  <div class="popup">
    <div class="card w-30">
      <h1 class="card-title">Add New Admin</h1>
      <div class="card-content">
        <h3>
          Please enter an email and a temporary password for the new admin. ONce
          the account is created, new admin will be able to login and change
          thier password.
        </h3>

        <div class="inputs">
          <inputView
            id="email"
            type="text"
            placeholder="Please enter an email address"
            name="Email"
            v-model="email"
            class="input"
            :enableInput="!processing"
            :needBubble="false"
          ></inputView>

          <inputView
            id="password"
            type="password"
            placeholder="Please enter a temporary password"
            name="Password"
            v-model="password"
            class="input"
            :enableInput="!processing"
            :needBubble="false"
          ></inputView>
        </div>
      </div>

      <div class="actions">
        <button class="bt-simple" @click="$emit('onAbort')">Abort</button>
        <buttonView
          title="Add"
          :processing="processing"
          :enabled="
            email && email.length > 0 && password && password.length > 0
          "
          @click="onClickCreate"
        ></buttonView>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "./Popup.vue";
import InputView from "../InputView.vue";
import ButtonView from "../ButtonView.vue";

export default {
  name: "AddAdminPopup",
  components: {
    popup: Popup,
    inputView: InputView,
    buttonView: ButtonView,
  },
  props: {},
  data() {
    return {
      processing: false,
      email: null,
      password: null,
    };
  },
  methods: {
    onClickCreate() {
      if (!email || !password) return;
      this.processing = true;
      this.$HTTP
        .post(`${this.$URLS.ADMIN.ADD}`, {
          email: this.email,
          password: this.password,
          role: "normal_admin",
        })
        .then((res) => {
          console.log("res");
          console.log(res);
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            "Admin added successful !"
          );
          this.$emit("onSuccess");
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
          this.processing = false;
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.inputs {
  display: grid;
  margin: 1rem 0rem;
  gap: 1rem;
}
</style>

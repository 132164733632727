<template>
  <div class="full-page flex-column page-padding">
    <div class="flex-row" style="margin-bottom: 2rem">
      <div class="page-nav" @click="$router.back()">
        <img
          src="../../../assets/icons/ic_arrow_down.png"
          alt="Back"
          class="back-btn"
        />
        <span class="page-title">User Detail</span>
        <div class="processing" v-if="processing"></div>
      </div>

      <div class="page-actions" style="margin-left: auto">
        <div class="verifcationScore">
          <div class="title">Verification<br />Score</div>

          <div class="radioContainer" @click="onClickScore('1')">
            <div class="processing" v-if="processingScore"></div>
            <div
              v-else
              :class="{ selected: user.verificationScore == '1' }"
              class="score"
            >
              1
            </div>
          </div>

          <div class="radioContainer" @click="onClickScore('2')">
            <div class="processing" v-if="processingScore"></div>
            <div
              v-else
              :class="{ selected: user.verificationScore == '2' }"
              class="score"
            >
              2
            </div>
          </div>

          <div class="radioContainer" @click="onClickScore('3')">
            <div class="processing" v-if="processingScore"></div>
            <div
              v-else
              :class="{ selected: user.verificationScore == '3' }"
              class="score"
            >
              3
            </div>
          </div>
        </div>
        <button v-if="!isEditMode" @click="onClickShowUpdate">
          Quick Status Update
        </button>
        <button v-if="!isEditMode" @click="isEditMode = true">Edit All</button>
        <button v-if="isEditMode" @click="onClickSave">Save All Changes</button>
        <button class="negative" v-if="isEditMode" @click="isEditMode = false">
          ABORT
        </button>
        <div
          class="flag"
          :class="{ flagged: user.flagged }"
          v-if="!savingFlag"
          @click="onClickFlag"
        >
          <img src="../../../assets/icons/ic_flag.png" alt="" />
          <span>{{ user.flagged ? "Flagged" : "Click to Flag" }}</span>
        </div>
        <div
          v-if="savingFlag"
          class="spinner"
          style="margin-left: 0.4rem"
        ></div>
      </div>
    </div>

    <div class="wrapper">
      <div class="card flex-column">
        <div class="card-top" @click="showEssentials = !showEssentials">
          <img
            src="../../../assets/icons/ic_arrow_down.png"
            alt=""
            :class="{ opened: showEssentials }"
          />
          <span class="page-title">Essentials</span>
        </div>

        <div class="detail" v-if="showEssentials">
          <div v-for="item in Object.keys(essentials)" v-bind:key="item">
            <div class="flex-column item">
              <span class="key">{{ getItemName(item) }} </span>

              <inputView
                v-if="isEditMode && nonEditables.indexOf(item) == -1"
                :id="item"
                v-model="editableUser[item]"
                class="input"
                :enableInput="!processing"
                :placeholder="getPlaceholder(item)"
                :type="getType(item)"
                :options="getOptions(item)"
              ></inputView>

              <div v-else>
                <div
                  class="val"
                  v-if="item !== 'realPicture' && item !== 'albumPictures'"
                >
                  {{ essentials[item] }}
                </div>

                <img
                  :src="essentials[item]"
                  alt="realPicture"
                  v-if="item === 'realPicture'"
                  class="picture"
                  @click="
                    imgPreview = essentials[item];
                    showImagePreview = true;
                  "
                />

                <div class="pictures-grid" v-if="item === 'albumPictures'">
                  <div v-for="picture in essentials[item]" v-bind:key="picture">
                    <img
                      :src="picture"
                      alt="realPicture"
                      class="picture"
                      @click="
                        imgPreview = picture;
                        showImagePreview = true;
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card flex-column">
        <div class="card-top" @click="showOthers = !showOthers">
          <img
            src="../../../assets/icons/ic_arrow_down.png"
            alt=""
            :class="{ opened: showOthers }"
          />
          <span class="page-title">Others</span>
        </div>

        <div class="detail" v-if="showOthers">
          <div v-for="item in Object.keys(others)" v-bind:key="item">
            <div class="flex-column item">
              <span class="key">{{ getItemName(item) }} </span>

              <div class="val">
                {{ formatVal(item) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card flex-column">
        <div class="card-top" @click="showMBTI = !showMBTI">
          <img
            src="../../../assets/icons/ic_arrow_down.png"
            alt=""
            :class="{ opened: showMBTI }"
          />
          <span class="page-title">MBTI</span>
        </div>
        <div class="detail g4" v-if="showMBTI">
          <div v-for="item in Object.keys(mbti)" v-bind:key="item">
            <div class="flex-column item">
              <span class="key">{{ item }} </span>

              <inputView
                v-if="isEditMode && nonEditables.indexOf(item) == -1"
                :id="item"
                v-model="editableUser[item]"
                class="input"
                :enableInput="!processing"
                :placeholder="getPlaceholder(item)"
                :type="getType(item)"
                :options="getOptions(item)"
              ></inputView>

              <div v-else class="val">
                {{ mbti[item] }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card flex-column" v-if="!isEditMode">
        <div class="card-top" @click="showUnfiends = !showUnfiends">
          <img
            src="../../../assets/icons/ic_arrow_down.png"
            alt=""
            :class="{ opened: showUnfiends }"
          />
          <span class="page-title">Times Unfriended </span>
        </div>

        <div class="detail g4" v-if="showUnfiends">
          <div v-for="item in Object.keys(unfriendings)" v-bind:key="item">
            <div class="flex-column item">
              <span class="key">Reason {{ item }} </span>

              <div class="val">
                {{ unfriendings[item].length }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card flex-column" v-if="!isEditMode">
        <div class="card-top" @click="showOrders = !showOrders">
          <img
            src="../../../assets/icons/ic_arrow_down.png"
            alt=""
            :class="{ opened: showOrders }"
          />
          <span class="page-title">Orders</span>
        </div>
        <div class="detail" style="display: flex; flex: 1" v-if="showOrders">
          <div style="width: 40%; margin: auto" v-if="allOrders.length <= 0">
            <div class="loading-history">
              <img src="../../../assets/svg/empty.svg" alt="" />
              <span>no orders history available...</span>
            </div>
          </div>

          <div style="flex: 1" v-else>
            <table>
              <thead>
                <tr class="header">
                  <th class="index idField">ID</th>
                  <th>Order Creation Date (CST)</th>
                  <th>Payment State</th>
                  <th>Payment Date (CST)</th>
                  <th>Good Purchased</th>
                  <th>Amount (CNY)</th>
                  <th>Channel</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-bind:key="index" v-for="(item, index) in allOrders">
                  <td
                    class="index idField"
                    style="padding: 2rem 1rem !important"
                  >
                    {{ item.id || "--" }}
                  </td>
                  <td>
                    {{
                      item.createdAt
                        ? $momentTZ(item.createdAt)
                            .tz("Asia/Shanghai")
                            .format("DD-MM-YYYY HH:mm a")
                        : "--"
                    }}
                  </td>
                  <td>
                    {{ getOrderStateName(item.paymentState) || "--" }}
                  </td>
                  <td>
                    {{
                      item.paymentAt
                        ? $momentTZ(item.paymentAt)
                            .tz("Asia/Shanghai")
                            .format("DD-MM-YYYY HH:mm a")
                        : "--"
                    }}
                  </td>
                  <td>
                    {{ item.good ? item.good.nameEn : "--" }} <br />
                    {{ item.good ? item.good.nameCn : "--" }}
                  </td>
                  <td>
                    {{ item.totalPrice || "--" }}
                  </td>
                  <td>
                    {{ item.channel || "--" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <transition>
      <updatePopup
        v-if="showUpdatePopup"
        :model="user"
        @onAbort="showUpdatePopup = false"
        @onSuccess="
          showUpdatePopup = false;
          getUser();
        "
      ></updatePopup>
    </transition>

    <transition>
      <div
        class="preview"
        v-if="showImagePreview"
        @click="
          showImagePreview = false;
          imgPreview = '';
        "
      >
        <img :src="imgPreview" alt="" />
      </div>
    </transition>

    <!-- NOTES CONTAINER -->
    <transition>
      <div
        class="notes-container"
        :class="{ 'notes-closed': !notesOpen, 'notes-with-chat': chatOpen }"
      >
        <div class="top" @click="notesOpen = !notesOpen">
          <img
            src="../../../assets/icons/ic_notes_black.png"
            alt=""
            class="logo"
          />
          <span class="title">Notes</span>
          <img
            src="../../../assets/icons/ic_arrow_black.png"
            alt=""
            class="close"
          />
        </div>
        <textarea
          class="notes"
          v-model="noteStr"
          @input="onNoteChange"
          placeholder="Enter any notes for this user here. All notes will be automatically saved... ✌"
        ></textarea>
        <div class="notes-save">
          <span
            >Notes can save automatically. But you can also save them manually
            by clicking 💾</span
          >

          <div v-if="savingNotes" class="spinner"></div>
          <div v-else class="save" @click="onClickSaveNote">
            <img src="../../../assets/icons/ic_save_black.png" alt="" />
          </div>
        </div>
      </div>
    </transition>

    <transition v-if="chatOn">
      <div class="chat-container" :class="{ 'chat-closed': !chatOpen }">
        <!-- CHAT TOP -->
        <div class="top" @click="chatOpen = !chatOpen">
          <img
            src="../../../assets/icons/ic_chat_black.png"
            alt=""
            class="logo"
          />
          <span v-if="chatOpen" class="title"
            >Chat with {{ user.nickName }}</span
          >
          <span v-if="!chatOpen" class="title">Chat</span>
          <img
            src="../../../assets/icons/ic_arrow_black.png"
            alt=""
            class="close"
          />
        </div>

        <div class="messages" v-if="!messages || messages.length <= 0">
          <div class="no-messages">
            <img src="../../../assets/svg/empty.svg" alt="" />
            no old messages...
          </div>
        </div>

        <div
          class="messages"
          id="chatContainer"
          v-if="messages && messages.length > 0"
        >
          <div
            class="chat-cell"
            v-for="message in messages"
            v-bind:key="message.content"
            :class="{ me: message.isMe, 'not-me': !message.isMe }"
          >
            <span class="message" v-if="!message.isImage">{{
              message.content
            }}</span>
            <div class="img-preview" v-else>
              <img :src="message.content" v-if="message.isImage" />
              <div
                class="loading"
                v-if="
                  message.isImage && message.progress && message.progress < 100
                "
              >
                <div class="spinner"></div>
                {{ message.progress }}%
              </div>
            </div>
            <span class="timestamp">{{ message.timestamp }}</span>

            <span
              class="err"
              v-if="
                message.isImage && message.progress && message.progress == -1
              "
              >Failed</span
            >
          </div>
        </div>

        <!-- INPUT CONTAINER -->
        <div class="message-container">
          <div class="btn-container">
            <img
              src="../../../assets/icons/ic_attach_file.png"
              class="attach"
            />
            <input type="file" accept="image/*" @change="onSelectChatFile" />
          </div>
          <input
            type="text"
            placeholder="enter your message here..."
            v-model="messageStr"
            v-on:keyup.enter="onEnterKey"
          />
          <div class="btn-container" @click="onEnterKey">
            <img src="../../../assets/icons/ic_send.png" alt="" class="send" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
String.prototype.insert = function (index, string) {
  if (index > 0) {
    return this.substring(0, index) + string + this.substr(index);
  }

  return string + this;
};

import UpdateUserStatusPopup from "../../../components/popups/UpdateUserStatusPopup.vue";
import InputView from "../../../components/InputView.vue";
import ChatController from "../../../controllers/ChatController";
let chatController;
import {
  REGISTRATION_STATUS,
  UPDATED_VERIFIED,
  GENDER,
  UNEDITABLE_FIELDS,
  ESSENTIAL_FIELDS,
  GET_ORDER_STATE_NAME,
} from "../../../assets/Constants";

export default {
  name: "UserDetail",
  data() {
    return {
      showEssentials: true,
      showOthers: false,
      showMBTI: false,
      showUnfiends: false,
      showOrders: false,
      processingScore: false,
      chatOn: false,
      savingFlag: false,
      savingNotes: false,
      noteStr: "",
      notesOpen: false,
      showImagePreview: false,
      imgPreview: "",
      isEditMode: false,
      showUpdatePopup: false,
      user: {},
      editableUser: {},
      id: -1,
      processing: true,
      essentials: {},
      mbti: {},
      unfriendings: {},
      others: {},
      nonEditables: UNEDITABLE_FIELDS,
      options: {
        registrationStatus: REGISTRATION_STATUS,
        updatedVerified: UPDATED_VERIFIED,
        gender: GENDER,
      },
      messages: [],
      messageStr: "",
      chatOpen: false,
      allOrders: [],
    };
  },
  components: {
    updatePopup: UpdateUserStatusPopup,
    inputView: InputView,
  },
  methods: {
    onClickShowUpdate() {
      this.user.id = this.id;
      this.showUpdatePopup = true;
    },
    getItemName(item) {
      if (item) {
        let parsed = item.replace(/([A-Z])/g, " $1").trim();
        return parsed;
      }
      return item;
    },
    bindOrders(wechatOrders, appleOrders) {
      let wx = wechatOrders.map((obj) => ({ ...obj, channel: "Wechat" }));
      let apple = appleOrders.map((obj) => ({ ...obj, channel: "Apple Pay" }));
      let allOrders = [...wx, ...apple];
      let sorted = allOrders.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      this.allOrders = sorted;
    },
    onClickScore(score) {
      console.log(score);
      this.processingScore = true;

      this.$HTTP
        .patch(`${this.$URLS.USERS.UPDATE}?id=${this.id}`, {
          verificationScore: score,
        })
        .then((res) => {
          console.log(res.data);
          this.getUser();
          this.processingScore = false;
          this.user.verificationScore = score;
          this.essentials.verificationScore = score;
          this.$Notifications.showNotification(
            "success",
            `Verification scored successfully updated to ${score}`
          );
        })
        .catch((err) => {
          console.log(err);
          this.processingScore = false;
        });
    },
    onClickFlag() {
      this.savingFlag = true;
      console.log("this.user.flagged = ", this.user.flagged);
      this.$HTTP
        .patch(`${this.$URLS.USERS.UPDATE}?id=${this.id}`, {
          flagged: !this.user.flagged,
        })
        .then((res) => {
          console.log("notes updated");
          console.log(res.data);
          this.savingFlag = false;
          this.getUser();
        })
        .catch((err) => {
          this.savingFlag = false;
        });
    },
    onClickSaveNote() {
      this.saveNotes(true);
    },
    onNoteChange(e) {
      this.noteStr = e.target.value;
      this.saveNotes(false);
    },
    saveNotes(showSpinner) {
      this.savingNotes = showSpinner;
      this.$HTTP
        .patch(`${this.$URLS.USERS.UPDATE}?id=${this.id}`, {
          notes: this.noteStr,
        })
        .then((res) => {
          console.log("notes updated");
          console.log(res.data);
          this.savingNotes = false;
        })
        .catch((err) => {
          this.savingNotes = false;
        });
    },
    formatVal(item) {
      if (
        ["created At", "updated At", "createdAtTime Stamp"].indexOf(item) > -1
      ) {
        return this.others[item]
          ? this.$momentTZ(this.others[item])
              .tz("Asia/Shanghai")
              .format("YYYY-MM-DD HH:mm a")
          : "--";
      } else {
        return this.others[item];
      }
    },
    getType(item) {
      return this.options[item]
        ? this.options[item].type
        : typeof this.editableUser[item] || "text";
    },
    getOptions(item) {
      return this.options[item] ? this.options[item].options : [];
    },
    getPlaceholder(item) {
      return this.options[item]
        ? this.options[item].placeholder
        : `Please enter a value for ${item}`;
    },
    onClickSave() {
      console.log("save");
      console.log(this.id);
      this.processing = true;

      this.editableUser.orders = this.user.orders;
      this.editableUser.applePayOrders = this.user.applePayOrders;
      this.$HTTP
        .patch(`${this.$URLS.USERS.UPDATE}?id=${this.id}`, this.editableUser)
        .then((res) => {
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            "User updated successful !"
          );
          this.isEditMode = false;
          this.getUser();
        })
        .catch((err) => {
          this.processing = false;
        });
    },
    getUser() {
      this.processing = true;
      this.$HTTP
        .get(`${this.$URLS.USERS.GET_BY_ID.replace("%id", this.id)}`)
        .then((res) => {
          this.processing = false;
          this.user = res.data.data;
          this.bindOrders(this.user.orders, this.user.applePayOrders);
          this.noteStr = this.user.notes;
          this.editableUser = res.data.data;
          delete this.editableUser["id"];
          Object.keys(this.user).forEach((field) => {
            if (ESSENTIAL_FIELDS.indexOf(field) > -1) {
              this.essentials[field] = this.user[field];
            } else if (field.includes("MBT")) {
              this.mbti[field] = this.user[field];
            } else if (field == "unfriendings") {
              this.unfriendings = this.user[field];
            } else if (
              !field.includes("orders") &&
              !field.includes("applePayOrders")
            ) {
              this.others[this.parseFieldName(field)] = this.user[field];
            }
          });

          let sortedEssentials = {};
          ESSENTIAL_FIELDS.forEach((item) => {
            sortedEssentials[item] =
              item == "id" ? this.id : this.essentials[item] || "--";
          });
          this.essentials = sortedEssentials;

          this.rearrangeOthers();
        })
        .catch((err) => {
          this.processing = false;
        });
    },
    rearrangeOthers() {
      delete this.others["latest Location"];
      this.others["latest Location"] = this.user.latestLocation;

      delete this.others["device Token"];
      this.others["device Token"] = this.user.deviceToken;

      delete this.others["device Id"];
      this.others["device Id"] = this.user.deviceId;

      delete this.others["open Id"];
      this.others["open Id"] = this.user.openId;

      delete this.others["im Token"];
      this.others["im token"] = this.user.imToken;

      delete this.others["profiles"];
      this.others.profiles = this.user.profiles;
    },
    parseFieldName(str) {
      var matches = str.match(/[A-Z]/g);
      if (!matches || matches.length <= 0) {
        return str;
      }

      var s = "";
      matches.forEach((item) => {
        let index = str.indexOf(item);
        s = str.insert(index, " ");
      });

      return s;
    },
    formatTime(time) {
      let weeksAgo = this.$moment
        .duration(this.$moment(time).diff(new Date()))
        .as("days");
      if (weeksAgo <= 1) {
        return this.$moment(time).fromNow().replace("a ", "");
      } else {
        return this.$moment(time).format("MM-DD hh:mm a");
      }
    },
    scrollChatToBottom() {
      console.log("scroll");
      let container = document.getElementById("chatContainer");
      if (!container) {
        return;
      }
      setTimeout(() => {
        console.log(container);
        container.scrollTop = container.scrollHeight - container.clientHeight;
      }, 100);
    },
    async onEnterKey() {
      this.messages.push({
        content: this.messageStr,
        isMe: true,
        timestamp: this.formatTime(new Date()),
        isImage: false,
      });
      this.scrollChatToBottom();
      await chatController.sendMessage(this.id, RongIMLib.MESSAGE_TYPE.TEXT, {
        content: this.messageStr,
        user: chatController.getMessageUserObject(),
        extra: chatController.getMessageExtra(this.user),
      });
      this.messageStr = "";
    },
    async onSelectChatFile(e) {
      let user = this.user;
      let ctx = this;
      let file = e.target.files[0];
      this.messages.push({
        isMe: true,
        timestamp: this.formatTime(new Date()),
        isImage: true,
        content: URL.createObjectURL(file),
        progress: 0,
      });
      this.user["id"] = this.id;

      let messageIndex = ctx.messages.length - 1;
      let { sendResponse, sendError } = await chatController.sendImageMessage(
        user,
        file,
        {
          progress(percent) {
            ctx.messages[messageIndex].progress = percent;
          },
        }
      );
      if (!sendResponse) {
        this.messages[messageIndex].progress = -1;
      }
    },
    async initChat() {
      chatController = new ChatController(this);
      // let connected = await chatController.connect();
      // if (!connected.success) {
      //   console.log("=== NOT CONNECTED");
      //   return;
      // }
      let that = this;
      chatController.watch({
        conversation(event) {},
        message(event) {
          try {
            let msgObj = event.message;
            let content = msgObj.content;
            let sender = content.user;
            console.log("sender");
            console.log(sender);
            if (!sender) {
              return;
            }

            if (sender.id == that.id) {
              that.messages.push({
                content: content.imageUri || content.content,
                isMe: false,
                isNew: false,
                timestamp: that.formatTime(msgObj.receivedTime),
                isImage: msgObj.messageType == "RC:ImgMsg",
              });
              that.scrollChatToBottom();
            }
          } catch (error) {
            console.error(error);
          }
        },
        status(event) {
          console.log("status in detail");
          console.log(event);
        },
      });

      let { chatHistory, chatHistoryError } =
        await chatController.getChatHistoryById(this.id);

      if (!chatHistory) {
        return;
      }
      chatHistory.forEach((msg) => {
        this.messages.push({
          content: msg.content.imageUri || msg.content.content,
          isMe: msg.senderUserId != this.id,
          timestamp: this.formatTime(msg.sentTime),
          isImage: msg.messageType == RongIMLib.MESSAGE_TYPE.IMAGE,
        });
      });
      this.scrollChatToBottom();
    },
  },
  async mounted() {
    this.id = this.$route.query.id;
    this.getUser();
  },
  created() {
    console.log("created");
    document.addEventListener("nine:onChatOff", (ev) => {
      this.chatOn = false;
    });
    document.addEventListener("nine:onChatOn", (ev) => {
      this.chatOn = true;
      this.initChat();
    });
    this.getOrderStateName = GET_ORDER_STATE_NAME;
  },
};
</script>

<style lang="scss" scoped>
.verifcationScore {
  height: 5rem;
  padding-right: 1rem;
  margin-right: 1rem;
  border: 0.2rem solid var(--clr-nine-green);
  border-radius: 0.4rem;
  display: flex;

  .title {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
    color: black;
    font-size: 1.2rem;
    font-family: "P-600";
    background: var(--clr-nine-green);
  }

  .processing {
    margin: 0rem 0.28rem !important;
  }

  .radioContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 2rem;
    cursor: pointer;

    .score {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: gray;
      border: 0.2rem solid lightgrey;
      font-size: 1.2rem;
      font-family: "P-700";
    }

    .selected {
      background: var(--clr-nine-green);
      color: black !important;
      border-color: black;
      transform: scale(1.1);
      font-size: 1.6rem !important;
    }
  }
}
.wrapper {
  width: 100%;
  height: 89vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 1rem;
}
.card {
  margin-bottom: 2rem;
  overflow-y: visible !important;
}

.card-top {
  display: flex;
  flex-direction: row;
  cursor: pointer;

  .page-title {
    display: flex;
    align-items: center;
    width: 100%;
    &::after {
      content: "( click to collapse/expand )";
      font-size: 1rem;
      color: gray;
      font-family: "P-400";
      text-transform: lowercase;
      margin-left: auto;
    }
  }

  img {
    width: 2rem;
    height: 2rem;
    transform: rotate(180deg);
    transition: transform 0.25s linear;
    margin: auto 1rem auto 0rem;
  }

  .opened {
    transform: rotate(0deg);
  }
}

.g4 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
}
.detail {
  margin-top: 2rem;
  width: 100% !important;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
  overflow-y: scroll;

  .item {
    .key {
      font-size: 1.2rem;
      color: gray;
      width: 100%;
      text-transform: capitalize;
      font-family: "P-600", sans-serif;
    }

    .val {
      margin-top: 1rem;
      font-size: 1.4rem;
      font-family: "P-500", sans-serif;
      word-break: break-all;
    }

    .pictures-grid {
      margin: 0.5rem;
      display: grid;
      grid-template-columns: 8rem 8rem 8rem;
      gap: 1rem;
    }
    .picture {
      width: 8rem;
      height: 8rem;
      background: lightgray;
      margin-top: 1rem;
      box-shadow: var(--shadow);
      border: 0.1rem solid gray;
    }
  }
}

.spinner {
  width: 3.5rem;
  height: 3.5rem;
  background: transparent;
  border-radius: 50%;
  border-width: 0.5em;
  border-color: var(--clr-nine-green);
  border-style: solid;
  border-top-color: var(--clr-nine-green-light);
  margin-bottom: 1rem;
  animation: rotating 1s linear infinite;
  margin: auto 0;
  margin-left: auto;
}

.flag {
  min-width: 3.8rem;
  height: 5rem;
  display: flex;
  border: 0.1rem solid black;
  border-radius: 0.4rem;
  transition: 0.25s all ease-in-out;
  display: flex;
  align-items: center;
  padding-right: 1rem;

  span {
    font-size: 1.2rem;
    font-family: "P-400";
  }
  img {
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    margin: auto;
  }

  &:hover {
    background: var(--clr-nine-green);
  }
}

.flagged {
  border: 0.2rem solid #f44336 !important;
  transition: 0.25s all ease-in-out !important;

  span {
    color: #f44336;
    text-transform: uppercase;
    font-family: "P-700";
  }
  img {
    filter: invert(34%) sepia(25%) saturate(5176%) hue-rotate(341deg)
      brightness(99%) contrast(93%);
  }

  &:hover {
    background: #f44336;

    img {
      filter: none;
    }
  }
}

.preview {
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.2);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 50rem;
    border: 0.1rem solid black;
  }
}

.notes-with-chat {
  right: 27vw !important;
}
.notes-closed {
  bottom: -55vh !important;
  width: 16rem !important;
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;

  .top {
    background: var(--clr-nine-green) !important;
    height: 5rem !important;
    padding: 0rem 1rem !important;

    .title {
      color: black !important;
      font-family: "P-600" !important;
      font-size: 1.8rem !important;
      text-transform: uppercase;
    }

    .close {
      width: 3rem !important;
      height: 3rem !important;
      margin-bottom: 0.15rem;
      transform: rotate(180deg);
      filter: invert(0%) sepia(0%) saturate(16%) hue-rotate(309deg)
        brightness(100%) contrast(107%) !important;
    }
  }
}
.notes-container {
  width: 25vw;
  height: 60vh;
  position: fixed;
  bottom: 0;
  right: 12vw;
  background: white;
  box-shadow: var(--shadow);
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  overflow: hidden;
  display: grid;
  grid-template-rows: 6rem 1fr 6rem;
  transition: 0.3s all linear;
  border: 0.2rem solid var(--clr-nine-green);
  border-bottom: none;

  .notes-save {
    width: 100%;
    height: 6rem;
    background: var(--clr-nine-green-light);
    padding: 1rem 2rem;
    color: gray;
    display: flex;
    align-items: center;

    span {
      font-size: 1rem;
      font-family: "P-500";
      width: calc(100% - 5rem);
    }

    .save {
      width: 4rem;
      height: 4rem;
      border: 0.1rem solid black;
      margin-left: 1rem;
      border-radius: 50%;
      display: flex;
      transition: 0.25s all ease-in-out;

      img {
        width: 3.5rem;
        height: 3.5rem;
        margin: auto;
      }

      &:hover {
        background: var(--clr-nine-green);
      }
    }
  }

  .top {
    width: 100%;
    height: 6rem;
    border-bottom: 0.2rem solid var(--clr-nine-green);
    background: var(--clr-nine-green-very-light);
    display: flex;
    align-items: center;
    padding: 0rem 1.5rem;
    transition: 0.25s all ease-in-out;
    transition-delay: 0.2s;

    .logo {
      width: 4rem;
      height: 4rem;
    }

    .title {
      font-size: 1.6rem;
      color: var(--clr-nine-green);
      font-family: "P-800";
    }

    .close {
      transition-delay: 0.2s;
      transition: 0.25s all linear;
      width: 4rem;
      height: 4rem;
      margin-left: auto;
      // filter: invert(63%) sepia(100%) saturate(364%) hue-rotate(111deg)
      //   brightness(101%) contrast(92%);
    }
  }

  textarea {
    resize: none !important;
    border: none;
    outline: none;
    padding: 2rem;
    font-family: "P-400";
  }

  // .notes {
  //   width: 100%;
  //   height: 100%;
  //   resize: none !important;
  //   background: red;
  // }
}
.chat-closed {
  bottom: -55vh !important;
  width: 16rem !important;
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;

  .top {
    background: var(--clr-nine-green) !important;
    height: 5rem !important;
    padding: 0rem 1rem !important;

    .title {
      color: black !important;
      font-family: "P-600" !important;
      font-size: 1.8rem !important;
      text-transform: uppercase;
    }

    .close {
      width: 3rem !important;
      height: 3rem !important;
      margin-bottom: 0.15rem;
      transform: rotate(180deg);
      filter: invert(0%) sepia(0%) saturate(16%) hue-rotate(309deg)
        brightness(100%) contrast(107%) !important;
    }
  }
}

.chat-container {
  width: 25vw;
  height: 60vh;
  position: fixed;
  bottom: 0;
  right: 1rem;
  background: white;
  box-shadow: var(--shadow);
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  overflow: hidden;
  display: grid;
  grid-template-rows: 6rem 1fr 6rem;
  transition: 0.3s all linear;
  border: 0.2rem solid var(--clr-nine-green);
  border-bottom: none;

  .top {
    width: 100%;
    height: 6rem;
    border-bottom: 0.2rem solid var(--clr-nine-green);
    background: var(--clr-nine-green-very-light);
    display: flex;
    align-items: center;
    padding: 0rem 1.5rem;
    transition: 0.25s all ease-in-out;
    transition-delay: 0.2s;

    .logo {
      width: 4rem;
      height: 4rem;
    }

    .title {
      font-size: 1.6rem;
      color: var(--clr-nine-green);
      font-family: "P-800";
    }

    .close {
      transition-delay: 0.2s;
      transition: 0.25s all linear;
      width: 4rem;
      height: 4rem;
      margin-left: auto;
      // filter: invert(63%) sepia(100%) saturate(364%) hue-rotate(111deg)
      //   brightness(101%) contrast(92%);
    }
  }

  .messages {
    transition: 0.2s all ease-in-out;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;

    .no-messages {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      color: gray;
      font-family: "P-400";

      img {
        width: 50%;
        margin-bottom: 2rem;
      }
    }

    .chat-cell {
      max-width: 75%;
      display: flex;
      flex-direction: column;
      border-radius: 1.6rem;
      padding: 0.6rem 1rem;
      margin-bottom: 1rem;

      .img-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 25rem;
        position: relative;
        border-radius: 1rem;
        overflow: hidden;

        img {
          max-height: 25rem;
          object-fit: contain;
          width: 100%;
          position: relative;
          top: 0;
          left: 0;
          z-index: 1;
        }

        .loading {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          background: rgba($color: #000000, $alpha: 0.5);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 1rem;
          font-weight: 700;

          .spinner {
            width: 3rem;
            height: 3rem;
            background: transparent;
            border-radius: 50%;
            border-width: 0.3rem;
            border-color: gray;
            border-style: solid;
            border-top-color: white;
            animation: rotating 1s linear infinite;
            margin-bottom: 1rem;
          }
        }
      }

      .message {
        font-size: 1.4rem;
        font-family: "P-300", sans-serif;
      }

      .timestamp {
        font-size: 1rem;
        font-family: "P-200", sans-serif;
        color: gray;
        margin-top: 0.4rem;
      }

      .err {
        font-size: 1.2;
        font-family: "P-800";
        color: red;
        margin: 0.4rem 0rem;
      }

      &:last-child {
        margin-bottom: 0rem;
      }
    }

    .me {
      background: var(--clr-nine-green-light);
      border-top-right-radius: 0rem !important;
      margin-left: auto;
      .message {
        margin-left: auto;
      }
      .timestamp {
        margin-left: auto;
      }
    }

    .not-me {
      background: rgba(211, 211, 211, 0.3);
      border-top-left-radius: 0rem !important;
      margin-right: auto;
    }
  }

  .message-container {
    width: 100%;
    background: var(--clr-nine-green-light);
    height: 6rem;
    display: flex;
    align-items: center;
    margin-top: auto;

    .btn-container {
      height: 5rem;
      width: 5rem;
      display: flex;
      cursor: pointer;
      position: relative;

      img {
        height: 3rem;
        margin: auto;
      }

      &:hover {
        img {
          filter: invert(70%) sepia(34%) saturate(873%) hue-rotate(113deg)
            brightness(98%) contrast(100%);
        }
      }

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }

    input {
      width: calc(100% - 10rem);
      height: 4rem;
      border: none;
      outline: none;
      border-radius: 5rem;
      padding: 1.5rem;
      font-size: 1.4rem;
      font-family: "P-300", sans-serif;
    }
  }
}
</style>

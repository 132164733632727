<template>
  <div
    class="full-page flex-column page-padding"
    style="transition: 0.25s all ease-in"
  >
    <div class="flex-row" style="margin-bottom: 2rem">
      <div class="page-nav" @click="$router.back()">
        <img
          src="../../../assets/icons/ic_arrow_down.png"
          alt="Back"
          class="back-btn"
        />
        <span class="page-title">{{
          isEditMode ? "Edit Event" : "Event Detail"
        }}</span>
        <div class="processing" v-if="processing"></div>
      </div>

      <div class="page-actions" style="margin-left: auto">
        <button v-if="!isEditMode" @click="isEditMode = !isEditMode">
          Edit
        </button>
        <button
          v-if="isEditMode"
          @click="isEditMode = !isEditMode"
          class="negative"
        >
          ABORT
        </button>
        <button v-if="isEditMode" @click="onClickSave">Save</button>
      </div>
    </div>

    <div class="conrtent-wrapper">
      <div class="top-wrapper">
        <div class="wrapper">
          <div class="top">
            <div class="card flex-column">
              <span class="page-title">DETAILS</span>
              <div class="inner" v-if="!isEditMode">
                <KeyValView name="ID" :val="event.id" />
                <KeyValView
                  name="Creation Time"
                  :val="
                    $momentTZ(event.createdAt)
                      .tz('Asia/Shanghai')
                      .format('DD-MM-YYYY HH:mm a')
                  "
                />
                <KeyValView name="Title" :val="event.title || '--'" />
                <KeyValView name="Address" :val="event.address || '--'" />
                <KeyValView name="Location" :val="event.location || '--'" />
                <KeyValView
                  name="Start Time"
                  :val="
                    $momentTZ(event.startTime)
                      .tz('Asia/Shanghai')
                      .format('DD-MM-YYYY HH:mm a')
                  "
                />
                <KeyValView
                  name="Event Type"
                  :val="event.eventType == 1 ? 'Online' : 'Offline'"
                />
                <KeyValView
                  name="Event Status"
                  :val="event.eventStatus || '--'"
                />
                <KeyValView
                  name="Maximum # User Allowed"
                  :val="event.userNumber || '--'"
                />

                <KeyValView
                  name="Users Type"
                  :val="getUserLimitValue(event.limitUserType)"
                />

                <KeyValView
                  name="Allow SignUps"
                  :val="event.allowSignUp ? 'Yes' : 'No'"
                />

                <KeyValView
                  name="Publish Status"
                  :val="event.publishStatus || '--'"
                />
              </div>
              <div class="inner" v-if="isEditMode">
                <inputView
                  id="title"
                  name="Title"
                  v-model="editable.title"
                  class="input"
                  :enableInput="!processing"
                  placeholder="Event event title"
                  type="string"
                ></inputView>

                <inputView
                  id="location"
                  name="Location"
                  v-model="editable.location"
                  class="input"
                  :enableInput="!processing"
                  placeholder="Event event location"
                  type="string"
                ></inputView>

                <inputView
                  id="startTime"
                  name="Start Time"
                  v-model="editable.startTime"
                  class="input"
                  :enableInput="!processing"
                  placeholder="Event event start time"
                  type="datetime-local"
                ></inputView>

                <inputView
                  id="userNumber"
                  name="Maximum # User Allowed"
                  v-model="editable.userNumber"
                  class="input"
                  :enableInput="!processing"
                  placeholder="Event event location"
                  type="number"
                ></inputView>

                <inputView
                  id="evenlimitUserTypetType"
                  name="User Type Allowed"
                  v-model="editable.limitUserType"
                  class="input"
                  :enableInput="!processing"
                  placeholder="Users type allowed"
                  type="select"
                  :options="userTypeOptions"
                ></inputView>

                <inputView
                  id="eventType"
                  name="Event Type"
                  v-model="editable.eventType"
                  class="input"
                  :enableInput="!processing"
                  placeholder="Online or Offline"
                  type="select"
                  :options="eventTypeOptions"
                ></inputView>

                <inputView
                  id="eventStatus"
                  name="Event Status"
                  v-model="editable.eventStatus"
                  class="input"
                  :enableInput="!processing"
                  placeholder="In Progress , Started or Ended"
                  type="select"
                  :options="eventStatusTypes"
                ></inputView>

                <inputView
                  id="publishStatus"
                  name="Publish Status"
                  v-model="editable.publishStatus"
                  class="input"
                  :enableInput="!processing"
                  placeholder="UnPublished or Published"
                  type="select"
                  :options="publishStatuses"
                ></inputView>

                <inputView
                  id="allowSignUp"
                  name="Allow SignUp"
                  v-model="editable.allowSignUp"
                  class="input"
                  :enableInput="!processing"
                  placeholder="Yes or No"
                  type="select"
                  :options="allowSignUps"
                ></inputView>
              </div>

              <div class="content-address">
                <KeyValView
                  name="Address"
                  :val="event.address"
                  v-if="!isEditMode"
                />

                <KeyValView
                  name="Content"
                  :val="event.content"
                  v-if="!isEditMode"
                />

                <div class="ta-view" v-if="isEditMode">
                  <label class="contentLabel" for="address">Address</label>
                  <textarea
                    id="address"
                    class="notes"
                    v-model="editable.address"
                    @input="onAddressChange"
                    placeholder="Event address ... ✌"
                  ></textarea>
                </div>

                <div class="ta-view" v-if="isEditMode">
                  <label class="contentLabel" for="address">Content</label>

                  <textarea
                    id="content"
                    class="notes"
                    v-model="editable.content"
                    placeholder="Enter event content helike ... ✌"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="card flex-column" style="margin-top: 3rem">
            <span class="page-title">Images</span>
            <div v-if="isEditMode" class="images">
              <div v-for="(image, index) in files" :key="image">
                <div class="add-image" v-if="index == 0">
                  <img src="../../../assets/icons/ic_add.png" alt="" />
                  <span>Click here to select images</span>
                  <input
                    type="file"
                    multiple
                    accept="image/png, image/gif, image/jpeg"
                    @change="addImage"
                  />
                </div>

                <div class="preview" v-else>
                  <img class="pre" :src="image.url" alt="" />
                  <div class="overlay">
                    <div class="delete" @click="deleteImage(index)">
                      <img
                        class="del"
                        src="../../../assets/icons/ic_delete.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else>
              <div
                v-if="
                  event == null ||
                  event.eventImages == null ||
                  event.eventImages == []
                "
                class="no-signups"
              >
                <img src="../../../assets/svg/empty.svg" alt="" />
                No images for this event were found...
              </div>
              <div v-else class="images">
                <div v-for="image in event.eventImages" :key="image">
                  <div class="preview">
                    <img class="pre" :src="image" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wrapper">
          <div
            class="card flex-column"
            style="height: auto; margin-bottom: 3rem"
          >
            <span class="page-title">Host</span>

            <div
              class="existing-host"
              v-if="event && event.host"
              style="margin-top: 2rem"
            >
              <img class="avatar" :src="event.host.realPicture" alt="" />
              <div class="info">
                <span class="title">{{
                  event.host.fullName || event.host.nickName
                }}</span>
                <span class="gender">{{ event.host.gender || "--" }}</span>
                <span class="gender">{{ event.host.location || "--" }}</span>
              </div>
            </div>
          </div>

          <div class="card flex-column" v-if="isEditMode">
            <span class="page-title">Change Host</span>

            <div class="host" style="margin-top: 2rem">
              <span class="host-info"
                >To change the host , please search the name below and select a
                new host</span
              >

              <div class="content">
                <div class="left">
                  <div class="fields">
                    <inputView
                      id="nickName"
                      type="text"
                      placeholder="Enter a host name"
                      v-model="userSearchString"
                      class="input"
                      @onUpdate="onEnterSearch"
                      :enableInput="!processing"
                      :needBubble="false"
                    ></inputView>

                    <buttonView title="Search" @click="getUsers"></buttonView>
                  </div>
                </div>
                <div class="right">
                  <div
                    class="processing"
                    v-if="processing"
                    style="margin: auto; margin-top: 80%"
                  ></div>
                  <div v-else style="height: 100%">
                    <div v-if="filteredUser && filteredUser.length > 0">
                      <div v-for="user in filteredUser" v-bind:key="user.id">
                        <div
                          class="user"
                          @click="onClickUser(user)"
                          :class="{
                            selected:
                              selectedUser && selectedUser.id == user.id,
                          }"
                        >
                          <div class="check">
                            <div
                              class="checked"
                              v-if="selectedUser && selectedUser.id == user.id"
                            ></div>
                          </div>
                          <img
                            v-if="user.realPicture"
                            :src="user.realPicture"
                            alt=""
                            class="avatar"
                          />
                          <div v-if="!user.realPicture" class="no-avatar">
                            {{ user.nickName ? user.nickName[0] : "" }}
                          </div>
                          <div class="info">
                            <span class="name">{{ user.nickName }}</span>
                            <span class="gender">{{ user.gender }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="no-data">
                      <img src="../../../assets/svg/empty.svg" alt="" />
                      {{
                        !userSearchString
                          ? "enter user nickname to saerch"
                          : "No user found"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card flex-column"
            v-if="!isEditMode && event.eventSignUps"
          >
            <span class="page-title"
              >Sign Ups (
              {{ event.eventSignUps ? event.eventSignUps.length : 0 }} )</span
            >

            <div class="signups" v-if="event.eventSignUps.length > 0">
              <div v-for="item in event.eventSignUps" :key="item.id">
                <div class="item">
                  <img class="avatar" :src="item.user.realPicture" alt="" />
                  <div class="info">
                    <span class="title">{{
                      item.user.fullName || item.user.nickName
                    }}</span>
                    <span class="gender">{{ item.user.gender }}</span>
                  </div>
                  <button @click="onClickKickOut(item)">Kick Out</button>
                </div>
              </div>
            </div>

            <div v-else class="no-signups">
              <img src="../../../assets/svg/empty.svg" alt="" />
              {{ "no sign ups for this event yet" }}
            </div>
          </div>
        </div>
      </div>

      <div class="uploading" v-if="saving">
        <div class="processing"></div>
        <span>{{ uploading_msgs }}</span>
      </div>
    </div>
    <deleteSignUp
      v-if="showDeleteSignUp"
      :model="deletableSignUp"
      @onDeleteSuccess="onDeleteSignUpSuccess"
      @onAbortDelete="
        showDeleteSignUp = false;
        deletableSignUp = null;
      "
    ></deleteSignUp>
  </div>
</template>

<script>
import InputView from "../../../components/InputView.vue";
import ButtonView from "../../../components/ButtonView.vue";
import KeyValView from "../../../components/KeyValView.vue";
import DeleteEventSignUp from "../../../components/popups/DeleteEventSignUp.vue";

export default {
  name: "CreateEvent",
  components: {
    InputView,
    buttonView: ButtonView,
    KeyValView: KeyValView,
    deleteSignUp: DeleteEventSignUp,
  },
  data() {
    return {
      event: {},
      isEditMode: false,
      processing: false,
      data: {},
      userTypeOptions: [
        {
          key: 0,
          value: "All",
        },
        {
          key: 1,
          value: "Verified",
        },
        {
          key: 2,
          value: "VIP",
        },
      ],
      eventTypeOptions: [
        {
          key: 1,
          value: "Online",
        },
        {
          key: 2,
          value: "Offline",
        },
      ],
      eventStatusTypes: [
        {
          key: "in_progress",
          value: "In Progress",
        },
        {
          key: "started",
          value: "Started",
        },
        {
          key: "ended",
          value: "Ended",
        },
      ],
      publishStatuses: [
        {
          key: "unpublished",
          value: "UnPublished",
        },
        {
          key: "published",
          value: "Published",
        },
      ],
      allowSignUps: [
        {
          key: true,
          value: "Yes",
        },
        {
          key: false,
          value: "No",
        },
      ],
      files: [""],
      rawData: [],
      userSearchString: "",
      processing: false,
      users: [],
      filteredUser: [],
      selectedUser: null,
      uploading_msgs: "Please wait...",
      saving: false,
      editable: null,
      showDeleteSignUp: false,
      deletableSignUp: null,
    };
  },
  methods: {
    onDeleteSignUpSuccess() {
      console.log("onDeleteSignUpSuccess");
      this.showDeleteSignUp = false;
      this.deletableSignUp = null;
      this.getEvent();
    },
    onClickKickOut(item) {
      this.deletableSignUp = item;
      this.showDeleteSignUp = true;
    },
    getUserLimitValue(type) {
      switch (type) {
        case 0:
          return "All";
        case 1:
          return "Verified";
        case 2:
          return "VIP";
        default:
          return "UnKnown";
      }
    },
    deleteImage(index) {
      console.log("sup");
      let item = this.files[index];
      this.files.splice(index, 1);
      this.editable.eventImages = this.editable.eventImages.filter((e) => {
        return e != item.url;
      });
    },
    addImage(e) {
      const tmpFiles = e.target.files;
      console.table(tmpFiles);
      if (tmpFiles.length === 0) {
        return false;
      }
      const self = this;

      for (var f in tmpFiles) {
        const file = tmpFiles[f];

        this.files.push({
          file: file,
          url: URL.createObjectURL(file),
        });
      }
    },
    onClickUser(user) {
      this.selectedUser = user;
    },
    getUsers() {
      console.log("this.userSearchString = ", this.userSearchString);
      if (!this.userSearchString) {
        this.$Notifications.showNotification(
          "warning",
          "Please enter a nickname"
        );
        return;
      }
      this.processing = true;
      var order = `createdAt DESC`;
      let d = {
        nickName: {
          like: this.userSearchString,
          options: "i",
        },
      };
      this.$HTTP
        .post(
          `${this.$URLS.USERS.LIST}?order=${order}&page=1&results_per_page=25`,
          d
        )
        .then((res) => {
          this.users = res.data.data.users;
          this.filteredUser = res.data.data.users;
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onEnterSearch(e) {
      this.userSearchString = e.value;
    },
    async onClickSave() {
      this.saving = true;
      console.log("UPLOADING IMAGES");
      let uploads = await this.uploadImages();
      this.editable.hostId = this.selectedUser.id;
      this.editable.eventImages = uploads;
      delete this.editable["host"];
      delete this.editable["eventSignUps"];
      console.log(JSON.parse(JSON.stringify(this.editable)));
      this.$HTTP
        .patch(
          this.$URLS.EVENTS.GET_BY_ID.replace("%id", this.editable.id),
          this.editable
        )
        .then((res) => {
          // this.saving = false;
          // this.getEvent();
          // this.isEditMode = false;
          // this.$router.go(this.$route.currentRoute);
          window.location.reload();
        })
        .catch((err) => {
          this.saving = false;
        });
    },
    uploadImages() {
      return new Promise(async (resolve, reject) => {
        if (this.files.length <= 1) {
          resolve(this.editable.eventImages || []);
          return;
        }
        if (
          this.files &&
          this.editable &&
          this.editable.eventImages &&
          this.files.length - 1 == this.editable.eventImages.length
        ) {
          resolve(this.editable.eventImages || []);
          return;
        }

        let index = 1;
        let uploadedFiles = [];
        while (index < this.files.length) {
          if (this.files[index].file == null && this.files[index].url) {
            uploadedFiles.push(this.files[index].url);
            index++;
            continue;
          }
          const { ok, data, err } = await this.uploadFile(this.files[index]);
          uploadedFiles.push(data.result);
          index++;
        }
        resolve(uploadedFiles);
      });
    },
    uploadFile(file) {
      console.log(file);
      return new Promise((resolve, reject) => {
        var formData = new FormData();
        formData.append("file", file.file);
        formData.append("imgType", "events");
        this.$HTTP
          .post(this.$URLS.UPLOAD, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            resolve({
              ok: true,
              data: res.data,
            });
          })
          .catch((err) => {
            resolve({
              ok: false,
              data: null,
              err: err,
            });
            this.saving = false;
          });
      });
    },
    getEvent() {
      this.$HTTP
        .get(this.$URLS.EVENTS.DETAIL.replace("%id", this.id))
        .then((res) => {
          this.event = res.data.data[0];
          this.editable = res.data.data[0];
          this.selectedUser = this.editable.host;
          this.editable.eventImages.forEach((item) => {
            this.files.push({
              file: null,
              url: item,
            });
          });
        });
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getEvent();
  },
};
</script>

<style lang="scss" scoped>
.conrtent-wrapper {
  height: calc(100vh - 11rem);
  overflow-y: scroll;
}
.no-signups {
  height: 30rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;

  img {
    height: 20rem;
    margin-bottom: 1rem;
  }
}
.host-info {
  font-size: 1.2rem;
  width: 100%;
  padding-bottom: 2rem;
}
.existing-host {
  width: 100%;
  display: grid;
  grid-template-columns: 8rem 1fr;
  border-radius: 0.4rem;
  gap: 2rem;
  padding-bottom: 1rem;

  .avatar {
    height: 8rem;
    width: 8rem;
    border-radius: 2rem;
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 1.4rem;
      color: black;
      font-family: "P-500";
    }

    .gender {
      font-size: 1.2rem;
      color: gray;
      margin-top: 0.3rem;
    }
  }
}

.host {
  width: 100%;
  height: 51.8rem;
  display: flex;
  flex-direction: column;
  .content {
    width: 100%;
    height: 75%;
    display: grid;
    grid-template-rows: 7rem 1fr;

    .left {
      padding-right: 2rem;
      width: 100%;
      min-height: 100%;
      display: flex;
      .fields {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3rem;
      }
    }

    .right {
      width: 100%;
      height: 100%;
      background: #f5f5f5;
      margin-right: -20rem;
      border-top-right-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
      overflow-y: scroll;

      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 1.2rem;
        color: gray;
        font-family: "P-600";
        text-transform: lowercase;

        img {
          margin-bottom: 2rem;
          height: 50%;
        }
      }
      .selected {
        background: #e0f2f1;
      }
      .user {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        border-bottom: 0.1rem solid #e0e0e0;
        transition: 0.1s all ease-in-out;

        &:hover {
          background: #eeeeee;
        }

        .check {
          width: 2rem;
          height: 2rem;
          border: 0.2rem solid lightgrey;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .checked {
            width: 1.2rem;
            height: 1.2rem;
            background: var(--clr-nine-green);
            border-radius: 50%;
          }
        }

        .avatar {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          overflow-y: hidden;
          margin: 0rem 2rem;
          border: 0.1rem solid lightgrey;
        }

        .no-avatar {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          overflow-y: hidden;
          margin: 0rem 2rem;
          background: var(--clr-skin);
          border: 0.1rem solid var(--clr-nine-green);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.6rem;
          font-family: "P-600";
          text-transform: uppercase;
        }

        .info {
          display: flex;
          flex-direction: column;

          .name {
            width: 100%;
            font-size: 1.4rem;
            font-family: "P-500";
            color: black;
          }

          .gender {
            width: 100%;
            font-size: 1.2rem;
            font-family: "P-400";
            color: gray;
          }
        }
      }
    }
  }
}

.signups {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  margin-top: 3rem;

  .item {
    widows: 100%;
    display: grid;
    grid-template-columns: 5rem 1fr 10rem;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid lightgray;
    margin-bottom: 2rem;
    .avatar {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
      margin-right: 1rem;
    }

    .info {
      width: 100%;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 1.3rem;
        color: black;
        font-family: "P-500";
      }

      .gender {
        font-size: 1.2rem;
        color: gray;
        margin-top: 0.3rem;
      }
    }

    button {
      background: none;
      border: none;
      border: 0.2rem solid var(--red);
      color: var(--red);
      font-size: 1.3rem;
      font-family: "P-500";
      border-radius: 3rem;
      height: 4rem;
      cursor: pointer;
      transition: 0.25s all ease-in-out;

      &:hover {
        background: var(--red);
        color: white;
        font-size: 1.5rem;
      }
    }
  }
}
.uploading {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .processing {
    width: 10rem !important;
    height: 10rem !important;
  }
  span {
    margin-top: 3rem;
    color: white;
    font-size: 1.4rem;
  }
}
.top {
  width: 100%;
}

.top-wrapper {
  display: grid;
  grid-template-columns: 1fr 30%;
  gap: 3rem;
}
.wrapper {
  width: 100%;
  // height: 89vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 0.2rem;

  .inner {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    padding-top: 3rem;
  }

  .content-address {
    width: 100%;
    display: grid;
    grid-template-columns: 33.5% 1fr;
    gap: 1rem;
    margin-top: 3rem;

    .ta-view {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .contentLabel {
      font-size: 1.2rem;
      color: gray;
      font-family: "P-500", sans-serif;
      text-transform: uppercase;
      padding-left: 0.2rem;
      height: 2.5rem;
    }
  }

  .notes {
    resize: none;
    max-width: 100vw;
    height: 20rem;
    padding: 1rem;
    border: 0.1rem solid lightgray !important;
  }

  .images {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    padding-top: 3rem;
    align-items: center;

    .preview {
      position: relative;
      border-radius: 0.4rem;
      overflow: hidden;
      box-shadow: var(--shadow);

      .pre {
        width: 100%;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        padding: 2rem;
        display: flex;
        width: 100%;
        height: 100%;
        transition: 0.15s all ease-in-out;

        &:hover {
          background: rgba($color: #fff, $alpha: 0.6);
        }

        .delete {
          width: 3rem !important;
          height: 3rem !important;
          margin-left: auto;
          border-radius: 50%;
          border: 0.4rem solid red;
          padding: 0.4rem;
          transition: 0.15s all ease-in-out;

          .del {
            width: 100%;
          }

          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }
    .add-image {
      width: 100%;
      height: 20rem;
      background: var(--clr-nine-green-very-light);
      border: 0.4rem dashed var(--clr-nine-green);
      border-radius: 0.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: 0.15s all ease-in-out;

      &:hover {
        background: var(--clr-nine-green-light);
      }

      img {
        width: 4rem;
        height: 4rem;
        margin-bottom: 3rem;
      }

      span {
        font-size: 1.4rem;
        font-family: "P-500";
      }

      input {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent;
        opacity: 0;
      }
    }
  }
}
</style>

<template>
  <div class="popup">
    <div class="card w-30">
      <h1 class="card-title">{{ title }}</h1>

      <div class="card-content">
        <h3>{{ desc }}</h3>

        <div class="current" v-if="current && current.for">
          <h4>
            {{ current.titles ? current.titles.for : "Updating Data For :" }}
          </h4>
          <div class="grid">
            <div
              class="flex-column"
              v-for="item in current.for"
              v-bind:key="item.key"
            >
              <span class="key">{{ item.key }}</span>
              <span class="val">{{ item.val }}</span>
            </div>
          </div>
        </div>

        <div class="current" v-if="current && current.current">
          <h4>
            {{ current.titles ? current.titles.current : "Current Values :" }}
          </h4>
          <div class="grid">
            <div
              class="flex-column"
              v-for="item in current.current"
              v-bind:key="item.key"
            >
              <span class="key">{{ item.key }}</span>
              <span class="val">{{ item.val }}</span>
            </div>
          </div>
        </div>

        <div class="fields">
          <div v-for="field in fields" v-bind:key="field.id">
            <inputView
              :id="field.id"
              :type="field.type"
              :placeholder="field.placeholder"
              :name="field.name"
              v-model="field.val"
              :options="field.options"
              class="input"
              :enableInput="!processing"
              :needBubble="true"
            ></inputView>
          </div>
        </div>
      </div>

      <div class="actions">
        <button class="bt-simple" @click="$emit('abort')">Abort</button>
        <buttonView
          :title="posBtnTitle"
          :processing="processing"
          :enabled="true"
          @click="$emit('onClickPosBtn')"
        ></buttonView>
      </div>
    </div>
  </div>
</template>

<script>
import InputView from "../InputView.vue";
import ButtonView from "../ButtonView.vue";

export default {
  name: "Popup",
  props: {
    title: String,
    desc: String,
    fields: Array,
    processing: Boolean,
    current: Object,
    posBtnTitle: String,
  },
  components: {
    inputView: InputView,
    buttonView: ButtonView,
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="full-page flex-column page-padding">
    <filters
      :fields="filters"
      :processing="processing"
      @onFiltersToggle="onFiltersToggle"
      @onApplyFilters="onApplyFilters"
      @onClearFilters="onClearFilters"
    ></filters>

    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-row">
          <span class="page-title">Nine Messages</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <toggler
          :preIndex="order"
          :schema="sortingTogglerData"
          @onToggle="onToggleOrder"
        />

        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
        ></pager>

        <div class="download" @click="onClickCreateArticle">
          <img src="../../../assets/icons/ic_add.png" />
          <span>Creat New Article</span>
        </div>

        <div class="download" @click="downloadData">
          <img src="../../../assets/icons/ic_download.png" />
          <span>Download</span>
        </div>
      </div>
      <div
        class="list-wrapper"
        v-if="messages.length > 0"
        :class="{ 'loading-history-anim': processing }"
      >
        <table>
          <thead>
            <tr class="header">
              <th class="index">ID</th>
              <th>Creation Date (CST)</th>
              <th>Publish Status</th>
              <th>Title</th>
              <th>Sub Title</th>
              <th>Cover Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in messages">
              <td class="index" style="width: 10%">
                {{ item.id || "--" }}
              </td>
              <td>
                {{
                  item.createdAt
                    ? $momentTZ(item.createdAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td style="text-transform: capitalize">
                {{ item.publishStatus || "--" }}
              </td>
              <td>
                {{ item.title || "--" }}
              </td>
              <td>
                {{ item.guidance || "--" }}
              </td>
              <td>
                <img :src="item.coverImage" :alt="item.title" class="cover" />
              </td>

              <td style="width: 30rem">
                <div class="cell-actions">
                  <button @click="onClickDetail(item)">Details</button>
                  <button class="red" @click="onClickDeleteEvent(item)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no messages to show here...</span>
      </div>
    </div>

    <transition>
      <updatePoup
        v-if="showUpdatePopup"
        :model="updateModel"
        @onAbort="
          showUpdatePopup = false;
          updateModel = {};
        "
        @onSuccess="
          showUpdatePopup = false;
          updateModel = {};
          getAllMessages();
        "
      ></updatePoup>
    </transition>

    <transition>
      <deleteMessagePopup
        v-if="showDeleteEventPopup"
        :model="deletableEvent"
        @onAbort="
          showDeleteEventPopup = false;
          deletableEvent = {};
        "
        @onSuccess="
          showDeleteEventPopup = false;
          deletableEvent = {};
          getAllMessages();
        "
      ></deleteMessagePopup>
    </transition>
  </div>
</template>

<script>
import Filters from "../../../components/Filters.vue";
import Pager from "../../../components/Pager.vue";
import UpdateUserStatusPopup from "../../../components/popups/UpdateUserStatusPopup.vue";
import Toggler from "../../../components/Toggler.vue";
import DeleteNineMessage from "../../../components/popups/DeleteNineMessage.vue";

const ExcelExporter = require("../../../utils/excel");

export default {
  name: "NineMessages",
  components: {
    filters: Filters,
    pager: Pager,
    updatePoup: UpdateUserStatusPopup,
    toggler: Toggler,
    deleteMessagePopup: DeleteNineMessage,
  },
  data() {
    return {
      showDeleteEventPopup: false,
      order: "ASC",
      showUpdatePopup: false,
      updateModel: {},
      processing: false,
      sortingTogglerData: {
        title: "SORT BY",
        desc: "Creation Date",
        options: [
          {
            key: "ASC",
            val: "Ascending",
          },
          {
            key: "DESC",
            val: "Descending",
          },
        ],
      },
      filters: [
        {
          id: "id",
          name: "Message ID",
          type: "text",
          placeholder: "ID of the Message",
          val: "",
          required: false,
        },
        {
          id: "title",
          name: "Message Title",
          type: "text",
          placeholder: "Title of the message",
          val: "",
          required: false,
        },
        {
          id: "publishStatus",
          name: "Publish Status",
          type: "select",
          placeholder: "UnPublished or Published",
          val: "",
          options: [
            {
              key: "unpublished",
              value: "UnPublished",
            },
            {
              key: "published",
              value: "Published",
            },
          ],
          required: false,
        },
      ],
      messages: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      filtersData: {},
    };
  },
  methods: {
    downloadData() {
      this.downloading = true;
      if (this.count === this.messages.length) {
        ExcelExporter.JSONToCSVConvertor(
          this.getParsedList(this.messages),
          "Nine-Messages",
          true
        );
        this.downloading = false;
      } else {
        console.log("The count is not same");
        var order = `createdAt ${this.messages}`;
        this.$HTTP
          .post(
            `${this.$URLS.ARTICLES.LIST}?order=${order}&page=1&results_per_page=${this.count}`,
            {}
          )
          .then((res) => {
            let messages = res.data.data.messages;
            ExcelExporter.JSONToCSVConvertor(
              this.getParsedList(messages),
              `Nine-Messages`,
              true
            );
            this.downloading = false;
          })
          .catch(() => {
            this.downloading = false;
          });
      }
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          ID: item.id || "--",
          "Created At": item.createdAt
            ? this.$momentTZ(item.createdAt)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          "Publish Status": `${item.publishStatus}` || "--",
          Title: item.title || "--",
          Content: item.content || "--",
          "Cover Image": item.coverImage || "--",
        });
      });
      return parsed;
    },
    onClickCreateArticle() {
      this.$router.push("/nine/messages/create");
    },
    onClickDeleteEvent(item) {
      this.deletableEvent = item;
      this.showDeleteEventPopup = true;
    },
    onToggleOrder(e) {
      this.order = e;
      localStorage.setItem("messagesOrder", e);
      this.getAllMessages();
    },
    onClearFilters() {
      localStorage.setItem("messagesFilters", null);
      this.filtersData = {};
      this.getAllMessages();
    },
    onApplyFilters() {
      var data = {};
      this.filters.forEach((item) => {
        if (item.val) {
          if (item.id == "id") {
            data[item.id] = item.val;
          } else if (item.type == "text") {
            data[item.id] = {
              like: item.val,
              options: "i",
            };
          } else {
            data[item.id] = item.val;
          }
        }
      });
      this.filtersData = data;
      this.getAllMessages();
      localStorage.setItem("messagesFilters", JSON.stringify(this.filters));
    },
    onClickDetail(item) {
      this.$router.push({
        name: "NineMessageDetail",
        query: { id: item.id },
      });
    },
    onClickUpdateStatus(item) {
      this.updateModel = item;
      this.showUpdatePopup = true;
    },
    onPageUpdated(page) {
      this.page = page;
      localStorage.setItem("messagesPage", parseInt(page));
      this.getAllMessages();
    },
    getAllMessages(data) {
      this.processing = true;
      var order = `createdAt ${this.order}`;
      this.$HTTP
        .post(
          `${this.$URLS.NINE_MESSAGES.LIST}?order=${order}&page=${this.page}&results_per_page=${this.pageSize}`,
          this.filtersData || {}
        )
        .then((res) => {
          this.messages = res.data.data.messages;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onFiltersToggle(opened) {
      setTimeout(() => {
        this.processListHeight();
      }, 100);
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 40);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    let page = localStorage.getItem("messagesPage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("messagesOrder") || "DESC";
    this.order = order;

    let filters = localStorage.getItem("messagesFilters");
    if (filters && filters != "null") {
      this.filters = JSON.parse(filters);
      this.onApplyFilters();
    } else {
      this.getAllMessages();
    }
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
.cover {
  height: 8rem;
  margin-top: 0.4rem;
  border-radius: 0.4rem;
}
</style>

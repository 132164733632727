<template>
  <div class="flex-column">
    <div class="flex-row" v-if="name">
      <label :for="id">{{ name }}</label>
      <div class="bubble" v-if="needBubble && modelValue"></div>
      <div v-if="!hideReset" class="reset">
        <img
          @click="onClickReset"
          v-if="modelValue"
          class="filter-reset"
          src="../assets/icons/ic_reset.png"
          alt=""
        />
      </div>
    </div>
    <input
      v-if="type !== 'select'"
      :id="id"
      :type="type"
      :value="modelValue"
      @input="onChange"
      :placeholder="placeholder"
      autocomplete="off"
      :disabled="!enableInput"
    />

    <select
      :disabled="!enableInput"
      v-if="type === 'select'"
      :id="id"
      :value="modelValue"
      @input="onChange"
    >
      <option disabled value="">{{ placeholder }}</option>
      <option v-for="e in options" :key="e.key" :value="e.key">
        {{ e.value }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "InputView",
  props: {
    id: String,
    name: String,
    val: String,
    placeholder: String,
    type: String,
    modelValue: String,
    enableInput: Boolean,
    options: Array,
    needBubble: Boolean,
    hideReset: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onClickReset() {
      this.$emit("update:modelValue", null);
      this.$emit("onUpdate", {
        id: this.id,
        value: null,
      });
    },
    onChange(e) {
      this.$emit("update:modelValue", e.target.value);
      this.$emit("onUpdate", {
        id: this.id,
        value: e.target.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-reset {
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  transition: 0.25s all ease-in-out;
  border-radius: 50%;

  &:hover {
    transform: scale(1.25);
    box-shadow: var(--shadow);
  }
}

.reset {
  margin-left: auto;
}
label {
  font-size: 1.2rem;
  color: gray;
  font-family: "P-500", sans-serif;
  text-transform: uppercase;
  padding-left: 0.2rem;
  height: 2.5rem;
}

input {
  border: none;
  outline: none;
  border-bottom: 0.1rem solid lightgray;
  padding: 0.5rem 0rem;
  font-size: 1.4rem;
  color: black;
  font-family: "P-300", sans-serif;

  &:focus {
    border-bottom: 0.1rem solid var(--clr-nine-green);
  }
}
</style>
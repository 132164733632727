<template>
  <div
    class="full-page flex-column anaytics-page-padding"
    style="height: 100%; overflow: scroll"
  >
    <tc
      title="Users Growth Analytics"
      @onClickSwitch="(index) => onClickSwitch(index)"
      @onDateSelected="(date) => onDateSelected(date)"
      @onClickDownload="downloadData"
      :date="date"
      :allowedDates="dates"
    />

    <div class="analytics-container">
      <div class="table" v-if="switchIndex == 1">
        <pv :schematics="genderPie" />
        <pv :schematics="registrationPie" />
        <pv :schematics="verifiedPie" />
        <pv :schematics="unVerifiedPie" />
        <pv :schematics="top4CitiesPie" />
        <pv :schematics="rotwPie" />
      </div>

      <div class="charts" v-else>
        <cv :schematics="totalsTrend" />
        <cv :schematics="statusTrends" />
        <cv :schematics="verifiedTrends" />
        <cv :schematics="unVerifiedTrends" />
      </div>
    </div>
  </div>
</template>

<script>
const ExcelExporter = require("../../../../utils/excel");
import PieView from "../../../../components/PieView.vue";
import ChartView from "../../../../components/ChartView.vue";
import AnalyticsTitleContainer from "../../../../components/AnalyticsTitleContainer.vue";
export default {
  name: "UsersGrowth",
  components: {
    pv: PieView,
    cv: ChartView,
    tc: AnalyticsTitleContainer,
  },
  data() {
    return {
      dates: [],
      analytics: null,
      typeIndex: 2,
      users: null,
      date: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      switchIndex: 0,
      genderPie: null,
      registrationPie: null,
      verifiedPie: null,
      unVerifiedPie: null,
      top4CitiesPie: null,
      rotwPie: null,
      totalsTrend: null,
      statusTrends: null,
      verifiedTrends: null,
      unVerifiedTrends: null,
    };
  },
  methods: {
    downloadData() {
      let arr = [];
      this.rawData.forEach((item) => {
        arr.push(Object.assign(JSON.parse(item.data), { date: item.date }));
      });
      ExcelExporter.JSONToCSVConvertor(
        this.getParsedList(arr),
        "User Growth Analytics",
        true
      );
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          "Date (CST)": item.date
            ? this.$momentTZ(item.date).tz("Asia/Shanghai").format("DD-MM-YYYY")
            : "--",
          Total: item.total,
          "Completed Registration": item.completeTotal,
          "InComplete Registration": item.incompleteTotal,
          Male: item.totalMale,
          Female: item.totalFemale,
          Verified: item.verifiedTotal,
          UnVerified: item.unverifiedTotal,
          "Male Verified": item.maleVerifiedTotal,
          "Female Verified": item.femaleVerifiedTotal,
          "Male UnVerified": item.maleUnverifiedTotal,
          "Female UnVerified": item.femaleUnverifiedTotal,
          "Top 4 Cities Verified": item.topFourCityVerified,
          "Top 4 Cities UnVerified": item.topFourCityUnVerified,
          "Rest Of The World Verified": item.restOfTheWorldVerified,
          "Rest Of The World UnVerified": item.restOfTheWorldUnVerified,
        });
      });
      return parsed;
    },
    onClickSwitch(index) {
      this.switchIndex = index;
    },
    onDateSelected(date) {
      let start = this.$moment(date).format("YYYY-MM-DD");
      let selected = this.rawData.filter((item) => {
        let itemDate = this.$moment(item.date).format("YYYY-MM-DD");
        if (itemDate == start) {
          return item;
        }
      });

      this.users = null;
      if (selected[0]) {
        this.populateData(JSON.parse(selected[0].data));
      }
    },
    getData(date) {
      this.users = null;
      this.$HTTP.get(this.$URLS.ANALYTICS.USERS_GROWTH).then((data) => {
        let arr = data.data.users.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        this.rawData = arr;
        this.dates = this.rawData.map((x) => x.date);
        this.maxDate = this.dates[this.dates.length - 1];
        this.date = this.maxDate;
        this.onDateSelected(this.maxDate);
        this.populateTrends();
      });
    },

    populateTrends() {
      let dates = this.dates.map((x) => this.$moment(x).format("D/MM"));

      this.totalsTrend = {
        title: "Active Users Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Total",
              data: this.rawData.map((x) => JSON.parse(x.data).total),
              borderColor: "#1de9b6",
            },
            {
              label: "Male",
              data: this.rawData.map((x) => JSON.parse(x.data).totalMale),
              borderColor: "#42A5F5",
            },
            {
              label: "Female",
              data: this.rawData.map((x) => JSON.parse(x.data).totalFemale),
              borderColor: "#EC407A",
            },
            {
              label: "Others",
              data: this.rawData.map((x) => {
                let data = JSON.parse(x.data);
                return data.total - (data.totalMale + data.totalFemale);
              }),
              borderColor: "#616161",
            },
          ],
        },
      };

      this.statusTrends = {
        title: "Registration Status Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Complete",
              data: this.rawData.map((x) => JSON.parse(x.data).completeTotal),
              borderColor: "#1de9b6",
            },
            {
              label: "InComplete",
              data: this.rawData.map((x) => JSON.parse(x.data).incompleteTotal),
              borderColor: "#42A5F5",
            },
          ],
        },
      };

      this.verifiedTrends = {
        title: "Verified Users Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Male",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).maleVerifiedTotal
              ),
              borderColor: "#42A5F5",
            },
            {
              label: "Female",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).femaleVerifiedTotal
              ),
              borderColor: "#EC407A",
            },
            {
              label: "Top 4 Cities",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).topFourCityVerified
              ),
              borderColor: "#8D6E63",
            },
            {
              label: "Rest of the World",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).restOfTheWorldVerified
              ),
              borderColor: "#1DE9B6",
            },
          ],
        },
      };

      this.unVerifiedTrends = {
        title: "UnVerified Users Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Male",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).maleUnverifiedTotal
              ),
              borderColor: "#42A5F5",
            },
            {
              label: "Female",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).femaleUnverifiedTotal
              ),
              borderColor: "#EC407A",
            },
            {
              label: "Top 4 Cities",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).topFourCityUnVerified
              ),
              borderColor: "#8D6E63",
            },
            {
              label: "Rest of the World",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).restOfTheWorldUnVerified
              ),
              borderColor: "#1DE9B6",
            },
          ],
        },
      };
    },

    populateData(data) {
      this.users = data;
      data: this.rawData.map((x) => {
        let data = JSON.parse(x.data);
        return data.total - (data.totalMale + data.totalFemale);
      }),
        (this.genderPie = {
          title: "Total",
          labels: ["Male", "Female", "Others"],
          data: [
            this.users.totalMale,
            this.users.totalFemale,
            this.users.total - (this.users.totalMale + this.users.totalFemale),
          ],
          backgroundColor: ["#42A5F5", "#EC407A", "#616161"],
        });

      this.registrationPie = {
        title: "By Registration",
        labels: ["Complete", "InComplete"],
        data: [this.users.completeTotal, this.users.incompleteTotal],
        backgroundColor: ["#00D8FF", "#41B883"],
      };

      this.verifiedPie = {
        title: "Verified",
        labels: ["Male", "Female"],
        data: [this.users.maleVerifiedTotal, this.users.femaleVerifiedTotal],
        backgroundColor: ["#42A5F5", "#EC407A"],
      };

      this.unVerifiedPie = {
        title: "UnVerified",
        labels: ["Male", "Female"],
        data: [
          this.users.maleUnverifiedTotal,
          this.users.femaleUnverifiedTotal,
        ],
        backgroundColor: ["#42A5F5", "#EC407A"],
      };

      this.top4CitiesPie = {
        title: "Top 4 Cities",
        labels: ["Verified", "UnVerified"],
        data: [
          this.users.topFourCityVerified,
          this.users.topFourCityUnVerified,
        ],
        backgroundColor: ["#00D8FF", "#41B883"],
      };

      this.rotwPie = {
        title: "Rest Of The World",
        labels: ["Verified", "UnVerified"],
        data: [
          this.users.restOfTheWorldVerified,
          this.users.restOfTheWorldUnVerified,
        ],
        backgroundColor: ["#00D8FF", "#41B883"],
      };
    },
  },
  created() {
    this.getData(new Date());
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="popup">
    <div class="card w-30">
      <h1 class="card-title">Kick Out User</h1>
      <div class="card-content">
        <h3>Are you sure want to kick out the following user ?</h3>

        <div class="current" style="margin-bottom: 2rem">
          <h4>YOU ARE ABOUT TO KICK OUT THE FOLLOWING USER :</h4>
          <div class="grid">
            <div class="flex-column">
              <span class="key">ID</span>
              <span class="val">{{ model.id }}</span>
            </div>
            <div class="flex-column">
              <span class="key">Name</span>
              <span class="val">{{
                model.user.fullName || model.user.nickName
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="actions">
        <button class="bt-simple" @click="$emit('onAbortDelete')">Abort</button>
        <buttonView
          title="Kick Out"
          :processing="processing"
          @click="onClickDelete"
        ></buttonView>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "./Popup.vue";
import InputView from "../InputView.vue";
import ButtonView from "../ButtonView.vue";

export default {
  name: "DeleteEventSignUp",
  components: {
    popup: Popup,
    inputView: InputView,
    buttonView: ButtonView,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      processing: false,
      current: {
        for: [],
      },
    };
  },
  methods: {
    onClickDelete() {
      this.processing = true;
      this.$HTTP
        .delete(this.$URLS.EVENTS.SIGN_UP_BY_ID.replace("%id", this.model.id))
        .then((res) => {
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            `User has been kicked out of this event`
          );
          console.log("success");
          this.$emit("onDeleteSuccess", true);
        })
        .catch((err) => {
          this.processing = false;
          console.log("delete error");
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

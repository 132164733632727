<template>
  <div class="group">
    <div class="content" v-if="show">
      <div class="values">
        <div class="title">
          <span class="k">{{ schematics.title }}</span>
          <span class="v">{{ total || "--" }}</span>
        </div>

        <div
          class="kv"
          v-for="(item, index) in schematics.data"
          v-bind:key="item"
        >
          <span class="k"> {{ schematics.labels[index] }} </span>
          <span class="v">{{ item || "0" }}</span>
          <span class="p">{{
            `${schematics.percentages[index]}%` || "0%"
          }}</span>
          <div
            class="legend"
            :style="{ background: schematics.backgroundColor[index] }"
          ></div>
        </div>
      </div>

      <div class="chart">
        <charts
          v-if="chartData"
          id="gender=donghnut"
          ref="chartRef"
          type="pie"
          :data="chartData"
        ></charts>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  name: "PieView",
  props: ["schematics"],
  components: {
    charts: Vue3ChartJs,
  },
  data() {
    return {
      total: 0,
      show: false,
    };
  },
  methods: {
    getPercentage(values) {
      let total = values.reduce((accumulator, curr) => accumulator + curr);
      this.total = total;
      let percentage = values.map((v) => {
        if (v <= 0) return 0;
        return ((v / total) * 100).toFixed(1);
      });
      return percentage;
    },
    populate() {
      if (!this.schematics || !this.schematics.data) return;
      this.show = false;
      this.schematics.percentages = this.getPercentage(this.schematics.data);
      this.chartData = {
        datasets: [
          {
            backgroundColor: this.schematics.backgroundColor,
            data: this.schematics.percentages,
          },
        ],
      };
      setTimeout(() => {
        this.show = true;
      }, 1);
    },
  },
  watch: {
    schematics: function (newVal, oldVal) {
      this.populate();
    },
  },
  mounted() {
    this.populate();
  },
};
</script>

<style lang="scss" scoped>
.pv {
  width: 100%;
  height: 100%;
  background: red;
}
</style>
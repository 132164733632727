<template>
  <div class="flex-column">
    <div v-for="item in inputs" v-bind:key="item.id">
      <input-view
        :id="item.id"
        :type="item.type"
        :placeholder="item.placeholder"
        :name="item.name"
        v-model="item.val"
        class="input"
        @onUpdate="onUpdate"
        :enableInput="!processing"
      ></input-view>
    </div>

    <button-view
      :processing="processing"
      :enabled="enabledClick"
      :title="actionName"
      @click="onClick"
    ></button-view>
  </div>
</template>

<script>
import InputView from "./InputView.vue";
import ButtonView from "./ButtonView.vue";

export default {
  name: "FormView",
  components: {
    InputView,
    ButtonView,
  },
  props: {
    inputs: Array,
    actionName: String,
    processing: Boolean,
  },
  data() {
    return {
      enabledClick: false,
    };
  },
  methods: {
    onClick() {
      if (!this.enabledClick) return;
      console.log("on click in form");
      this.$emit("onClick");
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    onUpdate() {
      this.enabledClick = this.inputs.every((item) => {
        if (item.type === "email") {
          return (
            item.val && item.val.length > 0 && this.validateEmail(item.val)
          );
        } else {
          return item.val && item.val.length > 0;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  margin-bottom: 2rem;
}
</style>
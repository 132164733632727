<template>
  <div
    class="full-page flex-column page-padding"
    style="padding-top: 0rem !important"
  >
    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-row">
          <span class="page-title">Admins</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <div class="download" @click="showAddPopup = true">
          <img src="../../../assets/icons/ic_add.png" />
          <span>Add New Admin</span>
        </div>
      </div>
      <div class="list-wrapper" v-if="users.length > 0">
        <table>
          <thead>
            <tr class="header">
              <th class="index">ID</th>
              <th>Creation Date (CST)</th>
              <th>Email</th>
              <th style="width: 20rem">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in users">
              <td class="index" style="width: 10%; height: 5rem">
                {{ item.id || "--" }}
              </td>
              <td>
                {{
                  item.createdAt
                    ? $momentTZ(item.createdAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                {{ item.email || "--" }}
              </td>
              <td>
                <div class="cell-actions">
                  <button
                    v-if="item.email != 'hi@meetnine.com'"
                    class="red"
                    @click="onClickDelete(item)"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no users to show here...</span>
      </div>

      <deletePopup
        v-if="showDeletePopup"
        :model="deletable"
        @onAbort="
          showDeletePopup = false;
          deletable = null;
        "
        @onSuccess="
          showDeletePopup = false;
          getAllUsers();
        "
      ></deletePopup>

      <addPopup
        v-if="showAddPopup"
        @onAbort="showAddPopup = false"
        @onSuccess="
          showAddPopup = false;
          getAllUsers();
        "
      ></addPopup>
    </div>
  </div>
</template>

<script>
import DeleteAdminPopup from "../../../components/popups/DeleteAdminPopup.vue";
import AddAdminPopup from "../../../components/popups/AddAdminPopup.vue";

export default {
  name: "Admins",
  components: {
    deletePopup: DeleteAdminPopup,
    addPopup: AddAdminPopup,
  },
  data() {
    return {
      processing: false,
      users: [],
      showDeletePopup: false,
      deletable: null,
      showAddPopup: false,
    };
  },
  methods: {
    onClickDelete(item) {
      console.log(item);
      this.deletable = item;
      this.showDeletePopup = true;
    },
    onClickAdd() {},
    getAllUsers() {
      this.processing = true;
      this.$HTTP
        .get(this.$URLS.ADMIN.LIST)
        .then((res) => {
          this.users = res.data.data;
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 30);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    this.getAllUsers();
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="flex-column">
    <label>{{ name }}</label>
    <span>{{ val }}</span>
  </div>
</template>

<script>
export default {
  name: "KeyValView",
  props: {
    id: String,
    name: String,
    val: String,
    placeholder: String,
    type: String,
    modelValue: String,
    enableInput: Boolean,
    options: Array,
    needBubble: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onClickReset() {
      this.$emit("update:modelValue", null);
      this.$emit("onUpdate", {
        id: this.id,
        value: null,
      });
    },
    onChange(e) {
      console.log("selected value = ", this.selectedVal);
      this.$emit("update:modelValue", e.target.value);
      this.$emit("onUpdate", {
        id: this.id,
        value: e.target.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

label {
  font-size: 1.2rem;
  color: gray;
  font-family: "P-500", sans-serif;
  text-transform: uppercase;
  height: 2.5rem;
}
span {
  font-size: 1.3rem;
  color: black;
  font-family: "P-500", sans-serif;
  min-height: 2.5rem;
  border-bottom: 0.2rem solid lightgray;
}
</style>

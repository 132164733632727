<template>
  <div class="popup">
    <div class="card w-75">
      <h1 class="card-title">Add Sign Up Question</h1>
      <div class="card-content">
        <h3>
          Please enter question title (this won't show to users , just for
          reference), upload question image , all four options and correct
          option
        </h3>

        <div class="content">
          <div class="left">
            <div class="top">
              <inputView
                id="title"
                type="text"
                placeholder="Enter title for the question"
                name="Title"
                v-model="question.title"
                class="input"
                :needBubble="false"
                :enableInput="!processing"
              ></inputView>

              <inputView
                id="correctOption"
                name="Correct Option"
                v-model="question.correctOption"
                class="input"
                :enableInput="!processing"
                placeholder="correct option for question"
                type="select"
                :options="options"
              ></inputView>

              <inputView
                id="optionOne"
                type="text"
                placeholder="option one value"
                name="Option 1"
                v-model="question.optionOne"
                class="input"
                :needBubble="false"
                :enableInput="!processing"
              ></inputView>

              <inputView
                id="optionTwo"
                type="text"
                placeholder="option two value"
                name="Option 2"
                v-model="question.optionTwo"
                class="input"
                :needBubble="false"
                :enableInput="!processing"
              ></inputView>

              <inputView
                id="optionThree"
                type="text"
                placeholder="option three value"
                name="Option 3"
                v-model="question.optionThree"
                class="input"
                :needBubble="false"
                :enableInput="!processing"
              ></inputView>

              <inputView
                id="optionFour"
                type="text"
                placeholder="option four value"
                name="Option 4"
                v-model="question.optionFour"
                class="input"
                :needBubble="false"
                :enableInput="!processing"
              ></inputView>
            </div>
            <div class="center" style="padding-top: 2rem; margin-top: auto">
              <button class="bt-simple" @click="$emit('onAbort')">Abort</button>
              <buttonView
                title="Save"
                :processing="processing"
                :enabled="true"
                @click="onClickSave"
              ></buttonView>
            </div>
          </div>
          <div class="right">
            <div class="top">
              <label>Question Image</label>

              <div class="add-image">
                <span>Select Image</span>
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  @change="onSelectImage"
                />
              </div>
            </div>
            <img v-if="selectImage" class="img" :src="selectImage.url" alt="" />
            <img
              v-else
              class="placeholder"
              src="../../assets/svg/empty.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "./Popup.vue";
import InputView from "../InputView.vue";
import ButtonView from "../ButtonView.vue";

export default {
  name: "AddSignUpQuestionPopup",
  components: {
    popup: Popup,
    inputView: InputView,
    buttonView: ButtonView,
  },
  data() {
    return {
      question: {
        correctOption: null,
      },
      processing: false,
      selectImage: null,
      userTypeOptions: [
        {
          key: 0,
          value: "All",
        },
        {
          key: 1,
          value: "Verified",
        },
        {
          key: 2,
          value: "VIP",
        },
      ],
      options: [
        {
          key: "optionOne",
          value: "Option 1",
        },
        {
          key: "optionTwo",
          value: "Option 2",
        },
        {
          key: "optionThree",
          value: "Option 3",
        },
        {
          key: "optionFour",
          value: "Option 4",
        },
      ],
    };
  },
  methods: {
    onSelectImage(e) {
      const file = e.target.files[0];
      this.selectImage = {
        file: file,
        url: URL.createObjectURL(file),
      };
    },
    onClickSave() {
      this.createNew();
    },
    async createNew() {
      if (!this.question.title) {
        this.$Notifications.showNotification(
          "warning",
          "Please enter title for the question"
        );
        return;
      }

      if (!this.question.correctOption) {
        this.$Notifications.showNotification(
          "warning",
          "Please select correct option for the question"
        );
        return;
      }

      if (!this.question.optionOne) {
        this.$Notifications.showNotification(
          "warning",
          "Please enter value for option 1"
        );
        return;
      }

      if (!this.question.optionTwo) {
        this.$Notifications.showNotification(
          "warning",
          "Please enter value for option 2"
        );
        return;
      }

      if (!this.question.optionThree) {
        this.$Notifications.showNotification(
          "warning",
          "Please enter value for option 3"
        );
        return;
      }

      if (!this.question.optionFour) {
        this.$Notifications.showNotification(
          "warning",
          "Please enter value for option 4"
        );
        return;
      }

      const { ok, url } = await this.uploadImage();
      if (!ok) return;

      this.question.questionImageUrl = url;
      this.processing = true;
      this.$HTTP
        .post(this.$URLS.SIGNUP_QUESTIONS.ROOT, this.question)
        .then((res) => {
          this.$Notifications.showNotification(
            "success",
            "Question added successfully"
          );
          this.$emit("onSuccess", res.data);
        })
        .catch(() => {
          this.processing = false;
        });
    },
    uploadImage() {
      return new Promise((resolve, reject) => {
        if (!this.selectImage || !this.selectImage.file) {
          this.$Notifications.showNotification(
            "warning",
            "Please select an image for the question"
          );
          resolve({ ok: false, url: null });
          return;
        }

        this.processing = true;
        var formData = new FormData();
        formData.append("file", this.selectImage.file);
        formData.append("imgType", "signup-questions");
        this.$HTTP
          .post(this.$URLS.UPLOAD, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log("THEN");
            console.log(res);
            resolve({
              ok: true,
              url: res.data.result,
            });
          })
          .catch((err) => {
            console.log("CATCH");
            console.log(err);
            resolve({
              ok: false,
              data: null,
              err: err,
            });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  width: 35rem;
  margin-left: auto;
}
.content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 35rem;
  margin: 2rem 0rem;
  gap: 1rem;

  .left {
    display: flex;
    flex-direction: column;
    .top {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      row-gap: 2rem;
    }

    .center {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-top: 2rem;
    }

    .bottom {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    border-left: 0.2rem dashed lightgray;
    padding-left: 1rem;

    .top {
      display: flex;

      margin-bottom: 2rem;
      label {
        font-size: 1.2rem;
        color: gray;
        font-family: "P-500", sans-serif;
        text-transform: uppercase;
        padding-left: 0.2rem;
        height: 3rem;
        line-height: 3rem;
      }

      .add-image {
        margin-left: auto;
        height: 3rem;
        width: 12rem;
        background: transparent;

        position: relative;
        display: flex;
        overflow: hidden;
        border-radius: 0.4rem;
        transition: 0.25s all ease-in-out;

        span {
          width: 100%;
          height: 100%;
          color: var(--clr-nine-green);
          font-family: "P-500";
          text-align: center;
          line-height: 3rem;
          font-size: 1.3rem;
        }

        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          opacity: 0;
        }

        &:hover {
          background: var(--clr-nine-green);

          span {
            color: white;
          }
        }
      }
    }

    .img {
      width: 100%;
    }

    .placeholder {
      padding: 4rem;
      margin: auto 0;
    }
  }
}
</style>

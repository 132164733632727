<template>
  <popup
    title="Refund"
    :desc="desc"
    :processing="processing"
    :fields="fields"
    :current="current"
    posBtnTitle="Refund"
    @abort="$emit('onAbort')"
    @onClickPosBtn="onClickUpdate"
  ></popup>
</template>

<script>
import Popup from "./Popup.vue";
import { CONFIRMATION_KEY } from "../../assets/Constants";
import axios from "axios";

export default {
  name: "UpdateUserStatusPopup",
  components: {
    popup: Popup,
  },
  props: {
    model: Object,
    paymentType: String,
  },
  data() {
    return {
      desc: "You are about to refund a user order. Are you sure you want to refund? Please check the details below carefully and enter the secreet key as confirmation.",
      processing: false,
      current: {
        for: [],
      },
      fields: [CONFIRMATION_KEY],
    };
  },
  methods: {
    onClickUpdate() {
      let confirmationKey = this.fields[0].val;
      if (
        !confirmationKey ||
        confirmationKey == "" ||
        confirmationKey != process.env.VUE_APP_REFUND_KEY
      ) {
        this.$Notifications.showNotification(
          "danger",
          "Incorrect confirmation key. Please enter a valid confirmation key"
        );
        return;
      }

      this.processing = true;
      this.$HTTP
        .post(`${this.$URLS.ORDERS.REFUND}?id=${this.model.id}`)
        .then((res) => {
          console.log(res);
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            "Order refunded successfully"
          );
          this.$emit("onSuccess");
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          this.$Notifications.showNotification("danger", e.message);
        });

      // axios
      //   .post(process.env.VUE_APP_REFUND_URL, {
      //     orderId: this.model.id,
      //     secret: process.env.VUE_APP_REFUND_SECRET,
      //   })
      //   .then((res) => {
      //     this.processing = false;
      //     this.$Notifications.showNotification(
      //       "success",
      //       "Order refunded successfully"
      //     );
      //   })
      //   .catch((e) => {
      //     this.$Notifications.showNotification("danger", e.message);
      //     this.processing = false;
      //   });
    },
  },
  mounted() {
    this.current.for = [
      { key: "Order ID", val: this.model.id },
      {
        key: "Order Creation Date (CST)",
        val: this.$momentTZ(this.model.createdAt)
          .tz("Asia/Shanghai")
          .format("DD-MM-YYYY HH:mm A"),
      },
      { key: "Item Name (EN)", val: this.model.good.nameEn },
      { key: "Item Name (CN)", val: this.model.good.nameCn },
      {
        key: "Payment Date (CST)",
        val: this.model.paymentAt
          ? this.$momentTZ(this.model.paymentAt)
              .tz("Asia/Shanghai")
              .format("DD-MM-YYYY HH:mm A")
          : "--",
      },
      { key: "Amount", val: this.model.totalPrice },
      { key: "Payment Channel", val: this.paymentType },
    ];

    this.current.current = [
      { key: "User ID", val: this.model.user.id },
      { key: "Nick Name", val: this.model.user.fullName || "--" },
    ];

    this.current.titles = {
      for: "Order Details",
      current: "User Details",
    };
  },
};
</script>

<style lang="scss" scoped></style>

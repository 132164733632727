import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Users from "../views/homepages/users/Users";
import VerifiedUsers from "../views/homepages/users/VerifiedUsers";
import UnPushedUsers from "../views/homepages/users/UnPushedUsers";
import UserDetail from "../views/homepages/users/Detail.vue";
import Messages from "../views/Messages.vue";
import Quitters from "../views/homepages/users/Quitters.vue";
import UnFrienders from "../views/homepages/users/UnFrienders.vue";
import UnFriendersMain from "../views/homepages/users/UnfriendersMain";
import SuspiciousUnFrienders from "../views/homepages/users/SuspiciousUnFrienders.vue"
import ProhibitedWordlist from "../views/homepages/users/ProhibitedWordlist.vue";
import Orders from "../views/homepages/users/Orders.vue";
import Analytics from "../views/homepages/users/Analytics.vue";
import UsersGrowth from "../views/homepages/users/analytics/UsersGrowth.vue";
import ActiveUsers from "../views/homepages/users/analytics/ActiveUsers.vue";
import Invites from "../views/homepages/users/analytics/Invites.vue";
import QuittersAnalytics from "../views/homepages/users/analytics/Quitters.vue";
import ChatsAnalytics from "../views/homepages/users/analytics/Chats.vue";
import PebblesAnalytics from "../views/homepages/users/analytics/Pebbles.vue";
import Events from "../views/homepages/users/Events";
import CreateEvent from "../views/homepages/users/CreateEvent";
import EventDetail from "../views/homepages/users/EventDetail";
import Articles from "../views/homepages/users/Articles";
import ArticleDetail from "../views/homepages/users/ArticleDetail";
import CreateArticle from "../views/homepages/users/CreateArticle";
import NineMessages from "../views/homepages/users/NineMessages";
import NineMessageDetail from "../views/homepages/users/NineMessageDetail";
import CreateNineMessage from "../views/homepages/users/CreateNineMessage";
import Admins from "../views/homepages/users/Admins";
import Popover from "../views/homepages/users/Popover";
import Pebble from "../views/homepages/users/Pebble";
import SignUpQuestions from "../views/homepages/users/SignUpQuestions";
import Settings from "../views/homepages/users/Settings";
import Crayons from "../views/homepages/users/Crayons";


const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/admins",
        name: "Admins",
        component: Admins,
      },
      {
        path: "/users",
        name: "Users",
        component: Users,
      },
      {
        path: "/verified_users",
        name: "VerifiedUsers",
        component: VerifiedUsers,
      },
      {
        path: "/unpushed_users",
        name: "UnPushedUsers",
        component: UnPushedUsers,
      },
      {
        path: "/user/detail",
        name: "UserDetails",
        component: UserDetail,
      },
      {
        path: "/messages",
        name: "Messages",
        component: Messages,
      },
      {
        path: "/quitters",
        name: "Quitters",
        component: Quitters,
      },
      {
        path: "/unfrienders",
        name: "UnFriendersMain",
        component: UnFriendersMain,
        children: [
          {
            path: "/unfrienders/all",
            name: "UnFrienders",
            component: UnFrienders,
          },
          {
            path: "/unfrienders/suspicious",
            name: "SuspiciousUnFrienders",
            component: SuspiciousUnFrienders,
          },
        ]
      },
      {
        path: "/wordlist",
        name: "ProhibitedWordlist",
        component: ProhibitedWordlist,
      },
      {
        path: "/orders",
        name: "Orders",
        component: Orders,
      },
      {
        path: "/events",
        name: "Events",
        component: Events,
      },
      {
        path: "/events/create",
        name: "CreateEvent",
        component: CreateEvent,
      },
      {
        path: "/events/detail",
        name: "EventsDetail",
        component: EventDetail,
      },
      {
        path: "/articles",
        name: "Articles",
        component: Articles,
      },
      {
        path: "/article/detail",
        name: "ArticleDetail",
        component: ArticleDetail,
      },
      {
        path: "/articles/create",
        name: "CreateArticle",
        component: CreateArticle,
      },
      {
        path: "/nine/messages",
        name: "NineMessages",
        component: NineMessages,
      },
      {
        path: "/nine/message/detail",
        name: "NineMessageDetail",
        component: NineMessageDetail,
      },
      {
        path: "/nine/messages/create",
        name: "CreateNineMessage",
        component: CreateNineMessage,
      },
      {
        path: "/popovers",
        name: "Popovers",
        component: Popover,
      },
      {
        path: "/pebbles",
        name: "Pebble",
        component: Pebble,
      },
      {
        path: "/analytics",
        name: "Analytics",
        component: Analytics,
        children: [
          {
            path: "/analytics/users/growth",
            name: "UsersGrowth",
            component: UsersGrowth,
          },
          {
            path: "/analytics/users/active",
            name: "ActiveUsers",
            component: ActiveUsers,
          },
          {
            path: "/analytics/invites",
            name: "InvitesAnalytics",
            component: Invites,
          },
          {
            path: "/analytics/quitters",
            name: "QuittersAnalytics",
            component: QuittersAnalytics,
          },
          {
            path: "/analytics/chats",
            name: "ChatsAnalytics",
            component: ChatsAnalytics,
          },
          {
            path: "/analytics/pebbles",
            name: "PebblesAnalytics",
            component: PebblesAnalytics,
          },
        ],
      },
      {
        path: "/signup-questions",
        name: "SignUpQuestions",
        component: SignUpQuestions,
      },
      {
        path: "/crayons",
        name: "Crayons",
        component: Crayons,
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
      },
    ],
  },
];

export { routes };

<template>
  <div class="full-page flex-column page-padding">
    <filters
      :fields="filters"
      :processing="processing"
      @onFiltersToggle="onFiltersToggle"
      @onApplyFilters="onApplyFilters"
      @onClearFilters="onClearFilters"
    ></filters>

    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-centered">
          <span class="page-title">Orders</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <!-- TYPE TOGGLE -->
        <toggler :schema="paymentTypeTogglerData" @onToggle="onPaymentToggle" />

        <!-- SORT -->
        <toggler
          :schema="sortingTogglerData"
          :preIndex="order"
          @onToggle="onSortToggle"
          style="margin-left: 1rem !important"
        />

        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
        ></pager>
        <div
          class="processing"
          style="margin-top: 0.5rem"
          v-if="downloading"
        ></div>
        <div class="download" @click="downloadData" v-else>
          <img src="../../../assets/icons/ic_download.png" />
          <span>Download</span>
        </div>
      </div>
      <div class="list-wrapper" v-if="orders.length > 0">
        <table>
          <thead>
            <tr class="header">
              <th class="index idField">Order ID</th>
              <th>Order Creation Date (CST)</th>
              <th>Payment State</th>
              <th>Payment Date (CST)</th>
              <th>Good Purchased</th>
              <th>Amount (CNY)</th>
              <th class="idField">User ID</th>
              <th>User Name</th>
              <th>User Reg Date (CST)</th>
              <th>User DOB</th>
              <th>User Location</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in orders">
              <td class="index idField">
                {{ item.id || "--" }}
              </td>
              <td>
                {{
                  item.createAt
                    ? $momentTZ(item.createAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                {{ getOrderStateName(item.paymentState) || "--" }}
              </td>
              <td>
                {{
                  item.paymentAt
                    ? $momentTZ(item.paymentAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                {{ item.good ? item.good.nameEn : "--" }} <br />
                {{ item.good ? item.good.nameCn : "--" }}
              </td>
              <td>
                {{ item.totalPrice || "--" }}
              </td>
              <td class="idField">
                {{ item.user ? item.user.id : "--" }}
              </td>
              <td>
                {{
                  item.user
                    ? item.user.fullName && item.user.fullName.length > 0
                      ? item.user.fullName
                      : "--"
                    : "--"
                }}
              </td>
              <td>
                {{
                  item.user && item.user.createdAt
                    ? $momentTZ(item.user.createdAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                {{ item.user ? item.user.age : "--" }}
              </td>
              <td>
                {{ item.user ? item.user.location : "--" }}
              </td>
              <td>
                <div class="cell-actions">
                  <button v-if="item.processing" class="disabled">
                    <div class="processing"></div>
                  </button>
                  <button
                    v-if="!item.processing"
                    :class="{ disabled: item.paymentState != 1 }"
                    @click="onClickRefund(item)"
                  >
                    Refund
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no orders to show here...</span>
      </div>
    </div>

    <transition>
      <refundPopup
        v-if="showRefundPopup"
        :model="refundableOrder"
        :paymentType="paymentType"
        @onAbort="
          showRefundPopup = false;
          refundableOrder = {};
        "
        @onSuccess="
          showRefundPopup = false;
          refundableOrder = {};
          getAllOrders();
        "
      ></refundPopup>
    </transition>
  </div>
</template>

<script>
import Filters from "../../../components/Filters.vue";
import Pager from "../../../components/Pager.vue";
import Toggler from "../../../components/Toggler.vue";
import RefundPopup from "../../../components/popups/RefundPopup.vue";
import { GET_ORDER_STATE_NAME } from "../../../assets/Constants";
const ExcelExporter = require("../../../utils/excel");

export default {
  name: "Orders",
  components: {
    filters: Filters,
    pager: Pager,
    toggler: Toggler,
    refundPopup: RefundPopup,
  },
  data() {
    return {
      order: "DESC",
      processing: false,
      filters: [
        {
          id: "id",
          name: "Order ID",
          type: "text",
          placeholder: "ID of the Order",
          val: "",
          required: false,
        },
        {
          id: "userId",
          name: "User ID",
          type: "text",
          placeholder: "ID of the User",
          val: "",
          required: false,
        },
        {
          id: "paymentState",
          name: "Payment State",
          type: "select",
          placeholder: "Select one payment state",
          val: "",
          options: [],
          required: false,
        },
        {
          id: "createAt",
          name: "Order Creation Date",
          type: "date",
          placeholder: "Select a date for order creation",
          val: "",
          required: false,
        },
      ],
      orders: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      filtersData: {},
      paymentType: "wechat",
      paymentTypeTogglerData: {
        title: "Payment",
        desc: "Channel",
        options: [
          {
            key: "wechat",
            val: "Wechat",
          },
          {
            key: "apple",
            val: "Apple",
          },
        ],
      },
      sortingTogglerData: {
        title: "SORT BY",
        desc: "Creation Date",
        options: [
          {
            key: "ASC",
            val: "Ascending",
          },
          {
            key: "DESC",
            val: "Descending",
          },
        ],
      },
      showRefundPopup: false,
      refundableOrder: null,
      downloading: false,
      wechatPaymentStates: [
        {
          key: 0,
          value: "Unpaid",
        },
        {
          key: 1,
          value: "Paid",
        },
        {
          key: 2,
          value: "Refunded",
        },
        {
          key: 3,
          value: "Refund in progress",
        },
        {
          key: 4,
          value: "Closed",
        },
        {
          key: 5,
          value: "Payment Error",
        },
        {
          key: 6,
          value: "Refund Error",
        },
      ],
      applePaymentStates: [
        {
          key: "Waiting",
          value: "Waiting",
        },
        {
          key: "Success",
          value: "Success",
        },
        {
          key: "Failed",
          value: "Failed",
        },
        {
          key: "Closed",
          value: "Closed",
        },
      ],
    };
  },
  methods: {
    processFiltersPaymentStates() {
      if (this.paymentType == "wechat") {
        this.filters[2].options = this.wechatPaymentStates;
      } else {
        this.filters[2].options = this.applePaymentStates;
      }
    },
    downloadData() {
      this.downloading = true;
      if (this.count === this.orders.length) {
        ExcelExporter.JSONToCSVConvertor(
          this.getParsedList(this.orders),
          "Orders",
          true
        );
        this.downloading = false;
      } else {
        console.log("The count is not same");
        var order = `updatedAt ${this.order}`;
        this.$HTTP
          .post(
            `${this.$URLS.ORDERS.LIST}?order=${order}&page=1&results_per_page=${this.count}&type=${this.paymentType}`,
            {}
          )
          .then((res) => {
            let orders = res.data.data.orders;
            const parsedList = this.getParsedList(orders);
            ExcelExporter.JSONToCSVConvertor(
              this.getParsedList(orders),
              `${this.paymentType} Orders`,
              true
            );
            this.downloading = false;
          })
          .catch((e) => {
            console.log(
              "🚀 ~ file: Orders.vue ~ line 356 ~ downloadData ~ e",
              e
            );
            this.downloading = false;
          });
      }
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        try {
          parsed.push({
            ID: item.id || "--",
            "Order Creation Date": item.createAt
              ? this.$momentTZ(item.createAt)
                  .tz("Asia/Shanghai")
                  .format("MM/DD/YY")
              : "--",
            "Payment State": item.paymentState,
            "Payment Date": item.paymentAt
              ? this.$momentTZ(item.paymentAt)
                  .tz("Asia/Shanghai")
                  .format("MM/DD/YY")
              : "--",
            "Goods Purchased": item.good ? `${item.good.nameEn}` : "--",
            Amount: item.totalPrice || "--",
            "User ID": item.user ? item.user.id : "--",
            "User Name": item.user ? item.user.fullName : "--",
            "User Creation Date": item.user
              ? this.$momentTZ(item.user.createdAt)
                  .tz("Asia/Shanghai")
                  .format("MM/DD/YY")
              : "--",
            "User DOB": item.user ? item.user.age : "--",
            Location: item.user ? item.user.location : "--",
          });
        } catch (error) {
          console.log(
            "🚀 ~ file: Orders.vue ~ line 399 ~ list.forEach ~ error",
            error
          );
        }
      });
      console.log(
        "🚀 ~ file: Orders.vue ~ line 406 ~ getParsedList ~ parsed.length",
        parsed.length
      );
      return parsed;
    },

    onClickRefund(item) {
      if (item.paymentState != 1) return;
      this.refundableOrder = item;
      this.showRefundPopup = true;
    },
    onPaymentToggle(option) {
      this.page = 1;
      this.paymentType = option;
      this.getAllOrders();
      this.processFiltersPaymentStates();
    },
    onSortToggle(e) {
      console.log(e);
      this.order = e;
      localStorage.setItem("ordersOrder", e);
      this.getAllOrders();
    },
    onClearFilters() {
      localStorage.setItem("filters", null);
      this.filtersData = {};
      this.getAllOrders();
    },
    onApplyFilters() {
      var data = {};
      this.filters.forEach((item) => {
        if (item.val) {
          if (item.type == "text") {
            data[item.id] = {
              like: item.val,
              options: "i",
            };
          } else if (item.type != "date") {
            data[item.id] = item.val;
          }
        }
      });

      if (this.filters[3].val != "") {
        let start = new Date(this.filters[3].val);
        let end = new Date(this.filters[3].val);
        end.setHours(28, 59, 59);
        if (start.getTime() > end.getTime()) {
          this.$Notifications.showNotification(
            "warning",
            "Start date cannot be later then end date"
          );
          return;
        }
        console.log("HAVE DATE");
        data["and"] = [{ createAt: { gt: start } }, { createAt: { lt: end } }];
      }

      this.filtersData = data;
      console.log(JSON.parse(JSON.stringify(data)));
      this.getAllOrders();
      localStorage.setItem("filters", JSON.stringify(this.filters));
    },
    onClickDetail(item) {
      this.$router.push({
        name: "UserDetails",
        query: { id: item.id },
      });
    },
    onPageUpdated(page) {
      this.page = page;
      localStorage.setItem("ordersPage", parseInt(page));
      this.getAllOrders();
    },
    getAllOrders(data) {
      this.processing = true;
      var order = `createAt ${this.order}`;
      console.log(order);
      this.$HTTP
        .post(
          `${this.$URLS.ORDERS.LIST}?type=${this.paymentType}&order=${order}&page=${this.page}&results_per_page=${this.pageSize}`,
          this.filtersData || {}
        )
        .then((res) => {
          this.orders = res.data.data.orders;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onFiltersToggle() {
      setTimeout(() => {
        this.processListHeight();
      }, 300);
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 30);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    let page = localStorage.getItem("ordersPage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("ordersOrder") || "ASC";
    console.log("order = ", order);
    console.log("this.order = ", this.order);
    this.order = order;

    let filters = localStorage.getItem("filters");
    if (filters && filters != "null") {
      this.filters = JSON.parse(filters);
      this.onApplyFilters();
    } else {
      this.getAllOrders();
    }
    this.processListHeight();
    this.getOrderStateName = GET_ORDER_STATE_NAME;
    this.processFiltersPaymentStates();
  },
};
</script>

<style lang="scss" scoped>
.idField {
  max-width: 10rem !important;
  padding: 0.4rem 0.4rem !important;
  word-wrap: break-word !important;
  white-space: normal !important;
}
</style>

<template>
  <div
    class="full-page flex-column anaytics-page-padding"
    style="height: 100%; overflow: scroll"
  >
    <tc
      title="Chats Analytics"
      @onClickSwitch="(index) => onClickSwitch(index)"
      @onDateSelected="(date) => onDateSelected(date)"
      @onClickDownload="downloadData"
      :date="date"
      :allowedDates="dates"
    />

    <div class="analytics-container">
      <div class="table" v-if="switchIndex == 1">
        <pv :schematics="totalSendersPie" />
        <pv :schematics="totalRecieversPie" />
        <pv :schematics="uniqueSendersPie" />
        <pv :schematics="uniqueRecieversPie" />
      </div>

      <div class="charts" v-else>
        <cv :schematics="sendersTrend" />
        <cv :schematics="recieversTrend" />
        <cv :schematics="uniqueSendersTrend" />
        <cv :schematics="uniqueRecieversTrend" />
      </div>
    </div>
  </div>
</template>

<script>
const ExcelExporter = require("../../../../utils/excel");
import PieView from "../../../../components/PieView.vue";
import ChartView from "../../../../components/ChartView.vue";
import AnalyticsTitleContainer from "../../../../components/AnalyticsTitleContainer.vue";
export default {
  name: "ChatsAnalytics",
  components: {
    pv: PieView,
    cv: ChartView,
    tc: AnalyticsTitleContainer,
  },
  data() {
    return {
      dates: [],
      analytics: null,
      typeIndex: 2,
      users: null,
      date: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      totalsTrend: null,
      switchIndex: 0,
      sendersTrend: null,
      recieversTrend: null,
      uniqueSendersTrend: null,
      uniqueRecieversTrend: null,
      totalSendersPie: null,
      totalRecieversPie: null,
      uniqueSendersPie: null,
      uniqueRecieversPie: null,
    };
  },
  methods: {
    downloadData() {
      let arr = [];
      this.rawData.forEach((item) => {
        arr.push(Object.assign(JSON.parse(item.data), { date: item.date }));
      });

      ExcelExporter.JSONToCSVConvertor(
        this.getParsedList(arr),
        "Chats Analytics",
        true
      );
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          "Date (CST)": item.date
            ? this.$momentTZ(item.date).tz("Asia/Shanghai").format("DD-MM-YYYY")
            : "--",
          Total: item.total,
          "Male Senders": item.maleSenders,
          "Female Senders": item.femaleSenders,
          "Male Recievers": item.maleRecievers,
          "Female Recievers": item.femaleRecievers,
          "Unique Male Senders": item.uniqueMaleSenders,
          "Unique Female Senders": item.uniqueFemaleSenders,
          "Unique Male Recievers": item.uniqueMaleRecievers,
          "Unqiue Female Recievers": item.uniqueFemaleRecievers,
        });
      });
      return parsed;
    },
    onClickSwitch(index) {
      this.switchIndex = index;
    },
    onDateSelected(date) {
      let start = this.$moment(date).format("YYYY-MM-DD");
      let selected = this.rawData.filter((item) => {
        let itemDate = this.$moment(item.date).format("YYYY-MM-DD");
        if (itemDate == start) {
          return item;
        }
      });

      this.users = null;
      this.populateData(JSON.parse(selected[0].data));
    },
    getData() {
      this.users = null;
      this.$HTTP.get(this.$URLS.ANALYTICS.CHATS).then((data) => {
        let arr = data.data.users.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        this.rawData = arr;
        this.dates = this.rawData.map((x) => x.date);
        this.maxDate = this.dates[this.dates.length - 1];
        this.date = this.maxDate;
        this.onDateSelected(this.maxDate);
        this.populateTrends();
      });
    },
    populateTrends() {
      let dates = this.dates.map((x) => this.$moment(x).format("D/MM"));

      this.sendersTrend = {
        title: "Sender Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Male",
              data: this.rawData.map((x) => JSON.parse(x.data).maleSenders),
              borderColor: "#42A5F5",
            },
            {
              label: "Female",
              data: this.rawData.map((x) => JSON.parse(x.data).femaleSenders),
              borderColor: "#EC407A",
            },
          ],
        },
      };

      this.recieversTrend = {
        title: "Reciever Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Male",
              data: this.rawData.map((x) => JSON.parse(x.data).maleRecievers),
              borderColor: "#42A5F5",
            },
            {
              label: "Female",
              data: this.rawData.map((x) => JSON.parse(x.data).femaleRecievers),
              borderColor: "#EC407A",
            },
          ],
        },
      };

      this.uniqueSendersTrend = {
        title: "Unique Sender Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Male",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).uniqueMaleSenders
              ),
              borderColor: "#42A5F5",
            },
            {
              label: "Female",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).uniqueFemaleSenders
              ),
              borderColor: "#EC407A",
            },
          ],
        },
      };

      this.uniqueRecieversTrend = {
        title: "Unique Reciever Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Male",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).uniqueMaleRecievers
              ),
              borderColor: "#42A5F5",
            },
            {
              label: "Female",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).uniqueFemaleRecievers
              ),
              borderColor: "#EC407A",
            },
          ],
        },
      };
    },
    populateData(data) {
      this.users = data;

      this.totalSendersPie = {
        title: "Total By Senders",
        labels: ["Male", "Female"],
        data: [this.users.maleSenders, this.users.femaleSenders],
        backgroundColor: ["#42A5F5", "#EC407A"],
      };

      this.totalRecieversPie = {
        title: "Total By Recievers",
        labels: ["Male", "Female"],
        backgroundColor: ["#42A5F5", "#EC407A"],
        data: [this.users.maleRecievers, this.users.femaleRecievers],
      };

      this.uniqueSendersPie = {
        title: "Unique Senders",
        labels: ["Male", "Female"],
        backgroundColor: ["#42A5F5", "#EC407A"],
        data: [this.users.uniqueMaleSenders, this.users.uniqueFemaleSenders],
      };

      this.uniqueRecieversPie = {
        title: "Unique Recievers",
        labels: ["Male", "Female"],
        backgroundColor: ["#42A5F5", "#EC407A"],
        data: [
          this.users.uniqueMaleRecievers,
          this.users.uniqueFemaleRecievers,
        ],
      };
    },
  },
  created() {
    this.getData(new Date());
  },
};
</script>

<style lang="scss"></style>

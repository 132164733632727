<template>
  <div class="sorter">
    <div
      class="flex-column"
      style="justify-center: center; align-items: center"
    >
      <span class="title">{{ schema.title }} </span>
      <span class="field">{{ schema.desc }}</span>
    </div>
    <div class="bottom">
      <button
        @click="onToggle(schema.options[0])"
        style="margin-right: auto"
        :class="{
          disabled: selected != schema.options[0].key,
          enabled: selected == schema.options[0].key,
        }"
      >
        {{ schema.options[0].val }}
      </button>
      <button
        @click="onToggle(schema.options[1])"
        style="margin-left: auto"
        :class="{
          disabled: selected != schema.options[1].key,
          enabled: selected == schema.options[1].key,
        }"
      >
        {{ schema.options[1].val }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toggler",
  props: {
    schema: Object,
    preIndex: String,
  },
  watch: {
    preIndex: function (newVal, oldVal) {
      this.preSelect(newVal);
    },
  },
  data() {
    return {
      selected: "",
    };
  },
  methods: {
    onToggle(option) {
      this.selected = option.key;
      this.$emit("onToggle", option.key);
    },
    preSelect(key) {
      this.schema.options.every((element) => {
        if (element.key == key) {
          this.selected = element.key;
          return false;
        }
        return true;
      });
    },
  },
  mounted() {
    if (this.schema) {
      this.selected = this.schema.options[0].key;
    }
    if (this.preIndex) {
      this.preSelect(this.preIndex);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
import { createRouter, createWebHistory } from "vue-router";
const Notifications = require("../controllers/Notifications");

import { routes } from "./pagesRoutes.js";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from, next) => {
  if (
    to.name != 'UserDetails' &&
    !(to.name == 'Users' && from.name == undefined) &&
    !(from.name == 'UserDetails' && to.name == 'Users')
  ) {
    localStorage.setItem("filters", null);
    localStorage.setItem("usersPage", 1);
    localStorage.setItem("usersOrder", 'DESC');
  }
});


router.beforeEach((to, from, next) => {
  if (to.name === 'Messages') {
    let chatOn = localStorage.getItem('chatOn') || false;
    if (chatOn == 'false') {
      Notifications.showNotification("warning", "Chat is turned off. Please turn chat on from the nav bar");
      next(from)
      return
    }
  }
  next();
})

export default router;

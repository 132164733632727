<template>
  <div class="item" v-if="show">
    <span class="title">{{ this.schematics.title || "--" }}</span>
    <div class="chart">
      <charts
        id="gender=donghnut"
        ref="chartRef"
        type="line"
        :data="schematics.chartData"
      ></charts>
    </div>
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  name: "ChartView",
  props: ["schematics"],
  components: {
    charts: Vue3ChartJs,
  },
  data() {
    return {
      show: false,
      title: "--",
      chartData: {
        datasets: [],
        labels: [],
      },
    };
  },
  methods: {
    populate() {
      this.show = false;
      if (!this.schematics) return;
      // this.schematics.chartData.datasets =
      //   this.schematics.chartData.datasets.map((item) => {
      //     item.tension = 0.1;
      //     return item;
      //   });
      this.show = true;
    },
  },
  watch: {
    schematics: function (newVal, oldVal) {
      this.populate();
    },
  },
  mounted() {
    this.populate();
  },
};
</script>

<style lang="scss" scoped>
</style>
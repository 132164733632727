const RongIMLib = require("@rongcloud/imlib-v4");
require("../../public/upload");
const UploadClient = require("../../public/init");
require("../../public/send-data");
export default class ChatController {

    ctx = null;
    imToken = null;
    im = null;
    adminChatUser = null;

    constructor(context) {
        this.ctx = context;
        this.im = context.$im;
        this.adminChatUser = JSON.parse(localStorage.getItem("chat"));
        this.imToken = this.adminChatUser.imToken;

    }

    connect() {
        return new Promise((resolve, reject) => {
            this.im.connect({ token: this.imToken })
                .then((user) => {
                    this.ctx.$Notifications.showNotification("success", "Connected to Chat Server");
                    resolve({ success: true })
                })
                .catch((error) => {
                    this.ctx.$Notifications.showNotification("danger", error.msg);
                    resolve({ success: false })
                });
        })
    }

    watch(callback) {
        this.im.watch({
            conversation(event) {
                callback.conversation(event);
            },
            message(event) {
                callback.message(event);
            },
            status(event) {
                callback.status(event);
            },
        });
    }


    sendMessage(targetId, type, content) {
        return new Promise((resolve, reject) => {
            this.im.Conversation.get({
                targetId: targetId,
                type: RongIMLib.CONVERSATION_TYPE.PRIVATE,
            })
                .send({
                    messageType: type,
                    content: content,
                })
                .then(function (message) {
                    resolve({ sendResponse: message, sendError: null });
                })
                .catch((error) => {
                    resolve({ sendResponse: null, sendError: error });
                });
        });
    }

    sendImageMessage(target, file, progressCallback) {
        return new Promise(async (resolve, reject) => {
            let base64 = await this.getCompressedBase64(file);
            let { uploadData, uploadError } = await this.uploadToQiniu(
                file,
                progressCallback
            );
            let d = await this.sendMessage(
                target.id,
                RongIMLib.MESSAGE_TYPE.IMAGE,
                {
                    content: base64,
                    imageUri: uploadData.downloadUrl,
                    user: this.getMessageUserObject(),
                    extra: this.getMessageExtra(target),
                }
            );
            resolve(d)
        })

    }

    getChatHistoryById(targetId) {
        return new Promise((resolve, reject) => {
            var conversation = this.im.Conversation.get({
                targetId: targetId,
                type: RongIMLib.CONVERSATION_TYPE.PRIVATE,
            });
            conversation
                .getMessages({
                    timestamp: 0,
                    count: 1000,
                })
                .then(function (result) {
                    resolve({ chatHistory: result.list, chatHistoryError: null });
                })
                .catch((error) => {
                    resolve({ chatHistory: null, chatHistoryError: error });
                });
        });
    }


    getMessageUserObject() {
        return {
            id: this.adminChatUser.id,
            name: "nine你呢",
            portrait: "https://oss.meetnine.cn/uploads/albumPictures/62602685.jpg",
        };
    }

    getMessageExtra(reciever) {
        let d = {
            isNine: true,
            receiveName: reciever.nickName,
            receiverHeadUrl: reciever.realPicture,
            receiverId: reciever.id,
            senderHeadUrl: this.adminChatUser.realPicture,
            senderId: this.adminChatUser.id,
            senderName: this.adminChatUser.name,
        };
        return JSON.stringify(d);
    }

    formatTime(time) {
        let weeksAgo = this.ctx.$moment
            .duration(this.$moment(time).diff(new Date()))
            .as("days");
        if (weeksAgo <= 1) {
            return this.$moment(time).fromNow().replace("a ", "");
        } else {
            return this.$moment(time).format("MM-DD hh:mm a");
        }
    }

    compress(img, size) {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let width = img.width;
        let height = img.height;
        canvas.width = 100;
        canvas.height = 100;
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, width, height);
        let ndata = canvas.toDataURL("image/jpeg", size);
        return ndata;
    }

    getCompressedBase64(file) {
        let ctx = this;
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            //Convert image to Base64 format
            reader.readAsDataURL(file);
            //Callback after successful reading
            reader.onloadend = function () {
                let result = this.result;
                let img = new Image();
                img.src = result;
                img.onload = function () {
                    let compressed = ctx.compress(img, 0.3);
                    var byteLength = parseInt(
                        compressed.replace(/=/g, "").length * 0.75
                    );
                    resolve(compressed);
                };
            };
        });
    }

    uploadToQiniu(file, progressCallback) {
        let im = this.im;
        var fileType = RongIMLib.FILE_TYPE.IMAGE;
        var config = {
            domain: "https://upload.qiniu.com",
            fileType: fileType,
            getToken: function (callback) {
                im.getFileToken(fileType)
                    .then(function (data) {
                        callback(data.token, data);
                    })
                    .catch(function (error) { });
            },
        };

        return new Promise((resolve, reject) => {
            var uploadCallbacks = {
                onProgress: function (loaded, total) {
                    var percent = Math.floor((loaded / total) * 100);
                    progressCallback.progress(percent)
                },
                onCompleted: function (data) {
                    // 上传完成, 调用 getFileUrl 获取文件下载 url
                    resolve({ uploadData: data, uploadError: null });
                },
                onError: function (error) {
                    resolve({ uploadData: null, uploadError: error });
                },
            };

            UploadClient.initFile(config, function (uploadFile) {
                uploadFile.upload(file, uploadCallbacks);
            });
        });
    }
}
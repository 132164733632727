<template>
  <div class="full-page flex-column page-padding">
    <filters
      :fields="filters"
      :processing="processing"
      @onFiltersToggle="onFiltersToggle"
      @onApplyFilters="onApplyFilters"
      @onClearFilters="onClearFilters"
    ></filters>

    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-row">
          <span class="page-title">Sign Up Questions</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <toggler
          :preIndex="order"
          :schema="sortingTogglerData"
          @onToggle="onToggleOrder"
        />

        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
        ></pager>

        <div class="download" @click="showAddPopup = true">
          <img src="../../../assets/icons/ic_add.png" />
          <span>Add New Question</span>
        </div>

        <div class="download" @click="downloadData">
          <img src="../../../assets/icons/ic_download.png" />
          <span>Download</span>
        </div>
      </div>
      <div
        class="list-wrapper"
        v-if="questions.length > 0"
        :class="{ 'loading-history-anim': processing }"
      >
        <table>
          <thead>
            <tr class="header">
              <th style="width: 1rem">ID</th>
              <th>Creation Date (CST)</th>
              <th>Question Image</th>
              <th>Title</th>
              <th>Option 1</th>
              <th>Option 2</th>
              <th>Option 3</th>
              <th>Option 4</th>
              <th>Correct Option</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in questions">
              <td style="width: 1rem">
                {{ item.id || "--" }}
              </td>
              <td>
                {{
                  item.createdAt
                    ? $momentTZ(item.createdAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                <img
                  :src="item.questionImageUrl"
                  :alt="item.title"
                  class="cover"
                />
              </td>
              <td>
                {{ item.title || "--" }}
              </td>
              <td>
                {{ item.optionOne || "--" }}
              </td>
              <td>
                {{ item.optionTwo || "--" }}
              </td>
              <td>
                {{ item.optionThree || "--" }}
              </td>
              <td>
                {{ item.optionFour || "--" }}
              </td>
              <td>
                {{ getParsedOption(item.correctOption) || "--" }}
              </td>

              <td style="width: 10rem">
                <div class="cell-actions">
                  <button class="red" @click="onClickDeleteEvent(item)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no questions to show here...</span>
      </div>
    </div>

    <transition>
      <addPopup
        v-if="showAddPopup"
        @onAbort="showAddPopup = false"
        @onSuccess="
          showAddPopup = false;
          getAllQuestions();
        "
      />
    </transition>
    <transition>
      <deleteMessagePopup
        v-if="showDeleteEventPopup"
        :model="deletableEvent"
        @onAbort="
          showDeleteEventPopup = false;
          deletableEvent = {};
        "
        @onSuccess="
          showDeleteEventPopup = false;
          deletableEvent = {};
          getAllQuestions();
        "
      ></deleteMessagePopup>
    </transition>
  </div>
</template>

<script>
import Filters from "../../../components/Filters.vue";
import Pager from "../../../components/Pager.vue";
import Toggler from "../../../components/Toggler.vue";
import DeleteNineMessage from "../../../components/popups/DeleteNineMessage.vue";
import AddSignUpQuestionPopup from "../../../components/popups/AddSignUpQuestionPopup.vue";

const ExcelExporter = require("../../../utils/excel");

export default {
  name: "NineMessages",
  components: {
    filters: Filters,
    pager: Pager,
    toggler: Toggler,
    deleteMessagePopup: DeleteNineMessage,
    addPopup: AddSignUpQuestionPopup,
  },
  data() {
    return {
      showAddPopup: false,
      showDeleteEventPopup: false,
      order: "ASC",
      showUpdatePopup: false,
      updateModel: {},
      processing: false,
      sortingTogglerData: {
        title: "SORT BY",
        desc: "Creation Date",
        options: [
          {
            key: "ASC",
            val: "Ascending",
          },
          {
            key: "DESC",
            val: "Descending",
          },
        ],
      },
      filters: [
        {
          id: "id",
          name: "Question ID",
          type: "text",
          placeholder: "ID of the question",
          val: "",
          required: false,
        },
        {
          id: "title",
          name: "Question Title",
          type: "text",
          placeholder: "Title of the question",
          val: "",
          required: false,
        },
        {
          id: "correctOption",
          name: "Correct Option",
          type: "select",
          placeholder: "Correct option for question",
          val: "",
          options: [
            {
              key: "optionOne",
              value: "Option 1",
            },
            {
              key: "optionTwo",
              value: "Option 2",
            },
            {
              key: "optionThree",
              value: "Option 3",
            },
            {
              key: "optionFour",
              value: "Option 4",
            },
          ],
          required: false,
        },
      ],
      questions: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      filtersData: {},
      options: {
        optionOne: "Option 1",
        optionTwo: "Option 2",
        optionThree: "Option 3",
        optionFour: "Option 4",
      },
    };
  },
  methods: {
    getParsedOption(option) {
      return this.options[option] || "--";
    },
    downloadData() {
      this.downloading = true;
      if (this.count === this.questions.length) {
        ExcelExporter.JSONToCSVConvertor(
          this.getParsedList(this.questions),
          "SignUp-Questions",
          true
        );
        this.downloading = false;
      } else {
        console.log("The count is not same");
        var order = `createdAt ${this.order}`;
        this.$HTTP
          .post(
            `${this.$URLS.SIGNUP_QUESTIONS.LIST}?order=${order}&page=1&results_per_page=${this.count}`,
            {}
          )
          .then((res) => {
            let questions = res.data.data.questions;
            ExcelExporter.JSONToCSVConvertor(
              this.getParsedList(questions),
              `Nine-Messages`,
              true
            );
            this.downloading = false;
          })
          .catch(() => {
            this.downloading = false;
          });
      }
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          ID: item.id || "--",
          "Created At": item.createdAt
            ? this.$momentTZ(item.createdAt)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          "Question URL": `${item.questionImageUrl}` || "--",
          Title: item.title || "--",
          "Option 1": item.optionOne || "--",
          "Option 2": item.optionTwo || "--",
          "Option 3": item.optionThree || "--",
          "Option 4": item.optionFour || "--",
          "Correct Option": this.options[item.correctOption] || "--",
        });
      });
      return parsed;
    },
    onClickDeleteEvent(item) {
      this.deletableEvent = item;
      this.showDeleteEventPopup = true;
    },
    onToggleOrder(e) {
      this.order = e;
      localStorage.setItem("messagesOrder", e);
      this.getAllQuestions();
    },
    onClearFilters() {
      localStorage.setItem("messagesFilters", null);
      this.filtersData = {};
      this.getAllQuestions();
    },
    onApplyFilters() {
      var data = {};
      this.filters.forEach((item) => {
        if (item.val) {
          if (item.id == "id") {
            data[item.id] = item.val;
          } else if (item.type == "text") {
            data[item.id] = {
              like: item.val,
              options: "i",
            };
          } else {
            data[item.id] = item.val;
          }
        }
      });
      this.filtersData = data;
      this.getAllQuestions();
      localStorage.setItem("messagesFilters", JSON.stringify(this.filters));
    },
    onClickUpdateStatus(item) {
      this.updateModel = item;
      this.showUpdatePopup = true;
    },
    onPageUpdated(page) {
      this.page = page;
      localStorage.setItem("messagesPage", parseInt(page));
      this.getAllQuestions();
    },
    getAllQuestions(data) {
      this.processing = true;
      var order = `createdAt ${this.order}`;
      this.$HTTP
        .post(
          `${this.$URLS.SIGNUP_QUESTIONS.LIST}?order=${order}&page=${this.page}&results_per_page=${this.pageSize}`,
          this.filtersData || {}
        )
        .then((res) => {
          this.questions = res.data.data.questions;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onFiltersToggle(opened) {
      setTimeout(() => {
        this.processListHeight();
      }, 100);
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 40);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    let page = localStorage.getItem("messagesPage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("messagesOrder") || "DESC";
    this.order = order;

    let filters = localStorage.getItem("messagesFilters");
    if (filters && filters != "null") {
      this.filters = JSON.parse(filters);
      this.onApplyFilters();
    } else {
      this.getAllQuestions();
    }
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
.cover {
  height: 8rem;
  margin-top: 0.4rem;
  border-radius: 0.4rem;
}
</style>

<template>
  <div class="wrapper">
    <div class="shapes">
      <svg viewBox="0 0 200 200" class="one" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#FF0066"
          d="M42.8,-53.9C58.1,-47.7,75,-38.8,80.2,-25.4C85.5,-12.1,79,5.8,69.9,19.3C60.8,32.8,49,42,36.9,52.7C24.8,63.3,12.4,75.4,-1.6,77.6C-15.6,79.8,-31.2,72.2,-45,62.1C-58.8,52,-70.8,39.4,-72.2,25.7C-73.6,11.9,-64.6,-3.1,-58.7,-18.4C-52.8,-33.8,-50.2,-49.6,-40.9,-57.8C-31.7,-65.9,-15.8,-66.3,-1.1,-64.8C13.7,-63.4,27.4,-60,42.8,-53.9Z"
          transform="translate(100 100)"
        />
      </svg>

      <svg viewBox="0 0 200 200" class="two" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#F1C21B"
          d="M28.2,-52.2C38.2,-43.1,49,-38.8,52.7,-30.9C56.5,-23,53.1,-11.5,53.1,0C53.2,11.5,56.6,23.1,54.3,33.5C52,44,44.1,53.3,34.1,64.1C24.1,74.9,12,87.2,2,83.8C-8.1,80.4,-16.2,61.3,-25.4,50C-34.6,38.7,-44.9,35.3,-52.3,28.3C-59.7,21.2,-64.2,10.6,-65.8,-0.9C-67.4,-12.5,-66.1,-24.9,-59.4,-33.2C-52.8,-41.5,-40.8,-45.6,-30,-54.2C-19.2,-62.8,-9.6,-75.9,-0.2,-75.5C9.1,-75.1,18.2,-61.2,28.2,-52.2Z"
          transform="translate(100 100)"
        />
      </svg>

      <svg
        viewBox="0 0 200 200"
        class="third"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#0F62FE"
          d="M52.5,-66.5C64.7,-52.4,68.8,-32.6,67.1,-15.7C65.5,1.2,58.1,15.2,51.5,32.7C45,50.1,39.2,71,26.7,77.1C14.3,83.2,-4.8,74.5,-20,64.7C-35.1,55,-46.3,44.1,-56.3,30.6C-66.4,17.1,-75.5,1.1,-76.2,-16.7C-77,-34.6,-69.5,-54.2,-55.4,-68C-41.3,-81.8,-20.7,-89.7,-0.2,-89.4C20.2,-89.1,40.4,-80.6,52.5,-66.5Z"
          transform="translate(100 100)"
        />
      </svg>

      <svg
        viewBox="0 0 200 200"
        class="fourth"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#A7F0BA"
          d="M47.1,-36.6C59.9,-34.2,68.5,-17.1,65.3,-3.2C62,10.6,47,21.3,34.1,35.2C21.3,49.1,10.6,66.4,-0.1,66.5C-10.9,66.6,-21.7,49.6,-32.1,35.6C-42.5,21.7,-52.5,10.9,-55.8,-3.4C-59.2,-17.6,-56,-35.2,-45.6,-37.6C-35.2,-40.1,-17.6,-27.3,-0.2,-27.1C17.1,-26.8,34.2,-39.1,47.1,-36.6Z"
          transform="translate(100 100)"
        />
      </svg>

      <svg
        viewBox="0 0 200 200"
        class="fifth"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#8A3FFC"
          d="M63.1,-35C74.3,-17.1,70.8,10.9,57.8,25.8C44.8,40.8,22.4,42.8,-2.5,44.3C-27.5,45.8,-55,46.7,-59.6,36.5C-64.3,26.3,-46.1,5.1,-32.4,-14.4C-18.7,-33.8,-9.3,-51.5,8.3,-56.3C26,-61.1,51.9,-53,63.1,-35Z"
          transform="translate(100 100)"
        />
      </svg>

      <svg
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
        class="sixth"
      >
        <path
          fill="#08BDBA"
          d="M54.9,-56.5C63.6,-46.2,57.9,-23.1,54,-3.9C50.2,15.4,48.1,30.7,39.4,37.4C30.7,44.2,15.4,42.2,4.4,37.8C-6.5,33.4,-13.1,26.5,-29.6,19.8C-46.1,13.1,-72.5,6.5,-75.9,-3.4C-79.3,-13.3,-59.6,-26.6,-43.1,-36.9C-26.6,-47.2,-13.3,-54.5,4.9,-59.4C23.1,-64.3,46.2,-66.8,54.9,-56.5Z"
          transform="translate(100 100)"
        />
      </svg>
    </div>
    <div id="login" class="page-full flex-column flex-centered">
      <div class="flex-column logo-wrapper">
        <img src="../assets/logo.png" alt="logo" id="logo" />
        <h2>Dashboard</h2>
      </div>
      <div class="card w-30">
        <h1>Login</h1>
        <h3>
          Welcome to Nine Dashboard. Please enter your email and password below
          to proceed
        </h3>

        <formView
          :inputs="inputs"
          actionName="Login"
          @onClick="onClickLogin"
          :processing="processing"
        ></formView>
      </div>
    </div>
  </div>
</template>

<script>
import FormView from "../components/FormView.vue";
export default {
  name: "Login",
  components: {
    formView: FormView,
  },
  data() {
    return {
      inputs: [
        {
          id: "email",
          type: "email",
          name: "Email",
          placeholder: "Please enter your email here",
          val: "",
          required: true,
        },
        {
          id: "password",
          type: "password",
          name: "Password",
          placeholder: "Please enter your password here",
          val: "",
          required: true,
        },
      ],
      processing: false,
    };
  },
  methods: {
    onClickLogin() {
      this.processing = true;
      let email = this.inputs[0].val;
      let password = this.inputs[1].val;

      this.$HTTP
        .post(this.$URLS.LOGIN, {
          email: email,
          password: password,
        })
        .then((res) => {
          let data = res.data.data;
          localStorage.setItem("id", data.id);
          localStorage.setItem("email", data.email);
          localStorage.setItem("accessToken", data.accessToken);
          localStorage.setItem("chat", JSON.stringify(data.chat));
          localStorage.setItem(
            "isSuperAdmin",
            data.email === "hi@meetnine.com"
          );

          this.$Notifications.showNotification("success", "Login successful !");
          this.$router.push("/");
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  background: rgb(247, 231, 217);
}
.shapes {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;

  .one {
    width: 23%;
    position: absolute;
    top: 16%;
    left: 13%;
  }

  .two {
    width: 62rem;
    position: absolute;
    right: -5%;
    bottom: -3%;
  }

  .third {
    width: 45rem;
    position: absolute;
    right: -11%;
    top: -10%;
  }

  .fourth {
    width: 36%;
    position: absolute;
    left: -10%;
    bottom: -30%;
  }

  .fifth {
    width: 50rem;
    position: absolute;
    bottom: -24%;
    left: 37%;
  }

  .sixth {
    width: 37rem;
    position: absolute;
    top: 1%;
    left: 50%;
    transform: translateX(-50%);
  }
}

#login {
  background: rgba($color: #000000, $alpha: 0.2);
  z-index: 20;
  position: relative;
  .logo-wrapper {
    position: absolute;
    top: 2rem;
    left: 2rem;

    h2 {
      margin-top: 1rem;
      color: var(--clr-skin);
    }
  }

  h1 {
    margin-bottom: 1rem;
    color: var(--clr-nine-green);
  }
  h3 {
    margin-bottom: 3rem;
  }

  .card {
    max-width: 35rem;
  }
}
</style>
<template>
  <div class="card">
    <div class="title-container flex-row" @click="onClickFilters">
      <!-- <span class="card-title">Filters</span> -->
      <div class="flex-row flex-h-centered">
        <img
          src="../assets/icons/ic_filter_list_alt.png"
          alt=""
          class="card-icon"
        />
        <span class="page-title">Filters</span>
        <div class="bubble" v-if="enabledClick"></div>
      </div>
      <img
        v-if="!filtersOpened"
        src="../assets/icons/ic_arrow_down.png"
        alt=""
        :class="{ 'filters-opened': filtersOpened }"
      />
    </div>

    <div class="flex-column" v-if="filtersOpened">
      <div
        class="filters"
        :class="{ twofr: fields.length == 2, threefr: fields.length == 3 }"
      >
        <div v-for="field in fields" v-bind:key="field.id">
          <inputView
            :id="field.id"
            :type="field.type"
            :placeholder="field.placeholder"
            :name="field.name"
            v-model="field.val"
            :options="field.options"
            class="input"
            :enableInput="!processing"
            @onUpdate="onUpdate"
            :needBubble="true"
          ></inputView>
        </div>
      </div>

      <div class="actions">
        <button
          class="bt-simple"
          @click="clearFilters"
          :class="{ 'bt-simple-disabled': !enabledClick }"
        >
          Clear Filters
        </button>
        <buttonView
          :enabled="enabledClick"
          :processing="processing"
          title="Apply Filters"
          @click="applyFilters"
        ></buttonView>
      </div>
    </div>
  </div>
</template>

<script>
import InputView from "./InputView.vue";
import ButtonView from "./ButtonView.vue";

export default {
  name: "Filters",
  components: {
    inputView: InputView,
    buttonView: ButtonView,
  },
  props: {
    fields: Array,
    posBtnTitle: String,
    processing: Boolean,
  },
  data() {
    return {
      filtersOpened: false,
      enabledClick: false,
    };
  },
  methods: {
    onClickFilters() {
      this.filtersOpened = !this.filtersOpened;
      this.$emit("onFiltersToggle", this.filtersOpened);

      let filters = this.$refs["filters"];
      if (!filters) {
        return;
      }
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    onUpdate() {
      this.enabledClick = this.fields.some((item) => {
        if (item.type === "email") {
          return (
            item.val && item.val.length > 0 && this.validateEmail(item.val)
          );
        } else {
          return item.val && item.val.length > 0;
        }
      });
    },
    applyFilters() {
      if (!this.enabledClick) return;
      var filters = {};
      this.fields.forEach((item) => {
        if (item.val && item.val != "" && item.val != null) {
          filters[item.id] = item.val;
        }
      });
      this.$emit("onApplyFilters", filters);
    },
    clearFilters() {
      if (!this.enabledClick) return;
      this.enabledClick = false;
      this.fields.forEach((item) => {
        item.val = "";
      });
      this.$emit("onClearFilters", this.fields);
    },
  },
  mounted() {
    setTimeout(() => {
      this.onUpdate();
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
input {
  font-size: 1.2rem !important;
  color: red;
}
.card {
  .title-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;

    img {
      width: 2rem;
      transform: rotate(180deg);
      transition: transform 0.25s linear;
    }

    .filters-opened {
      transform: rotate(0deg);
    }
  }

  .twofr {
    grid-template-columns: 1fr 1fr !important;
  }

  .threefr {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
  .filters {
    margin: 2rem 0rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2rem;
  }

  @media screen and (max-width: 1120px) {
    .filters {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media screen and (max-width: 956px) {
    .filters {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (max-width: 730px) {
    .filters {
      grid-template-columns: 1fr;
    }
  }
}
</style>
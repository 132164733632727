<template>
  <div
    class="full-page flex-column anaytics-page-padding"
    style="height: 100%; overflow: scroll"
  >
    <tc
      title="Invites Analytics"
      @onClickSwitch="(index) => onClickSwitch(index)"
      @onDateSelected="(date) => onDateSelected(date)"
      @onClickDownload="downloadData"
      :date="date"
      :allowedDates="dates"
    />
    <div class="analytics-container">
      <div class="table" v-if="switchIndex == 1">
        <pv :schematics="totalsPie" />
        <pv :schematics="sendersPie" />
        <pv :schematics="recieversPie" />
      </div>

      <div class="charts" v-else>
        <cv :schematics="statusTrends" />
        <cv :schematics="sendersTrends" />
        <cv :schematics="recieversTrends" />
      </div>
    </div>
  </div>
</template>

<script>
const ExcelExporter = require("../../../../utils/excel");
import PieView from "../../../../components/PieView.vue";
import ChartView from "../../../../components/ChartView.vue";
import AnalyticsTitleContainer from "../../../../components/AnalyticsTitleContainer.vue";
export default {
  name: "InvitesAnalytics",
  components: {
    pv: PieView,
    cv: ChartView,
    tc: AnalyticsTitleContainer,
  },
  data() {
    return {
      dates: [],
      analytics: null,
      typeIndex: 2,
      users: null,
      date: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      totalsTrend: null,
      switchIndex: 0,
      totalsPie: null,
      sendersPie: null,
      recieversPie: null,
      statusTrends: null,
      sendersTrends: null,
      recieversTrends: null,
    };
  },
  methods: {
    downloadData() {
      let arr = [];
      this.rawData.forEach((item) => {
        arr.push(Object.assign(JSON.parse(item.data), { date: item.date }));
      });
      console.table(arr);
      ExcelExporter.JSONToCSVConvertor(
        this.getParsedList(arr),
        "Invites Analytics",
        true
      );
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          "Date (CST)": item.date
            ? this.$momentTZ(item.date).tz("Asia/Shanghai").format("DD-MM-YYYY")
            : "--",
          Total: item.total,
          Pending: item.pending,
          Accepted: item.accepted,
          Rejected: item.rejected,
          "Male Senders": item.maleSenders,
          "Female Senders": item.femaleSenders,
          "Unknown Gender Senders": item.unknownSenders,
          "Male Recievers": item.maleRecievers,
          "Female Recievers": item.femaleRecievers,
          "Unknown Gender Recievers": item.unknownRecievers,
        });
      });
      return parsed;
    },
    onClickSwitch(index) {
      this.switchIndex = index;
    },
    onDateSelected(date) {
      let start = this.$moment(date).format("YYYY-MM-DD");
      let selected = this.rawData.filter((item) => {
        let itemDate = this.$moment(item.date).format("YYYY-MM-DD");
        if (itemDate == start) {
          return item;
        }
      });

      this.users = null;
      this.populateData(JSON.parse(selected[0].data));
    },
    getData() {
      this.users = null;
      this.$HTTP.get(this.$URLS.ANALYTICS.INVITES).then((data) => {
        let arr = data.data.users.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        this.rawData = arr;
        this.dates = this.rawData.map((x) => x.date);
        this.maxDate = this.dates[this.dates.length - 1];
        this.date = this.maxDate;
        this.onDateSelected(this.maxDate);
        this.populateTrends();
      });
    },

    populateTrends() {
      let dates = this.dates.map((x) => this.$moment(x).format("D/MM"));

      this.statusTrends = {
        title: "Invites Status Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Pending",
              data: this.rawData.map((x) => JSON.parse(x.data).pending),
              borderColor: "#FFC107",
            },
            {
              label: "Accepted",
              data: this.rawData.map((x) => JSON.parse(x.data).accepted),
              borderColor: "#1de9b6",
            },
            {
              label: "Rejected",
              data: this.rawData.map((x) => JSON.parse(x.data).rejected),
              borderColor: "#9E9E9E",
            },
          ],
        },
      };

      this.sendersTrends = {
        title: "Senders Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Male",
              data: this.rawData.map((x) => JSON.parse(x.data).maleSenders),
              borderColor: "#42A5F5",
            },
            {
              label: "Female",
              data: this.rawData.map((x) => JSON.parse(x.data).femaleSenders),
              borderColor: "#EC407A",
            },
            {
              label: "Others",
              data: this.rawData.map((x) => JSON.parse(x.data).unknownSenders),
              borderColor: "#616161",
            },
          ],
        },
      };

      this.recieversTrends = {
        title: "Recievers Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Male",
              data: this.rawData.map((x) => JSON.parse(x.data).maleRecievers),
              borderColor: "#42A5F5",
            },
            {
              label: "Female",
              data: this.rawData.map((x) => JSON.parse(x.data).femaleRecievers),
              borderColor: "#EC407A",
            },
            {
              label: "Others",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).unknownRecievers
              ),
              borderColor: "#616161",
            },
          ],
        },
      };
    },

    populateData(data) {
      this.users = data;

      this.totalsPie = {
        title: "Total",
        labels: ["Pending", "Accepted", "Rejected"],
        data: [this.users.pending, this.users.accepted, this.users.rejected],
        backgroundColor: ["#FFC107", "#41B883", "#FF1744"],
      };

      this.sendersPie = {
        title: "By Senders",
        labels: ["Male", "Female", "Others"],
        data: [
          this.users.maleSenders,
          this.users.femaleSenders,
          this.users.unknownSenders,
        ],
        backgroundColor: ["#42A5F5", "#EC407A", "#616161"],
      };

      this.recieversPie = {
        title: "By Recievers",
        labels: ["Male", "Female", "Others"],
        data: [
          this.users.maleRecievers,
          this.users.femaleRecievers,
          this.users.unknownRecievers,
        ],
        backgroundColor: ["#42A5F5", "#EC407A", "#616161"],
      };
    },
  },
  created() {
    this.getData(new Date());
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="full-page flex-column">
    <div class="card list" id="list" ref="list" style="margin-top: 0rem">
      <div class="title-container">
        <div class="flex-centered">
          <span class="page-title">Suspicous</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <toggler
          :preIndex="order"
          :schema="sortingTogglerData"
          @onToggle="onToggleOrder"
        />

        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
        ></pager>
        <div
          class="processing"
          style="margin-top: 0.5rem"
          v-if="downloading"
        ></div>
        <div class="download" @click="downloadData" v-else>
          <img src="../../../assets/icons/ic_download.png" />
          <span>Download</span>
        </div>
      </div>
      <div class="list-wrapper" v-if="users.length > 0">
        <table>
          <thead>
            <tr class="header">
              <th class="index">ID</th>
              <th>Creation Date (CST)</th>
              <th>Nickname</th>
              <th>Gender</th>
              <th>Location</th>
              <th>Registration Status</th>
              <th>Comulative Unfriendee Count</th>
              <th>Times Unfriended since<br />last time on list</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in users">
              <td class="index" style="width: 10%">
                {{ item.id || "--" }}
              </td>
              <td>
                {{
                  item.createdAt
                    ? $momentTZ(item.createdAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                {{ item.nickName || "--" }}
              </td>
              <td style="text-transform: capitalize">
                {{ item.gender || "--" }}
              </td>
              <td>
                {{ item.location || "--" }}
              </td>
              <td style="text-transform: capitalize">
                {{
                  (item.registrationStatus
                    ? item.registrationStatus.replace("_", " ")
                    : "--") || "--"
                }}
              </td>

              <td>
                {{ item.specialUnFriendCount_total || "--" }}
              </td>

              <td>
                {{ item.specialUnFriendCount_active || "--" }}
              </td>

              <td style="width: 30rem">
                <div class="cell-actions">
                  <button @click="onClickDetail(item)">Details</button>
                  <button class="red" @click="onClickUpdateStatus(item)">
                    Take Off List
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no users to show here...</span>
      </div>
    </div>

    <transition>
      <updatePoup
        v-if="showUpdatePopup"
        :model="updateModel"
        @onAbort="
          showUpdatePopup = false;
          updateModel = {};
        "
        @onSuccess="
          showUpdatePopup = false;
          updateModel = {};
          getAllUsers();
        "
      ></updatePoup>
    </transition>
  </div>
</template>

<script>
import Pager from "../../../components/Pager.vue";
import ApproveSuspiciousUser from "../../../components/popups/ApproveSuspiciousUser.vue";
const ExcelExporter = require("../../../utils/excel");
import Toggler from "../../../components/Toggler.vue";

export default {
  name: "UnFrienders",
  components: {
    pager: Pager,
    updatePoup: ApproveSuspiciousUser,
    toggler: Toggler,
  },
  data() {
    return {
      order: "ASC",
      showUpdatePopup: false,
      updateModel: {},
      processing: false,
      users: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      downloading: false,
      sortingTogglerData: {
        title: "SORT BY",
        desc: "Creation Date",
        options: [
          {
            key: "ASC",
            val: "Ascending",
          },
          {
            key: "DESC",
            val: "Descending",
          },
        ],
      },
    };
  },
  methods: {
    downloadData() {
      this.downloading = true;
      if (this.count === this.users.length) {
        ExcelExporter.JSONToCSVConvertor(
          this.getParsedList(this.users),
          "DeletedFriends",
          true
        );
        this.downloading = false;
      } else {
        console.log("The count is not same");
        var order = `updatedAt ${this.order}`;
        this.$HTTP
          .post(
            `${this.$URLS.USERS.SUSPICIOUS_UNFRIENDEES}?order=${order}&page=1&results_per_page=${this.count}`,
            {}
          )
          .then((res) => {
            let users = res.data.data.users;
            ExcelExporter.JSONToCSVConvertor(
              this.getParsedList(users),
              "DeletedFriends",
              true
            );
            this.downloading = false;
          })
          .catch(() => {
            this.downloading = false;
          });
      }
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          ID: item.id
            ? this.$momentTZ(item.updatedAt)
                .tz("Asia/Shanghai")
                .format("DD-MM-YYYY HH:mm a")
            : "--",
          Name: item.nickName || "--",
          Gender: item.gender || "--",
          Location: location || "--",
          "Total Unfriendee Count": item.specialUnFriendCount_total || "--",
          "UNFriendee count since last time on list":
            item.specialUnFriendCount_active || "--",
        });
      });
      return parsed;
    },
    onToggleOrder(e) {
      this.order = e;
      localStorage.setItem("suspiciousUnFrinedeesOrder", e);
      this.getAllUsers();
    },
    onClickDetail(item) {
      this.$router.push({
        name: "UserDetails",
        query: { id: item.id },
      });
    },
    onClickUpdateStatus(item) {
      this.updateModel = item;
      this.showUpdatePopup = true;
    },
    onPageUpdated(page) {
      this.page = page;
      localStorage.setItem("suspiciousUnFrinedeesPage", parseInt(page));
      this.getAllUsers();
    },
    getAllUsers(data) {
      this.processing = true;
      var order = `updatedAt ${this.order}`;
      this.$HTTP
        .post(
          `${this.$URLS.USERS.SUSPICIOUS_UNFRIENDEES}?order=${order}&page=${this.page}&results_per_page=${this.pageSize}`
        )
        .then((res) => {
          this.users = res.data.data.users;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
  },
  mounted() {
    let page = localStorage.getItem("suspiciousUnFrinedeesPage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("suspiciousUnFrinedeesOrder") || "ASC";
    this.order = order;

    let filters = localStorage.getItem("filters");
    if (filters && filters != "null") {
      this.filters = JSON.parse(filters);
      this.onApplyFilters();
    } else {
      this.getAllUsers();
    }
  },
};
</script>

<style lang="scss" scoped>
.download {
  height: 4rem;
  border: 0.1rem solid var(--clr-nine-green);
  border-radius: 0.2rem;
  display: flex;
  transition: all 0.3s ease-in-out;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  img {
    height: 2rem;
    width: 2rem;
    filter: invert(94%) sepia(95%) saturate(7147%) hue-rotate(84deg)
      brightness(100%) contrast(83%);
  }

  span {
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    color: transparent;
  }

  &:hover {
    background: var(--clr-nine-green);
    filter: none;
    padding: 0rem 1rem;

    span {
      width: auto;
      margin-left: 0.5rem;
      font-size: 1.2rem;
      color: white;
      font-family: "P-500";
      opacity: 1;
      visibility: visible;
    }

    img {
      filter: invert(100%) sepia(2%) saturate(0%) hue-rotate(134deg)
        brightness(104%) contrast(102%);
    }
  }
}
</style>
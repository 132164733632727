<template>
  <div class="full-page flex-column page-padding">
    <div class="card list" style="margin-top: 0rem; padding: 0rem">
      <div class="nav-bar">
        <div class="nav" v-for="nav in navs" v-bind:key="nav.name">
          <!-- {{ nav.name }} -->
          <router-link
            tag="li"
            active-class="active"
            :to="nav.val"
            exact
            @click="onClickNav(nav)"
          >
            <a>{{ nav.name }}</a>
          </router-link>
        </div>
      </div>

      <div class="inner-page-full" style="height: 83.5vh; overflow: hidden">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Toggler from "../../../components/Toggler.vue";

export default {
  name: "Analytics",
  components: {
    toggler: Toggler,
  },
  data() {
    return {
      selectedNav: null,
      navs: [
        {
          name: "Users Growth",
          val: "/analytics/users/growth",
        },
        {
          name: "Active Users",
          val: "/analytics/users/active",
        },
        {
          name: "Invites",
          val: "/analytics/invites",
        },
        {
          name: "Quitters",
          val: "/analytics/quitters",
        },
        {
          name: "Chats",
          val: "/analytics/chats",
        },
        {
          name: "Pebbles",
          val: "/analytics/pebbles",
        },
      ],
    };
  },
  methods: {
    onClickNav(item) {
      this.selectedNav = item;
    },
  },
  created() {
    console.log();
    if (this.$route.fullPath == "/analytics") {
      this.selectedNav = this.navs[0];
      this.$router.push("/analytics/users/growth");
    }
  },
};
</script>

<style lang="scss">
.nav-bar {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: row;
  background: var(--clr-nine-green-very-light);
  border-bottom: 0.1rem dashed var(--clr-nine-green);
  margin-bottom: 2rem;

  .nav {
    a:-webkit-any-link {
      font-size: 1.5rem;
      color: #616161;
      text-decoration: none;
      margin-right: 2rem;
      font-family: "P-400", sans-serif;
      transition: 0.15s all ease-in-out;
      padding: 1rem;
    }

    .active {
      cursor: pointer;
      color: black !important;
      font-family: "P-600", sans-serif !important;
      background: var(--clr-nine-green);
      border-radius: 1rem;
      text-transform: uppercase;
      position: relative;
      box-shadow: var(--shadow);
    }
  }

  .selected {
    border-bottom: 0.3rem solid var(--clr-nine-green);
  }
}

.desc {
  margin: 0.4rem auto 2rem auto;
  font-family: "P-400";
  color: gray;
  font-size: 1.2rem;
}

.anaytics-page-padding {
  padding: 0rem 2rem 0rem 2rem;
  width: 100% !important;
}

.analytics-container {
  width: 100%;
  display: flex;
  margin-top: 2rem;
  transition: 0.25s all ease-in-out;

  .table {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 4rem;

    .group {
      display: flex;
      flex-direction: column;
      background: var(--clr-nine-green-very-light);
      border-radius: 1rem;
      box-shadow: 0rem 0rem 0.5rem 0.1rem rgba(0, 0, 0, 0.1);
      overflow: hidden;
      border: 0.1rem solid var(--clr-nine-green-light);
      padding-bottom: 2rem;
      // border-top-right-radius: 14rem;
      // border-bottom-right-radius: 14rem;

      .title {
        font-size: 1.3rem;
        font-family: "P-800";
        color: black;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        height: 4.5rem;
        background: var(--clr-nine-green);
        border-bottom: 0.3rem solid var(--clr-nine-green);

        .v {
          margin-left: auto;
          font-size: 2rem;
          color: black;
          background: white;
          padding: 0rem 1rem;
          border-radius: 1rem;
        }
      }

      .content {
        display: grid;
        grid-template-columns: 1fr;

        .values {
          .kv {
            display: flex;
            align-items: center;
            height: 3.5rem;
            padding: 0rem 1rem;
            border-bottom: 0.1rem solid var(--clr-nine-green-light);
            padding-bottom: 0.4rem;
            margin-bottom: 0.4rem;

            .k {
              font-size: 1.3rem;
              color: gray;
            }

            .v {
              font-size: 1.8rem;
              color: black;
              margin-left: auto;
              font-family: "P-400";
            }

            .p {
              font-size: 1.3rem;
              margin: 0 0.4rem;
              font-family: "P-400";
              background: white;
              border: 0.1rem solid rgba(211, 211, 211, 0.75);
              border-radius: 0.4rem;
              padding: 0.2rem 0.5rem;
            }

            .legend {
              width: 1rem;
              height: 1rem;
              margin-left: 0.6rem;
              border-radius: 0.2rem;
            }

            .red {
              background: #ff5722;
            }

            .yellow {
              background: #ffeb3b;
            }

            .blue {
              background: #00d8ff;
            }

            .green {
              background: #41b883;
            }

            .r1 {
              background: #ef5350;
            }

            .r2 {
              background: #ab47bc;
            }

            .r3 {
              background: #dce775;
            }

            .r4 {
              background: #2196f3;
            }

            .r5 {
              background: #26c6da;
            }

            .r6 {
              background: #64ffda;
            }

            &:last-child {
              border: none;
            }
          }
        }

        .chart {
          width: 20rem;
          height: 20rem;

          // box-shadow: 0rem 0rem 1rem 0.1rem rgba(128, 128, 128, 0.459);
          border-radius: 50%;
          margin: auto;
        }
      }
    }
  }

  .charts {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    .item {
      width: 100%;
      background: var(--clr-nine-green-light);
      border-radius: 1rem;
      overflow: hidden;
      border: 0.1rem solid var(--clr-nine-green);

      .title {
        font-size: 1.3rem;
        font-family: "P-800";
        color: black;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        height: 4.5rem;
        background: var(--clr-nine-green);
        border-bottom: 0.3rem solid var(--clr-nine-green);
        margin-bottom: 1rem;
      }

      .chart {
        width: 100%;
        background: white;
        padding: 1rem;
      }
    }
  }
}

@media screen and (max-width: 1750px) {
  .analytics-container {
    .table {
      gap: 2rem;
      .group {
        border-radius: 0rem;
        border-radius: 2rem;
        .content {
          grid-template-columns: 1fr;
          .values {
            min-height: auto;
          }
          .chart {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .analytics-container {
    .table {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    .charts {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 750px) {
  .analytics-container {
    .table {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .charts {
      grid-template-columns: 1fr;
    }
  }
}
</style>

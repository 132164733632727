<template>
  <div class="full-page flex-column page-padding">
    <pebblefilters
      :fields="pebblefilters"
      :processing="processing"
      @onFiltersToggle="onFiltersToggle"
      @onApplyFilters="onApplyFilters"
      @onClearFilters="onClearFilters"
    ></pebblefilters>

    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-row">
          <span class="page-title">Pebble</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <toggler
          :preIndex="order"
          :preSortBy="sortBy"
          :schema="sortingTogglerData"
          @onToggleOrder="onToggleOrder"
          @onToggleSortBy="onToggleSortBy"
        />

        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
        ></pager>

        <div class="download" @click="downloadData">
          <img src="../../../assets/icons/ic_download.png" />
          <span>Download</span>
        </div>
      </div>
      <div
        class="list-wrapper"
        v-if="pebbles.length > 0"
        :class="{ 'loading-history-anim': processing }"
      >
        <table>
          <thead>
            <tr class="header">
              <th class="index">ID</th>
              <th>Creation Date</th>
              <th style="width: 20rem !important">User</th>
              <th>Pebble</th>
              <th># of Likes</th>
              <th>Caption/Content</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-bind:key="index"
              v-for="(item, index) in pebbles"
              class="body"
            >
              <td class="index" style="width: 5%">
                {{ item.id || "--" }}
              </td>
              <td>
                {{
                  item.timestamp
                    ? $momentTZ(item.timestamp)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td style="width: 20rem !important">
                <div class="host">
                  <div class="info">
                    <span class="title">{{
                      item.user
                        ? item.user.fullName || item.user.nickName
                        : "--"
                    }}</span>
                    <br />
                    <span class="ID">
                      {{ item.user ? item.user.id : "--" }}</span
                    >
                  </div>
                </div>
              </td>
              <td>
                <img
                  class="pebbleImg"
                  v-if="item.imageUrls && item.imageUrls[0]"
                  :src="item.imageUrls[0]"
                  alt=""
                />
              </td>
              <td>
                {{ item.likes ? item.likes.length : "--" }}
              </td>
              <td>
                {{ item.content ? item.content : "--" }}
              </td>

              <td style="width: 30rem">
                <div class="cell-actions">
                  <button class="red" @click="onClickDeletePebble(item)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no pebbles to show here...</span>
      </div>
    </div>

    <transition>
      <deletePebblePopup
        v-if="showDeleteEventPopup"
        :model="deletablePebble"
        @onAbort="
          showDeleteEventPopup = false;
          deletablePebble = {};
        "
        @onSuccess="
          showDeleteEventPopup = false;
          deletablePebble = {};
          getAllPebbles();
        "
      ></deletePebblePopup>
    </transition>
  </div>
</template>

<script>
import Filters from "../../../components/Filters.vue";
import Pager from "../../../components/Pager.vue";
import Toggler from "../../../components/MultiToggler.vue";
import DeletePebblePopup from "../../../components/popups/DeletePebblePopup.vue";
const ExcelExporter = require("../../../utils/excel");

export default {
  name: "Pebble",
  components: {
    pebblefilters: Filters,
    pager: Pager,
    toggler: Toggler,
    deletePebblePopup: DeletePebblePopup,
  },
  data() {
    return {
      showDeleteEventPopup: false,
      order: "ASC",
      sortBy: "createdAt",
      processing: false,
      sortingTogglerData: {
        title: "SORT BY",
        desc: "Creation Date",
        orderOptions: [
          {
            key: "ASC",
            val: "Ascending",
          },
          {
            key: "DESC",
            val: "Descending",
          },
        ],
        sortByOptions: [
          {
            key: "timestamp",
            value: "Creation Date",
          },
          {
            key: "updatedAt",
            value: "Last Updated",
          },
        ],
      },
      pebblefilters: [
        {
          id: "id",
          name: "Pebble ID",
          type: "text",
          placeholder: "ID of the pebble",
          val: "",
          required: false,
        },
        {
          id: "userId",
          name: "User ID",
          type: "text",
          placeholder: "ID of the pebble",
          val: "",
          required: false,
        },
        {
          id: "createdAt",
          name: "Creation Date",
          type: "date",
          placeholder: "Pebble creation date",
          val: "",
          required: false,
        },
      ],
      pebbles: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      filtersData: {},
    };
  },
  methods: {
    downloadData() {
      this.downloading = true;

      if (this.count === this.pebbles.length) {
        ExcelExporter.JSONToCSVConvertor(
          this.getParsedList(this.pebbles),
          "Events",
          true
        );
        this.downloading = false;
      } else {
        var order = `createdAt ${this.order}`;
        this.$HTTP
          .post(
            `${this.$URLS.PEBBLE.LIST}?order=${order}&page=1&results_per_page=${this.count}`,
            {}
          )
          .then((res) => {
            let pebbles = res.data.data.pebbles;
            ExcelExporter.JSONToCSVConvertor(
              this.getParsedList(pebbles),
              `Pebbles`,
              true
            );
            this.downloading = false;
          })
          .catch(() => {
            this.downloading = false;
          });
      }
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          ID: item.id || "--",
          "Created At": item.createdAt
            ? this.$momentTZ(item.createdAt)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          "TIme Stamp": item.timestamp
            ? this.$momentTZ(item.timestamp)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          "User ID": `${item.user ? item.user.id : "--"}` || "--",
          "User Name": item.user
            ? item.user.fullName || item.user.nickName
            : "--",
          "Pebble UEL": JSON.stringify(item.imageUrls) || "--",
          Content: item.content || "--",
          "$ of likes": item.likes ? item.likes.length : "--",
        });
      });
      return parsed;
    },
    onClickDeletePebble(item) {
      this.deletablePebble = item;
      this.showDeleteEventPopup = true;
    },
    onToggleSortBy(e) {
      this.sortBy = e;
      localStorage.setItem("pebbleSortBy", e);
      this.getAllPebbles();
    },
    onToggleOrder(e) {
      this.order = e;
      localStorage.setItem("pebbleOrder", e);
      this.getAllPebbles();
    },
    onClearFilters() {
      localStorage.setItem("pebblefilters", null);
      this.filtersData = {};
      this.getAllPebbles();
    },
    onApplyFilters() {
      var data = {};
      this.pebblefilters.forEach((item) => {
        if (item.val) {
          if (item.id == "id") {
            data[item.id] = item.val;
          } else if (item.type == "text") {
            data[item.id] = {
              like: item.val,
              options: "i",
            };
          } else {
            data[item.id] = item.val;
          }
        }
      });
      this.filtersData = data;
      this.getAllPebbles();
      localStorage.setItem("pebblefilters", JSON.stringify(this.pebblefilters));
    },
    onClickDetail(item) {
      this.$router.push({
        name: "EventsDetail",
        query: { id: item.id },
      });
    },
    onPageUpdated(page) {
      this.page = page;
      localStorage.setItem("pebblePage", parseInt(page));
      this.getAllPebbles();
    },
    getAllPebbles(data) {
      this.processing = true;
      var order = `${this.sortBy} ${this.order}`;
      this.$HTTP
        .post(
          `${this.$URLS.PEBBLE.LIST}?order=${order}&page=${this.page}&results_per_page=${this.pageSize}`,
          this.filtersData || {}
        )
        .then((res) => {
          this.pebbles = res.data.data.pebbles;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onFiltersToggle(opened) {
      setTimeout(() => {
        this.processListHeight();
      }, 100);
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 40);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    let page = localStorage.getItem("pebblePage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("pebbleOrder") || "DESC";
    this.order = order;

    let sortBy = localStorage.getItem("pebbleSortBy") || "createdAt";
    this.sortBy = sortBy;

    let pebblefilters = localStorage.getItem("pebblefilters");
    if (pebblefilters && pebblefilters != "null") {
      this.pebblefilters = JSON.parse(pebblefilters);
      this.onApplyFilters();
    } else {
      this.getAllPebbles();
    }
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
.pebbleImg {
  height: 10rem;
  margin: 1rem 0.4rem;
}
.body {
  td {
    overflow-wrap: break-word;

    &:nth-child(1) {
      width: 2rem !important;
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 7% !important;
    }

    &:nth-child(4) {
      width: 10rem;
    }

    &:nth-child(5) {
      max-width: 25rem;
    }

    &:nth-child(6) {
      max-width: 20rem;
    }

    &:nth-child(7) {
      width: 20rem;
    }

    &:last-child {
      max-width: 10rem;
    }
  }
}
.host {
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem;
  align-items: center;

  img {
    width: 6rem;
    height: 6rem;
    border-radius: 0.6rem;
    overflow: hidden;
    margin-right: 1rem;
  }

  .info {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 1.3rem;
      font-family: "P-600";
      color: black;
    }

    .gender,
    .location {
      font-size: 1.2rem;
      font-family: "P-300";
    }
  }
}
</style>

const Notifications = require("../controllers/Notifications.js");

import axios from "axios";
const baseURL = process.env.VUE_APP_SERVER_URL;
// const baseURL = "http://localhost:8080/api/dashboard/";
// const baseURL = "http://api-dev.meetnine.cn/api/dashboard/";
// const baseURL = "https://api.meetnine.cn/api/dashboard/";





axios.defaults.baseURL = baseURL;

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API,
});

HTTP.interceptors.request.use(
  function (config) {
    // Notifications.showLoading();
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["x-access-token"] = token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    Notifications.hideLoading();
    return response;
  },
  (error) => {
    Notifications.hideLoading();
    if (error.response && error.response.data) {
      Notifications.showNotification(
        "danger",
        error.response.data.error.message
      );
    } else {
      Notifications.showNotification("danger", error.message);
    }
    throw error;
  }
);

const URLS = {
  baseURL: baseURL,
  LOGIN: "/dashboard/login",
  ADMIN: {
    ADD: "/dashboard/admin/create",
    LIST: "/dashboard/admin/list",
    BY_ID: "/dashboard/admin?id=%id",
    CHANGE_PASSWORD: "/dashboard/admin/update/my/password"
  },
  USERS: {
    LIST: "/dashboard/users/list",
    UPDATE: "/dashboard/users",
    GET_BY_ID: "/dashboard/users?id=%id",
    QUITTERS: "/dashboard/deleted/accounts/list",
    UNFRIENDERS: "/dashboard/deleted/friends/list",
    SUSPICIOUS_UNFRIENDEES: "/dashboard/suspicious/unfriendees/list"
  },
  WORDLIST: {
    ADD: "/dashboard/prohibited/word",
    LIST: "/dashboard/prohibited/wordlist",
    BY_ID: "/dashboard/prohibited/word?id=%id",
  },
  ORDERS: {
    LIST: "/dashboard/orders/list",
    REFUND: "orders/refund",
  },
  GOODS: {
    LIST: "/dashboard/goods/list",
  },
  EVENTS: {
    LIST: "/dashboard/events/list",
    GET_BY_ID: "/dashboard/event?id=%id",
    ADD: "/dashboard/event",
    DETAIL: "/dashboard/event/detail?id=%id",
    SIGN_UP_BY_ID: "/dashboard/event/signup?id=%id"
  },
  ARTICLES: {
    LIST: "/dashboard/articles/list",
    GET_BY_ID: "/dashboard/article?id=%id",
    ADD: "/dashboard/article",
    DETAIL: "/dashboard/article/detail?id=%id",
  },
  NINE_MESSAGES: {
    LIST: "/dashboard/nine/messages/list",
    GET_BY_ID: "/dashboard/nine/message?id=%id",
    ADD: "/dashboard/nine/message",
    DETAIL: "/dashboard/nine/message/detail?id=%id",
  },
  ANALYTICS: {
    USERS_GROWTH: "/dashboard/analytics/users/growth",
    ACTIVE_USERS: "/dashboard/analytics/active/users",
    INVITES: "/dashboard/analytics/invites",
    QUITTERS: "/dashboard/analytics/quitters",
    CHATS: "/dashboard/analytics/chats",
    PEBBLES: "/dashboard/analytics/pebbles",
  },
  POPOVERS: {
    LIST: "/dashboard/popover/list",
    GET_BY_ID: "/dashboard/popover?id=%id",
    ADD: "/dashboard/popover",
    DETAIL: "/dashboard/popover/detail?id=%id",
  },
  PEBBLE: {
    LIST: "/dashboard/pebbles/list",
    BY_ID: "/dashboard/pebble?id=%id",
  },
  SIGNUP_QUESTIONS: {
    LIST: "/dashboard/signup-questions/list",
    ROOT: "/dashboard/signup-questions",
    BY_ID: "/dashboard/signup-questions?id=%id",
  },
  SETTINGS: {
    LIST: "/dashboard/settings/list",
    ROOT: "/dashboard/settings",
    BY_ID: "/dashboard/settings?id=%id",
    CREATE: "/dashboard/settings/create"
  },
  UPLOAD: "container/events/upload",
};

export { HTTP, URLS };

<template>
  <div class="page-full flex-row">
    <div class="nav" :class="{ opened: showNav, closed: !showNav }">
      <img src="../assets/logo.png" alt="logo" id="logo" v-if="showNav" />
      <h2 v-if="showNav">Dashboard</h2>

      <div v-if="showNav" class="navs flex-column">
        <div
          v-for="route in routes"
          v-bind:key="route.name"
          class="nav-wrapper"
        >
          <router-link tag="li" active-class="active" :to="route.to" exact>
            <img :src="route.icon" alt="" />
            <a>{{ route.name }}</a>
          </router-link>
        </div>
      </div>

      <div class="close" @click="onCloseNav">
        <img src="../assets/icons/ic_arrow_down.png" alt="" />
      </div>

      <div class="chat-switch" v-if="showNav">
        <span class="title"> Chat Server </span>
        <div class="switch" v-if="!processingChatConnection">
          <div :class="{ on: chatOn }" @click="toggleChat">ON</div>
          <div :class="{ on: !chatOn }" @click="toggleChat">OFF</div>
        </div>
        <div class="processing" v-else></div>
      </div>

      <div class="admin" v-if="showNav">
        <label class="title" for="email">Logged in as :</label>
        <span class="email" id="email">{{ email }}</span>
        <div class="actions">
          <div
            class="bt edit"
            title="Change Password"
            @click="showUpdatePasswordModal = true"
          >
            <img src="../assets/icons/ic_edit.png" alt="Update Password" />
          </div>
          <div class="bt logout" @click="logout" title="Logout">
            <img src="../assets/icons/ic_logout.png" alt="Logout" />
          </div>
        </div>
      </div>

      <div
        v-if="!this.isProduction"
        :class="{ 'ribbon-closed': !showNav }"
        class="ribbon"
      ></div>
    </div>

    <div class="inner-page-full">
      <transition>
        <router-view></router-view>
      </transition>
    </div>

    <transition>
      <updatePasswordModal
        v-if="showUpdatePasswordModal"
        @onAbort="showUpdatePasswordModal = false"
        @onSuccess="showUpdatePasswordModal = false"
      />
    </transition>
  </div>
</template>

<script>
import UpdateAdminPasswordPopup from "../components/popups/UpdateAdminPasswordPopup";
export default {
  name: "Home",
  components: {
    updatePasswordModal: UpdateAdminPasswordPopup,
  },
  data() {
    return {
      showUpdatePasswordModal: false,
      isProduction: false,
      processingChatConnection: false,
      chatOn: false,
      showNav: true,
      routes: [
        {
          name: "Analytics",
          to: "/analytics",
          icon: require("../assets/icons/ic_trend.png"),
        },
        {
          name: "Admins",
          to: "/admins",
          icon: require("../assets/icons/ic_admins.png"),
        },
        {
          name: "All Users",
          to: "/users",
          icon: require("../assets/icons/svg/users.svg"),
        },
        {
          name: "Verified",
          to: "/verified_users",
          icon: require("../assets/icons/svg/verified.svg"),
        },
        {
          name: "UnPushed",
          to: "/unpushed_users",
          icon: require("../assets/icons/svg/unpushed.svg"),
        },
        {
          name: "Quitters",
          to: "/quitters",
          icon: require("../assets/icons/svg/quitters.svg"),
        },
        {
          name: "UnFrienders",
          to: "/unfrienders",
          icon: require("../assets/icons/svg/unfrienders.svg"),
        },
        {
          name: "Chat",
          to: "/messages",
          icon: require("../assets/icons/svg/messages.svg"),
        },
        {
          name: "WordList",
          to: "/wordlist",
          icon: require("../assets/icons/svg/wordlist.svg"),
        },
        {
          name: "Orders",
          to: "/orders",
          icon: require("../assets/icons/svg/orders.svg"),
        },
        {
          name: "Events",
          to: "/events",
          icon: require("../assets/icons/ic_event.png"),
        },
        {
          name: "Articles",
          to: "/articles",
          icon: require("../assets/icons/ic_article.png"),
        },
        {
          name: "Messages",
          to: "/nine/messages",
          icon: require("../assets/icons/ic_admin_messages.png"),
        },
        {
          name: "Popovers",
          to: "/popovers",
          icon: require("../assets/icons/ic_popover.png"),
        },
        {
          name: "Pebbles",
          to: "/pebbles",
          icon: require("../assets/icons/ic_popover.png"),
        },
        {
          name: "SignUpQuestions",
          to: "/signup-questions",
          icon: require("../assets/icons/ic_popover.png"),
        },
        {
          name: "Crayons",
          to: "/crayons",
          icon: require("../assets/icons/ic_popover.png"),
        },
        {
          name: "Settings",
          to: "/settings",
          icon: require("../assets/icons/ic_popover.png"),
        },
      ],
      email: "",
    };
  },
  methods: {
    toggleChat() {
      this.processingChatConnection = true;
      if (!this.chatOn) {
        console.log("chat is off , turning on...");
        this.connectChat();
      } else {
        console.log("chat is on , turning off...");
        this.disconnectChat();
      }
    },
    disconnectChat() {
      this.$im.disconnect().then(() => {
        console.log("chat turned off!");
        this.chatOn = false;
        localStorage.setItem("chatOn", this.chatOn);
        document.dispatchEvent(new CustomEvent("nine:onChatOff", {}));
        this.processingChatConnection = false;
      });
    },
    connectChat() {
      let adminChatUser = JSON.parse(localStorage.getItem("chat"));
      let imToken = adminChatUser.imToken;
      this.$im
        .connect({ token: imToken })
        .then((user) => {
          this.$Notifications.showNotification("success", "加入聊天室成功");
          this.chatOn = true;
          localStorage.setItem("chatOn", this.chatOn);
          this.processingChatConnection = false;
          document.dispatchEvent(new CustomEvent("nine:onChatOn", {}));
        })
        .catch((error) => {
          this.$Notifications.showNotification("danger", error.msg);
          console.log(error);
          console.log("链接失败: ", error.code, error.msg);
          this.processingChatConnection = false;
        });
    },
    logout() {
      localStorage.setItem("accessToken", "");
      localStorage.setItem("id", "");
      localStorage.setItem("email", "");
      this.$router.push("/login");
    },
    onCloseNav() {
      this.showNav = !this.showNav;
      console.log("onCloseNav");
    },
  },
  mounted() {
    this.email = localStorage.getItem("email");
    let token = localStorage.getItem("accessToken");
    if (
      this.$route.fullPath === "/" &&
      (token === "" || token === null || token === undefined)
    ) {
      this.$router.push("/login");
    } else if (this.$route.fullPath === "/") {
      this.$router.push("/analytics");
    }

    this.chatOn = (localStorage.getItem("chatOn") || false) == "true";
    this.isProduction = process.env.VUE_APP_ENV == "production";
    let isSuperAdmin = localStorage.getItem("isSuperAdmin") || "false";
    if (isSuperAdmin == "false") {
      console.log("hello");
      this.routes = this.routes.filter((e) => {
        return e.name != "Admins";
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.opened {
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 2.6rem;
  width: 25rem;
  padding: 2rem;
}

.closed {
  width: 2rem;

  .close {
    right: -70% !important;

    img {
      transform: rotate(270deg) !important;
    }
  }
}
.nav {
  transition: 0.15s all ease-in;
  background: var(--nav-bg);

  box-shadow: var(--shadow);
  border-radius: var(--card-border);

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .ribbon-closed {
    top: 15rem !important;
    left: -4.5rem !important;

    &::before {
      transform: none !important;
      transform: rotate(-90deg) !important;
      box-shadow: none !important;
    }
  }

  .ribbon {
    width: 12rem;
    height: 12rem;
    position: absolute;
    top: -0.8rem;
    left: -0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 0.2rem;
    transition: 0.25s all ease-in-out;
    &::before {
      content: "Dev";
      position: absolute;
      width: 150%;
      height: 4rem;
      background: linear-gradient(#c2185b, #f44336);
      transform: rotate(-45deg) translateY(-2.1rem);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "P-800";
      color: white;
      text-transform: uppercase;
      letter-spacing: 1rem;
      font-size: 2.2em;
      box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.3);
      padding-left: 1rem;

      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-color: white;
      -webkit-text-stroke-width: 1.5px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: #c2185b;
      z-index: -1;
      box-shadow: 11rem -11rem #c2185b;
    }
  }

  .close {
    width: 3rem;
    height: 8rem;
    background: var(--clr-nine-green);
    position: absolute;
    right: -3%;
    top: 3.5%;
    box-shadow: var(--shadow);
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.15s all ease-in;

    img {
      height: 2rem;
      filter: brightness(0%);
      transform: rotate(90deg);
      transition: 0.15s all ease-in;
    }

    &:hover {
      transform: scale(1.1);
      img {
        transform: scale(1.1) rotate(90deg);
      }
    }
  }

  .chat-switch {
    width: 100%;
    margin-top: auto;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 0.2rem solid white;
    padding-top: 2rem;

    .title {
      font-size: 1.2rem;
      font-family: "P-600";
      color: black;
    }

    .processing {
      margin-left: auto;
    }

    .switch {
      width: 6rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      border: 0.2rem solid var(--clr-nine-green);
      border-radius: 01rem;
      margin-left: auto;
      overflow: hidden;

      div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "P-500";
        transition: 0.2s all ease-in-out;
        cursor: pointer;
      }

      .on {
        color: white;
        background: var(--clr-nine-green);
        font-family: "P-700";
      }
    }
  }

  .admin {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 0.4rem solid var(--clr-nine-green);
    box-shadow: var(--shadow);
    padding: 1rem;

    .title {
      font-size: 1.2rem;
      font-family: "P-600";
    }

    .email {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    .actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      .bt {
        width: 100%;
        height: 4rem;
        border: 0.3rem solid black;
        border-radius: var(--btn-radius);
        display: flex;
        transition: 0.25s all ease-in-out;
        cursor: pointer;

        img {
          transition: 0.25s all ease-in-out;
          width: 1.5rem;
          margin: auto;
        }
      }

      .edit {
        border-color: var(--yellow);
        img {
          filter: invert(0%) sepia(87%) saturate(7446%) hue-rotate(135deg)
            brightness(86%) contrast(111%);
        }

        &:hover {
          background: var(--yellow);
          img {
            transform: scale(1.2);
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(248deg)
              brightness(106%) contrast(106%);
          }
        }
      }

      .logout {
        border-color: var(--red);
        img {
          filter: invert(25%) sepia(64%) saturate(7439%) hue-rotate(339deg)
            brightness(101%) contrast(101%);
        }

        &:hover {
          background: var(--red);
          img {
            transform: scale(1.2);
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(248deg)
              brightness(106%) contrast(106%);
          }
        }
      }
    }
  }

  #logo {
    margin-bottom: 1rem;
  }

  .navs {
    width: 100%;
    margin-top: 2rem;
    border-top: 0.2rem solid white;
    padding-top: 2rem;
    overflow-y: scroll;
    .nav-wrapper {
      margin-bottom: 1rem;
    }

    a:-webkit-any-link {
      font-size: 1.5rem;
      color: #616161;
      text-decoration: none;
      padding: 0.2rem 1rem;
      font-family: "P-400", sans-serif;
      transition: 0.15s all ease-in-out;
      display: flex;
      align-items: center;

      img {
        margin-right: 1rem;
        filter: invert(39%) sepia(11%) saturate(21%) hue-rotate(350deg)
          brightness(91%) contrast(87%);
        height: 2rem;
      }
    }

    .active {
      cursor: pointer;
      color: black !important;
      font-family: "P-600", sans-serif !important;
      border-radius: 1rem;

      img {
        animation: filters 0.2s forwards;
        transform: scale(1.2);
      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 1rem solid transparent;
        border-bottom: 1rem solid transparent;
        border-left: 1rem solid var(--red);
        clear: both;
        margin-left: auto;
      }
    }
  }
}

@keyframes filters {
  0% {
    filter: invert(39%) sepia(11%) saturate(21%) hue-rotate(350deg)
      brightness(91%) contrast(87%);
  }
  100% {
    filter: invert(21%) sepia(100%) saturate(4288%) hue-rotate(339deg)
      brightness(102%) contrast(102%);
  }
}
</style>

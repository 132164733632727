<template>
  <div class="full-page flex-column page-padding">
    <NavSwitches
      style="margin-bottom: 2rem"
      :items="['All', 'Suspicious']"
      @onClickItem="onSelectNavItem"
      :preSelectIndex="switchIndex"
    />

    <div class="inner-page-full" style="height: 83.5vh; overflow: hidden">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavSwitches from "../../../components/NavSwitches.vue";
import UnFrienders from "./UnFrienders.vue";
import SuspiciousUnFrienders from "./SuspiciousUnFrienders.vue";

export default {
  name: "UnfriendersMain",
  components: {
    NavSwitches,
    UnFrienders,
    SuspiciousUnFrienders,
  },
  data() {
    return {
      switchIndex: 0,
    };
  },
  methods: {
    onSelectNavItem(item) {
      console.log(
        "🚀 ~ file: UnfriendersMain.vue ~ line 37 ~ onSelectNavItem ~ item",
        parseInt(item)
      );
      if (item == 0) {
        this.$router.push("/unfrienders/all");
      } else {
        this.$router.push("/unfrienders/suspicious");
      }
    },
  },
  mounted() {
    if (this.$route.name == "UnFriendersMain") {
      this.switchIndex = 0;
      this.$router.push({
        name: "UnFrienders",
      });
    } else if (this.$route.name == "SuspiciousUnFrienders") {
      this.switchIndex = 1;
    }
  },
};
</script>

<style lang="scss" scoped>
.switches {
  width: 20rem;
}
</style>
<template>
  <div class="full-page flex-column page-padding">
    <filters
      :fields="filters"
      :processing="processing"
      @onFiltersToggle="onFiltersToggle"
      @onApplyFilters="onApplyFilters"
      @onClearFilters="onClearFilters"
    ></filters>

    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-row">
          <span class="page-title">Articles</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <toggler
          :preIndex="order"
          :schema="sortingTogglerData"
          @onToggle="onToggleOrder"
        />

        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
        ></pager>

        <div class="download" @click="onClickCreateArticle">
          <img src="../../../assets/icons/ic_add.png" />
          <span>Creat New Article</span>
        </div>

        <div class="download" @click="downloadData">
          <img src="../../../assets/icons/ic_download.png" />
          <span>Download</span>
        </div>
      </div>
      <div
        class="list-wrapper"
        v-if="articles.length > 0"
        :class="{ 'loading-history-anim': processing }"
      >
        <table>
          <thead>
            <tr class="header">
              <th class="index">ID</th>
              <th>Creation Date (CST)</th>
              <th>Status</th>
              <th>Title</th>
              <th>Sub Title</th>
              <th>Cover Image</th>
              <th>Author</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in articles">
              <td class="index" style="width: 10%">
                {{ item.id || "--" }}
              </td>
              <td>
                {{
                  item.createdAt
                    ? $momentTZ(item.createdAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td style="text-transform: capitalize">
                {{ item.status || "--" }}
              </td>
              <td>
                {{ item.title || "--" }}
              </td>
              <td>
                {{ item.guidance || "--" }}
              </td>
              <td>
                <img
                  :key="item.id"
                  :src="item.coverImage"
                  :alt="item.title"
                  class="cover"
                />
              </td>
              <td>
                {{ item.author || "--" }}
              </td>

              <td style="width: 30rem">
                <div class="cell-actions">
                  <button @click="onClickDetail(item)">Details</button>
                  <button class="red" @click="onClickDeleteArticle(item)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no articles to show here...</span>
      </div>
    </div>

    <transition>
      <updatePoup
        v-if="showUpdatePopup"
        :model="updateModel"
        @onAbort="
          showUpdatePopup = false;
          updateModel = {};
        "
        @onSuccess="
          showUpdatePopup = false;
          updateModel = {};
          getAllArticles();
        "
      ></updatePoup>
    </transition>

    <transition>
      <deleteArticlePopup
        v-if="showDeleteArticlePopup"
        :model="deletableArticle"
        @onAbort="
          showDeleteArticlePopup = false;
          deletableArticle = {};
        "
        @onSuccess="
          showDeleteArticlePopup = false;
          deletableArticle = {};
          getAllArticles();
        "
      ></deleteArticlePopup>
    </transition>
  </div>
</template>

<script>
import Filters from "../../../components/Filters.vue";
import Pager from "../../../components/Pager.vue";
import UpdateUserStatusPopup from "../../../components/popups/UpdateUserStatusPopup.vue";
import Toggler from "../../../components/Toggler.vue";
import DeleteArticlePopup from "../../../components/popups/DeleteArticlePopup.vue";

import {
  REGISTRATION_STATUS,
  UPDATED_VERIFIED,
  GENDER,
} from "../../../assets/Constants";
const ExcelExporter = require("../../../utils/excel");

export default {
  name: "Articles",
  components: {
    filters: Filters,
    pager: Pager,
    updatePoup: UpdateUserStatusPopup,
    toggler: Toggler,
    deleteArticlePopup: DeleteArticlePopup,
  },
  data() {
    return {
      showDeleteArticlePopup: false,
      order: "ASC",
      showUpdatePopup: false,
      updateModel: {},
      processing: false,
      sortingTogglerData: {
        title: "SORT BY",
        desc: "Creation Date",
        options: [
          {
            key: "ASC",
            val: "Ascending",
          },
          {
            key: "DESC",
            val: "Descending",
          },
        ],
      },
      filters: [
        {
          id: "id",
          name: "Article ID",
          type: "text",
          placeholder: "ID of the user",
          val: "",
          required: false,
        },
        {
          id: "title",
          name: "Article Title",
          type: "text",
          placeholder: "Title of the article",
          val: "",
          required: false,
        },
        {
          id: "status",
          name: "Article Status",
          type: "select",
          placeholder: "UnPublished or Published",
          val: "",
          options: [
            {
              key: "unpublished",
              value: "UnPublished",
            },
            {
              key: "published",
              value: "Published",
            },
          ],
          required: false,
        },
      ],
      articles: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      filtersData: {},
    };
  },
  methods: {
    downloadData() {
      this.downloading = true;
      if (this.count === this.articles.length) {
        ExcelExporter.JSONToCSVConvertor(
          this.getParsedList(this.articles),
          "Articles",
          true
        );
        this.downloading = false;
      } else {
        console.log("The count is not same");
        var order = `createdAt ${this.articles}`;
        this.$HTTP
          .post(
            `${this.$URLS.ARTICLES.LIST}?order=${order}&page=1&results_per_page=${this.count}`,
            {}
          )
          .then((res) => {
            let articles = res.data.data.articles;
            ExcelExporter.JSONToCSVConvertor(
              this.getParsedList(articles),
              `Articles`,
              true
            );
            this.downloading = false;
          })
          .catch(() => {
            this.downloading = false;
          });
      }
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          ID: item.id || "--",
          "Created At": item.createdAt
            ? this.$momentTZ(item.createdAt)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          Status: `${item.status}` || "--",
          Title: item.title || "--",
          Content: item.content || "--",
          Author: item.author || "--",
          "Cover Image": item.coverImage || "--",
        });
      });
      return parsed;
    },

    onClickCreateArticle() {
      this.$router.push("/articles/create");
    },
    getUserLimitValue(type) {
      switch (type) {
        case 0:
          return "All";
        case 1:
          return "Verified";
        case 2:
          return "VIP";
        default:
          return "UnKnown";
      }
    },
    onClickDeleteArticle(item) {
      this.deletableArticle = item;
      this.showDeleteArticlePopup = true;
    },
    onToggleOrder(e) {
      this.order = e;
      localStorage.setItem("articlesOrder", e);
      this.getAllArticles();
    },
    onClearFilters() {
      localStorage.setItem("filters", null);
      this.filtersData = {};
      this.getAllArticles();
    },
    onApplyFilters() {
      var data = {};
      this.filters.forEach((item) => {
        if (item.val) {
          if (item.id == "id") {
            data[item.id] = item.val;
          } else if (item.type == "text") {
            data[item.id] = {
              like: item.val,
              options: "i",
            };
          } else {
            data[item.id] = item.val;
          }
        }
      });
      this.filtersData = data;
      this.getAllArticles();
      localStorage.setItem("filters", JSON.stringify(this.filters));
    },
    onClickDetail(item) {
      this.$router.push({
        name: "ArticleDetail",
        query: { id: item.id },
      });
    },
    onClickUpdateStatus(item) {
      this.updateModel = item;
      this.showUpdatePopup = true;
    },
    onPageUpdated(page) {
      this.page = page;
      localStorage.setItem("articlesPage", parseInt(page));
      this.getAllArticles();
    },
    getAllArticles(data) {
      this.processing = true;
      var order = `createdAt ${this.order}`;
      this.$HTTP
        .post(
          `${this.$URLS.ARTICLES.LIST}?order=${order}&page=${this.page}&results_per_page=${this.pageSize}`,
          this.filtersData || {}
        )
        .then((res) => {
          this.articles = res.data.data.articles;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onFiltersToggle(opened) {
      setTimeout(() => {
        this.processListHeight();
      }, 100);
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 40);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    let page = localStorage.getItem("articlesPage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("articlesOrder") || "DESC";
    this.order = order;

    let filters = localStorage.getItem("filters");
    if (filters && filters != "null") {
      this.filters = JSON.parse(filters);
      this.onApplyFilters();
    } else {
      this.getAllArticles();
    }
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
.cover {
  height: 8rem;
  margin-top: 0.4rem;
  border-radius: 0.4rem;
}
</style>

<template>
  <div
    class="full-page flex-column anaytics-page-padding"
    style="height: 100%; overflow: scroll"
  >
    <tc
      title="Quitters Analytics"
      @onClickSwitch="(index) => onClickSwitch(index)"
      @onDateSelected="(date) => onDateSelected(date)"
      @onClickDownload="downloadData"
      :date="date"
      :allowedDates="dates"
    />

    <div class="analytics-container">
      <div class="table" v-if="switchIndex == 1">
        <pv :schematics="totalsPie" />
        <pv :schematics="gendersPie" />
        <pv :schematics="locationsPie" />
      </div>

      <div class="charts" v-else>
        <cv :schematics="totalTrends" />
        <cv :schematics="reasonsTrend" />
        <cv :schematics="gendersTrend" />
        <cv :schematics="locationsTrend" />
      </div>
    </div>
  </div>
</template>

<script>
const ExcelExporter = require("../../../../utils/excel");
import PieView from "../../../../components/PieView.vue";
import ChartView from "../../../../components/ChartView.vue";
import AnalyticsTitleContainer from "../../../../components/AnalyticsTitleContainer.vue";
export default {
  name: "QuittersAnalytics",
  components: {
    tc: AnalyticsTitleContainer,
    pv: PieView,
    cv: ChartView,
  },
  data() {
    return {
      dates: [],
      analytics: null,
      typeIndex: 2,
      users: null,
      date: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      totalsTrend: null,
      switchIndex: 0,
      totalsPie: null,
      gendersPie: null,
      locationsPie: null,
      totalTrends: null,
      reasonsTrend: null,
      gendersTrend: null,
      locationsTrend: null,
    };
  },
  methods: {
    downloadData() {
      let arr = [];
      this.rawData.forEach((item) => {
        arr.push(Object.assign(JSON.parse(item.data), { date: item.date }));
      });
      ExcelExporter.JSONToCSVConvertor(
        this.getParsedList(arr),
        "Quitters Analytics",
        true
      );
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          "Date (CST)": item.date
            ? this.$momentTZ(item.date).tz("Asia/Shanghai").format("DD-MM-YYYY")
            : "--",
          Total: item.total,
          "Reason 1": item.reasonOne,
          "Reason 2": item.reasonTwo,
          "Reason 3": item.reasonThree,
          "Reason 4": item.reasonFour,
          "Reason 5": item.reasonFive,
          "Other Reasons": item.otherReason,
          Male: item.maleUnSubsribers,
          Female: item.femaleUnsubscriber,
          "Unknown Gender": item.otherUnsubsribers,
          "Top 4 Cities": item.topFourCityUnsubsribers,
          "Rest Of The World": item.restOfTheWorldUnsubscribers,
        });
      });
      return parsed;
    },
    onClickSwitch(index) {
      this.switchIndex = index;
    },
    onDateSelected(date) {
      let start = this.$moment(date).format("YYYY-MM-DD");
      let selected = this.rawData.filter((item) => {
        let itemDate = this.$moment(item.date).format("YYYY-MM-DD");
        if (itemDate == start) {
          return item;
        }
      });

      this.users = null;
      this.populateData(JSON.parse(selected[0].data));
    },
    getData() {
      this.users = null;
      this.$HTTP.get(this.$URLS.ANALYTICS.QUITTERS).then((data) => {
        let arr = data.data.users.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        this.rawData = arr;
        this.dates = this.rawData.map((x) => x.date);
        this.maxDate = this.dates[this.dates.length - 1];
        this.date = this.maxDate;
        this.onDateSelected(this.maxDate);
        this.populateTrends();
      });
    },
    populateTrends() {
      let dates = this.dates.map((x) => this.$moment(x).format("D/MM"));

      this.totalTrends = {
        title: "Total Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Total Quitters",
              data: this.rawData.map((x) => JSON.parse(x.data).total),
              borderColor: "#42A5F5",
            },
          ],
        },
      };

      this.reasonsTrend = {
        title: "Reasons Trend",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Reason 1",
              data: this.rawData.map((x) => JSON.parse(x.data).reasonOne),
              borderColor: "#ef5350",
            },
            {
              label: "Reason 2",
              data: this.rawData.map((x) => JSON.parse(x.data).reasonTwo),
              borderColor: "#ab47bc",
            },
            {
              label: "Reason 3",
              data: this.rawData.map((x) => JSON.parse(x.data).reasonThree),
              borderColor: "#DCE775",
            },
            {
              label: "Reason 4",
              data: this.rawData.map((x) => JSON.parse(x.data).reasonFour),
              borderColor: "#2196f3",
            },
            {
              label: "Reason 5",
              data: this.rawData.map((x) => JSON.parse(x.data).reasonFive),
              borderColor: "#26c6da",
            },
            {
              label: "Others",
              data: this.rawData.map((x) => JSON.parse(x.data).otherReason),
              borderColor: "#64ffda",
            },
          ],
        },
      };

      this.gendersTrend = {
        title: "Gender Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Male",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).maleUnSubsribers
              ),
              borderColor: "#42A5F5",
            },
            {
              label: "Female",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).femaleUnsubscriber
              ),
              borderColor: "#EC407A",
            },
            {
              label: "Others",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).otherUnsubsribers
              ),
              borderColor: "#616161",
            },
          ],
        },
      };

      this.locationsTrend = {
        title: "Location Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Top 4 Cities",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).topFourCityUnsubsribers
              ),
              borderColor: "#8D6E63",
            },
            {
              label: "Rest of the World",
              data: this.rawData.map(
                (x) => JSON.parse(x.data).restOfTheWorldUnsubscribers
              ),
              borderColor: "#1DE9B6",
            },
          ],
        },
      };
    },

    populateData(data) {
      this.users = data;

      this.totalsPie = {
        title: "Total",
        labels: [
          "Reason 1",
          "Reason 2",
          "Reason 3",
          "Reason 4",
          "Reason 5",
          "Others",
        ],
        data: [
          this.users.reasonOne,
          this.users.reasonTwo,
          this.users.reasonThree,
          this.users.reasonFour,
          this.users.reasonFive,
          this.users.otherReason,
        ],
        backgroundColor: [
          "#ef5350",
          "#ab47bc",
          "#DCE775",
          "#2196f3",
          "#26c6da",
          "#64ffda",
        ],
      };

      this.gendersPie = {
        title: "By Gender",
        labels: ["Male", "Female", "Others"],
        backgroundColor: ["#42A5F5", "#EC407A", "#616161"],
        data: [this.users.totalMale, this.users.totalFemale],
        data: [
          this.users.maleUnSubsribers,
          this.users.femaleUnsubscriber,
          this.users.otherUnsubsribers,
        ],
      };

      this.locationsPie = {
        title: "By Location",
        labels: ["Top 4 Cities", "Rest of the World"],
        data: [
          this.users.topFourCityUnsubsribers,
          this.users.restOfTheWorldUnsubscribers,
        ],
        backgroundColor: ["#8D6E63", "#1DE9B6"],
      };
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss"></style>

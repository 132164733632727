<template>
  <div
    class="full-page flex-column anaytics-page-padding"
    style="height: 100%; overflow: scroll"
  >
    <tc
      title="Chats Analytics"
      @onClickSwitch="(index) => onClickSwitch(index)"
      @onClickDownload="downloadData"
      :hideDetails="true"
      :date="date"
      :allowedDates="dates"
    />

    <div class="analytics-container">
      <div class="charts">
        <cv :schematics="allTrends" />
        <cv :schematics="likesTremds" />
      </div>
    </div>
  </div>
</template>

<script>
const ExcelExporter = require("../../../../utils/excel");
import ChartView from "../../../../components/ChartView.vue";
import AnalyticsTitleContainer from "../../../../components/AnalyticsTitleContainer.vue";
export default {
  name: "PebblesAnalytics",
  components: {
    cv: ChartView,
    tc: AnalyticsTitleContainer,
  },
  data() {
    return {
      dates: [],
      analytics: null,
      typeIndex: 2,
      users: null,
      date: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      totalsTrend: null,
      switchIndex: 0,
      allTrends: null,
      likesTremds: null,
    };
  },
  methods: {
    downloadData() {
      let arr = [];
      this.rawData.forEach((item) => {
        arr.push(Object.assign(JSON.parse(item.data), { date: item.date }));
      });

      ExcelExporter.JSONToCSVConvertor(
        this.getParsedList(arr),
        "Pebbles Analytics",
        true
      );
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          "Date (CST)": item.date
            ? this.$momentTZ(item.date).tz("Asia/Shanghai").format("DD-MM-YYYY")
            : "--",
          "Total Count": item.all,
          "Total Likes": item.likes,
        });
      });
      return parsed;
    },
    onClickSwitch(index) {
      this.switchIndex = index;
    },
    getData() {
      this.users = null;
      this.$HTTP.get(this.$URLS.ANALYTICS.PEBBLES).then((data) => {
        let arr = data.data.pebbles.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        this.rawData = arr;
        this.dates = this.rawData.map((x) => x.date);
        this.maxDate = this.dates[this.dates.length - 1];
        this.date = this.maxDate;
        this.populateTrends(arr);
      });
    },
    populateTrends(data) {
      this.users = data;
      let dates = this.dates.map((x) => this.$moment(x).format("D/MM"));

      this.allTrends = {
        title: "Daily Creation Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Pebble",
              data: this.rawData.map((x) => JSON.parse(x.data).all),
              borderColor: "#42A5F5",
            },
          ],
        },
      };

      this.likesTremds = {
        title: "Daily Likes Trends",
        chartData: {
          labels: dates,
          datasets: [
            {
              label: "Total Likes",
              data: this.rawData.map((x) => JSON.parse(x.data).likes),
              borderColor: "#EC407A",
            },
          ],
        },
      };
    },
  },
  created() {
    this.getData(new Date());
  },
};
</script>

<style lang="scss"></style>

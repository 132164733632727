<template>
  <div class="pager">
    <img
      src="../assets/icons/ic_arrow_down.png"
      class="back"
      @click="onBack"
      :class="{ disabled: current <= 1 }"
    />
    <div class="pages">
      <span>page</span>
      <div class="flex-row flex-centered">
        <!-- <span class="current">{{ current }}</span> -->
        <input
          type="number"
          class="current"
          v-model="current"
          @keydown="onKeyDown"
        />
        OF
        <span class="total">{{ total }}</span>
      </div>
    </div>
    <img
      src="../assets/icons/ic_arrow_down.png"
      class="next"
      @click="onNext"
      :class="{ disabled: current >= total }"
    />
  </div>
</template>

<script>
export default {
  name: "Pager",
  props: {
    current: Number,
    total: Number,
  },
  methods: {
    onKeyDown(e) {
      if (e && e.key && e.key == "Enter") {
        this.$emit("onPageUpdated", parseInt(this.current));
      }
    },
    onBack() {
      if (this.current <= 1) return;
      this.$emit("onPageUpdated", parseInt(this.current - 1));
    },
    onNext() {
      if (this.current >= this.total) return;
      this.$emit("onPageUpdated", parseInt(this.current + 1));
    },
  },
};
</script>

<style lang="scss" scoped>
.pager {
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 2rem;
    transition: 0.25s all ease-in-out;
    padding: 0rem 1rem;

    &:hover {
      filter: brightness(100%);
    }
    margin-top: 1.3rem;
    filter: brightness(0%);
  }
  .back {
    transform: rotate(90deg);
  }
  .next {
    transform: rotate(270deg);
  }

  .disabled {
    opacity: 0.2;
    cursor: not-allowed;

    &:hover {
      filter: brightness(0%);
    }
  }

  span {
    text-transform: uppercase;
  }

  .pages {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0rem 2rem;
    font-size: 1rem;
    font-family: "P-500", sans-serif;
    .current {
      width: 5rem;
      text-align: center;
      margin-right: 1rem;
      font-family: "P-800", sans-serif;
      color: var(--clr-nine-green);
      font-size: 1.6rem;
      border: none;
      background: rgba(211, 211, 211, 0.192);
      border: 0.1rem solid rgba(128, 128, 128, 0.329);
      border-radius: 0.2rem;
      outline: none;

      &:focus {
        border: 0.2rem solid var(--clr-nine-green);
      }

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .total {
      margin-left: 1rem;
      font-size: 1.6rem;
    }
  }
}
</style>
<template>
  <div class="full-page flex-column page-padding">
    <filters
      :fields="filters"
      :processing="processing"
      @onFiltersToggle="onFiltersToggle"
      @onApplyFilters="onApplyFilters"
      @onClearFilters="onClearFilters"
    ></filters>

    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-row">
          <span class="page-title">Users</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <toggler
          :preIndex="order"
          :preSortBy="sortBy"
          :schema="sortingTogglerData"
          @onToggleOrder="onToggleOrder"
          @onToggleSortBy="onToggleSortBy"
        />

        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
        ></pager>
      </div>
      <div
        class="list-wrapper"
        v-if="users.length > 0"
        :class="{ 'loading-history-anim': processing }"
      >
        <table>
          <thead>
            <tr class="header">
              <th class="index">ID</th>
              <th>Creation Date (CST)</th>
              <th>Last Online (CST)</th>
              <th>Real Picture</th>
              <th>Nickname</th>
              <th>Gender</th>
              <th>Location</th>
              <th>Registration Status</th>
              <th>Verifiation Score</th>
              <th>Flagged</th>
              <th>Membership</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in users">
              <td class="index" style="width: 5%">
                {{ item.id || "--" }}
              </td>
              <td>
                {{
                  item.createdAt
                    ? $momentTZ(item.createdAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                {{
                  item.updatedAt
                    ? $momentTZ(item.updatedAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                <img
                  :src="item.realPicture"
                  :key="item.id"
                  class="listRealPic"
                />
              </td>
              <td>
                {{ item.nickName || "--" }}
              </td>
              <td style="text-transform: capitalize">
                {{ item.gender || "--" }}
              </td>
              <td>
                {{ item.location || "--" }}
              </td>
              <td style="text-transform: capitalize">
                {{
                  (item.registrationStatus
                    ? item.registrationStatus.replace("_", " ")
                    : "--") || "--"
                }}
              </td>
              <td style="text-transform: capitalize">
                {{ item.verificationScore || "--" }}
              </td>
              <td style="text-transform: capitalize">
                {{ item.flagged || "--" }}
              </td>

              <td style="text-transform: capitalize">
                <span v-html="getLatestOrder(item)"></span>
              </td>

              <td style="width: 20rem">
                <div class="cell-actions">
                  <button @click="onClickDetail(item)">Details</button>
                  <button @click="onClickUpdateStatus(item)">
                    Update Status
                  </button>
                  <button @click="onClickQuickApprove(item)">
                    Quick Approve
                  </button>
                  <button class="red" @click="onClickDeleteUser(item)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no users to show here...</span>
      </div>
    </div>

    <transition>
      <updatePoup
        v-if="showUpdatePopup"
        :model="updateModel"
        @onAbort="
          showUpdatePopup = false;
          updateModel = {};
        "
        @onSuccess="
          showUpdatePopup = false;
          updateModel = {};
          getAllUsers();
        "
      ></updatePoup>
    </transition>

    <transition>
      <quickApprovePopup
        v-if="showQuickApprovePopup"
        :model="quickApproveModel"
        @onAbort="
          showQuickApprovePopup = false;
          quickApproveModel = {};
        "
        @onSuccess="
          showQuickApprovePopup = false;
          quickApproveModel = {};
          getAllUsers();
        "
      ></quickApprovePopup>
    </transition>

    <transition>
      <deleteUserPopup
        v-if="showDeleteUserPopup"
        :model="deletableUser"
        @onAbort="
          showDeleteUserPopup = false;
          deletableUser = {};
        "
        @onSuccess="
          showDeleteUserPopup = false;
          deletableUser = {};
          getAllUsers();
        "
      ></deleteUserPopup>
    </transition>
  </div>
</template>

<script>
import Filters from "../../../components/Filters.vue";
import Pager from "../../../components/Pager.vue";
import UpdateUserStatusPopup from "../../../components/popups/UpdateUserStatusPopup.vue";
import Toggler from "../../../components/MultiToggler.vue";
import DeleteUserPopup from "../../../components/popups/DeleteUserPopup.vue";
import QuickApprovePopup from "../../../components/popups/QuickApprovePopup.vue";

import {
  REGISTRATION_STATUS,
  UPDATED_VERIFIED,
  GENDER,
} from "../../../assets/Constants";

export default {
  name: "Users",
  components: {
    filters: Filters,
    pager: Pager,
    updatePoup: UpdateUserStatusPopup,
    toggler: Toggler,
    deleteUserPopup: DeleteUserPopup,
    quickApprovePopup: QuickApprovePopup,
  },
  data() {
    return {
      showQuickApprovePopup: false,
      quickApproveModel: null,
      showDeleteUserPopup: false,
      order: "ASC",
      sortBy: "createdAt",
      showUpdatePopup: false,
      updateModel: {},
      processing: false,
      sortingTogglerData: {
        title: "SORT BY",
        desc: "Last Update",
        orderOptions: [
          {
            key: "ASC",
            val: "Ascending",
          },
          {
            key: "DESC",
            val: "Descending",
          },
        ],
        sortByOptions: [
          {
            key: "createdAt",
            value: "Creation Date",
          },
          {
            key: "updatedAt",
            value: "Last Updated",
          },
        ],
      },
      filters: [
        {
          id: "id",
          name: "User ID",
          type: "text",
          placeholder: "ID of the user",
          val: "",
          required: false,
        },
        {
          id: "nickName",
          name: "Nick Name",
          type: "text",
          placeholder: "Nick name of the user",
          val: "",
          required: false,
        },
        GENDER,
        {
          id: "phone",
          name: "Phone",
          type: "text",
          placeholder: "Phone no. of the user",
          val: "",
          required: false,
        },
        {
          id: "deviceType",
          name: "Device Type",
          type: "select",
          placeholder: "Device type of user",
          val: "",
          options: [
            {
              key: "ios",
              value: "iOS",
            },
            {
              key: "android",
              value: "Android",
            },
          ],
          required: false,
        },
        REGISTRATION_STATUS,
        {
          id: "flagged",
          name: "Flagged",
          type: "select",
          placeholder: "Flagged or not",
          val: "",
          required: false,
          options: [
            {
              key: true,
              value: "Yes",
            },
            {
              key: false,
              value: "No",
            },
          ],
        },
        {
          id: "verificationScore",
          name: "Verification Score",
          type: "select",
          placeholder: "Verification score for the user",
          val: "",
          required: false,
          options: [
            {
              key: "null",
              value: "Nil",
            },
            {
              key: 1,
              value: "1",
            },
            {
              key: 2,
              value: "2",
            },
            {
              key: 3,
              value: "3",
            },
          ],
        },
      ],
      users: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      filtersData: {},
    };
  },
  methods: {
    onClickQuickApprove(item) {
      this.quickApproveModel = item;
      this.showQuickApprovePopup = true;
    },
    getLatestOrder(item) {
      if (!item) return "--";
      if (!item.orders || item.orders.length <= 0) return "--";
      if (item.orders.length == 1) {
        return `${item.orders[0].good.nameEn} <br> ${item.orders[0].good.nameCn}`;
      } else {
        let sorted = item.orders.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        return `${sorted[0].good.nameEn} <br/> ${sorted[0].good.nameCn}`;
      }
    },
    onClickDeleteUser(item) {
      this.deletableUser = item;
      this.showDeleteUserPopup = true;
    },
    onToggleSortBy(e) {
      this.sortBy = e;
      localStorage.setItem("usersSortBy", e);
      this.getAllUsers();
    },
    onToggleOrder(e) {
      this.order = e;
      localStorage.setItem("usersOrder", e);
      this.getAllUsers();
    },
    onClearFilters() {
      localStorage.setItem("filters", null);
      this.filtersData = {};
      this.getAllUsers();
    },
    onApplyFilters() {
      var data = {};
      this.filters.forEach((item) => {
        if (item.id === "verificationScore" && item.val === "null") {
          data[item.id] = {
            nin: [1, 2, 3],
          };
        } else if (item.val) {
          if (item.type == "text") {
            data[item.id] = {
              like: item.val,
              options: "i",
            };
          } else {
            data[item.id] = item.val;
          }
        }
      });
      this.filtersData = data;
      this.getAllUsers();
      localStorage.setItem("filters", JSON.stringify(this.filters));
    },
    onClickDetail(item) {
      this.$router.push({
        name: "UserDetails",
        query: { id: item.id },
      });
    },
    onClickUpdateStatus(item) {
      this.updateModel = item;
      this.showUpdatePopup = true;
    },
    onPageUpdated(page) {
      this.page = page;
      localStorage.setItem("usersPage", parseInt(page));
      this.getAllUsers();
    },
    getAllUsers(data) {
      this.processing = true;
      var order = `${this.sortBy} ${this.order}`;
      this.$HTTP
        .post(
          `${this.$URLS.USERS.LIST}?order=${order}&page=${this.page}&results_per_page=${this.pageSize}`,
          this.filtersData || {}
        )
        .then((res) => {
          this.users = res.data.data.users;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onFiltersToggle(opened) {
      setTimeout(() => {
        this.processListHeight();
      }, 100);
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 40);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    let page = localStorage.getItem("usersPage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("usersOrder") || "DESC";
    this.order = order;

    let sortBy = localStorage.getItem("usersSortBy") || "createdAt";
    this.sortBy = sortBy;

    let filters = localStorage.getItem("filters");
    if (filters && filters != "null") {
      this.filters = JSON.parse(filters);
      this.onApplyFilters();
    } else {
      this.getAllUsers();
    }
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="inner-page-wrapper flex-column page-padding">
    <div class="card">
      <div class="title-container">
        <div class="flex-row flex-centered">
          <span class="page-title">Messages</span>
          <div class="processing" v-if="processing"></div>

          <button class="bt-nav-action" @click="showUserSearch = true">
            + Start New Chat
          </button>
        </div>
      </div>
    </div>
    <div class="messages" v-if="chatOn">
      <div class="chat card" v-if="!selectedUser">
        <div class="no-user-selected">
          <img src="../assets/svg/begin-chat.svg" alt="" />
          <span>Please select a user from the right side to start a chat.</span>
        </div>
      </div>
      <div class="chat card" v-if="selectedUser">
        <div class="title-container">
          <div class="avatar-container">
            <img
              :src="selectedUser.realPicture"
              v-if="selectedUser.realPicture"
            />
            <div class="initials" v-if="!selectedUser.realPicture">
              {{ getFirstChar(selectedUser) }}
            </div>
          </div>
          <b>{{ selectedUser.nickName }}</b>
          <!-- <button class="profile" @click="showProfile = true">View Profile</button> -->
        </div>
        <div class="chat-container" id="chatContainer">
          <div
            class="chat-cell"
            v-for="message in messages"
            v-bind:key="message.content"
            :class="{ me: message.isMe, 'not-me': !message.isMe }"
          >
            <span class="message" v-if="!message.isImage">{{
              message.content
            }}</span>
            <div class="preview">
              <img :src="message.content" v-if="message.isImage" />
              <div
                class="loading"
                v-if="
                  message.isImage && message.progress && message.progress < 100
                "
              >
                <div class="spinner"></div>
                {{ message.progress }}%
              </div>
            </div>
            <span class="timestamp">{{ message.timestamp || "--" }}</span>

            <span
              class="err"
              v-if="
                message.isImage && message.progress && message.progress == -1
              "
              >Failed</span
            >
          </div>
        </div>

        <div class="message-container">
          <div class="btn-container">
            <img src="../assets/icons/ic_attach_file.png" class="attach" />
            <input type="file" accept="image/*" @change="onSelectChatFile" />
          </div>
          <input
            type="text"
            placeholder="enter your message here..."
            v-model="messageStr"
            v-on:keyup.enter="onEnterKey"
          />
          <div class="btn-container" @click="onEnterKey">
            <img src="../assets/icons/ic_send.png" alt="" class="send" />
          </div>
        </div>
      </div>

      <!-- CHAT HISTORY -->
      <div class="users card">
        <searchBar
          placeholder="search user or old chat"
          v-model="userSearchString"
          @onEnter="onEnterSearch"
        />

        <div class="loading-history" v-if="loadingHistory">
          <div class="processing"></div>
          loading history...
        </div>

        <div v-else style="height: 100%">
          <div class="loading-history" v-if="filteredUser.length <= 0">
            <img src="../assets/svg/empty.svg" alt="" />
            <span>no chat history available...</span>
          </div>

          <div class="users-list" v-if="filteredUser.length > 0">
            <div
              class="user"
              v-for="(user, index) in filteredUser"
              v-bind:key="user.id"
              :class="{
                'selected-user': selectedUser && selectedUser.id === user.id,
              }"
              @click="onClickUser(user, index)"
            >
              <div class="bubble-cotainer">
                <div v-if="user.isNew"></div>
              </div>
              <div class="avatar-container">
                <img :src="user.realPicture" v-if="user.realPicture" />
                <div class="initials" v-if="!user.realPicture">
                  {{ getFirstChar(user) }}
                </div>
              </div>
              <div class="info">
                <div class="top">
                  <span class="name">{{ user.nickName }}</span>
                  <span v-if="user.message" class="time">{{
                    formatTime(user.message.time)
                  }}</span>
                  <img
                    @click="deleteConverstation(user)"
                    class="delete"
                    src="../assets/icons/ic_delete.png"
                    alt=""
                  />
                </div>
                <span class="message" v-if="user.message">{{
                  user.message.type == "RC:TxtMsg"
                    ? user.message.content
                    : "[image]"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="chatoff" v-else>
      <img src="../assets/svg/empty.svg" alt="" />
      <span>Chat is turned off. Please turn on chat from the nav bar</span>
    </div>

    <searchUserPopup
      v-if="showUserSearch"
      @abort="showUserSearch = false"
      @success="onStartNewChat"
    ></searchUserPopup>
  </div>
</template>

<script>
import SearchUserPopup from "../components/popups/SearchUserPopup.vue";
import SearchBar from "../components/SearchBar.vue";
const RongIMLib = require("@rongcloud/imlib-v4");

export default {
  name: "Messages",
  components: {
    searchBar: SearchBar,
    searchUserPopup: SearchUserPopup,
  },
  data() {
    return {
      chatOn: false,
      showUserSearch: false,
      adminChatUser: {},
      num: 0,
      notice: "",
      gobalToken: {},
      list: [],
      talk: [],
      dialogBan: false,
      dialogBanData: "",
      rongyun: null,
      messageList: [],
      processing: false,
      userSearchString: "",
      users: [],
      filteredUser: [],
      selectedUser: null,
      messageStr: "",
      messages: [],
      loadingHistory: true,
    };
  },
  methods: {
    onStartNewChat(user) {
      this.selectedUser = user;
      this.showUserSearch = false;

      this.users.unshift({
        id: user.id,
        nickName: user.nickName,
        realPicture: user.realPicture,
        message: null,
      });
      this.filteredUser = this.users;
    },
    async onClickUser(user, index) {
      this.filteredUser[index].isNew = false;

      this.selectedUser = user;
      this.messages = [];
      let { chatHistory, chatHistoryError } = await this.getChatHistoryById(
        user.id
      );
      if (!chatHistory) {
        return;
      }
      chatHistory.forEach((msg) => {
        this.messages.push({
          content: msg.content.imageUri || msg.content.content,
          isMe: msg.senderUserId != user.id,
          timestamp: this.formatTime(msg.sentTime),
          isImage: msg.messageType == RongIMLib.MESSAGE_TYPE.IMAGE,
        });
      });
      this.scrollChatToBottom();
    },
    scrollChatToBottom() {
      let container = document.getElementById("chatContainer");
      if (!container) {
        return;
      }
      setTimeout(() => {
        container.scrollTop = container.scrollHeight - container.clientHeight;
      }, 100);
    },
    getMessageExtra() {
      let d = {
        isNine: true,
        receiveName: this.selectedUser.nickName,
        receiverHeadUrl: this.selectedUser.realPicture,
        receiverId: this.selectedUser.id,
        senderHeadUrl: this.adminChatUser.realPicture,
        senderId: this.adminChatUser.id,
        senderName: this.adminChatUser.name,
      };
      console.log(d);
      return JSON.stringify(d);
    },
    getMessageUserObject() {
      return {
        id: this.adminChatUser.id,
        name: "nine你呢",
        portrait: "https://oss.meetnine.cn/uploads/albumPictures/62602685.jpg",
      };
    },
    deleteConverstation(user) {
      var deleteId;
      if (user.id == "a8065503-039a-5c77-848b-affb84820ca2") {
        deleteId = user.extra.receiverId;
      } else {
        deleteId = user.id;
      }
      this.$Notifications.showLoading();
      let im = this.rongyun;
      var conversation = im.Conversation.get({
        targetId: deleteId,
        type: RongIMLib.CONVERSATION_TYPE.PRIVATE,
      });
      conversation
        .destory()
        .then((r) => {
          this.getAllConverstations();
          this.$Notifications.showNotification(
            "success",
            "Conversation deleted"
          );
          if (this.selectedUser.id == deleteId) {
            this.selectedUser = null;
          }
          this.$Notifications.hideLoading();
        })
        .catch((e) => {
          console.log("delete error");
          this.$Notifications.showNotification("danger", e.msg);
          console.error(e);
        });
    },
    getChatHistoryById(targetId) {
      return new Promise((resolve, reject) => {
        let im = this.rongyun;
        var conversation = im.Conversation.get({
          targetId: targetId,
          type: RongIMLib.CONVERSATION_TYPE.PRIVATE,
        });
        conversation
          .getMessages({
            timestamp: 0,
            count: 1000,
          })
          .then(function (result) {
            resolve({ chatHistory: result.list, chatHistoryError: null });
          })
          .catch((error) => {
            resolve({ chatHistory: null, chatHistoryError: error });
          });
      });
    },
    getAllConverstations() {
      let ctx = this;
      let im = this.rongyun;
      im.Conversation.getList({
        count: 30,
        startTime: 0,
        order: 0,
      })
        .then((conversationList) => {
          ctx.users = [];
          ctx.filteredUser = [];
          ctx.loadingHistory = false;
          conversationList.forEach((item) => {
            try {
              let extra = JSON.parse(item.latestMessage.content.extra);
              let user = item.latestMessage.content.user;
              let id, name, pic;

              if (user.id != ctx.adminChatUser.id) {
                id = user.id;
                name = user.name;
                pic = user.portrait;
              } else if (
                extra &&
                extra.senderId &&
                extra.senderId != ctx.adminChatUser.id
              ) {
                id = extra.senderId;
                name = extra.senderName;
                pic = extra.senderHeadUrl;
              } else if (
                extra &&
                extra.receiverId &&
                extra.receiverId != ctx.adminChatUser.id
              ) {
                id = extra.receiverId;
                name = extra.receiveName;
                pic = extra.receiverHeadUrl;
              } else if (extra.id === ctx.adminChatUser.id) {
                id = user.receiverId;
                name = user.receiveName;
                pic = user.receiverHeadUrl;
              }
              ctx.users.push({
                id: id,
                nickName: name,
                realPicture: pic,
                message: {
                  content: item.latestMessage.content.content,
                  type: item.latestMessage.messageType,
                  time: item.latestMessage.sentTime,
                },
                extra: extra,
              });
              ctx.filteredUser = ctx.users;
              ctx.loadingHistory = false;
            } catch (error) {
              console.log(error);
            }
          });
        })
        .catch((e) => {
          console.log("ERR");
          console.error(e);
          ctx.loadingHistory = false;
        });
    },
    getFirstChar(user) {
      if (user.nickName) {
        return user.nickName[0];
      } else {
        return "9";
      }
    },
    onEnterSearch(e) {
      this.userSearchString = e;
      if (!this.userSearchString || this.userSearchString == "") {
        this.filteredUser = this.users;
        return;
      }
      this.filteredUser = this.users.filter((item) => {
        return (
          (item.nickName &&
            item.nickName
              .toLowerCase()
              .includes(this.userSearchString.toLowerCase())) ||
          (item.message &&
            item.message.content
              .toLowerCase()
              .includes(this.userSearchString.toLowerCase()))
        );
      });
    },
    async onEnterKey() {
      this.messages.push({
        content: this.messageStr,
        isMe: true,
        timestamp: this.formatTime(new Date()),
        isImage: false,
      });
      this.scrollChatToBottom();
      let ctx = this;
      let im = this.rongyun;
      let { sendResponse, sendError } = await this.sendMessage(
        this.selectedUser.id,
        RongIMLib.MESSAGE_TYPE.TEXT,
        {
          content: ctx.messageStr,
          user: {
            id: this.adminChatUser.id,
            name: "nine你呢",
            portrait:
              "https://oss.meetnine.cn/uploads/albumPictures/62602685.jpg",
            isNine: true,
            receiveName: this.selectedUser.nickName,
            receiverHeadUrl: this.selectedUser.realPicture,
            receiverId: this.selectedUser.id,
            senderHeadUrl: this.adminChatUser.realPicture,
            senderId: this.adminChatUser.id,
            senderName: this.adminChatUser.name,
          },
          extra: JSON.stringify(this.getMessageUserObject()),
        }
      );

      if (!this.selectedUser) {
        this.messageStr = "";
        return;
      }

      var index = this.users
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.selectedUser.id);

      if (index < 0) {
        this.messageStr = "";
        return;
      }

      this.users[index].message = {
        content: this.messageStr,
        type: RongIMLib.MESSAGE_TYPE.TEXT,
        time: new Date(),
      };

      this.messageStr = "";
      this.sortChatHistory(this.selectedUser.id);
    },
    compress(img, size) {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let width = img.width;
      let height = img.height;
      canvas.width = 100;
      canvas.height = 100;
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, width, height);
      let ndata = canvas.toDataURL("image/jpeg", size);
      return ndata;
    },
    getCompressedBase64(file) {
      let ctx = this;
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        //Convert image to Base64 format
        reader.readAsDataURL(file);
        //Callback after successful reading
        reader.onloadend = function () {
          let result = this.result;
          let img = new Image();
          img.src = result;
          img.onload = function () {
            let compressed = ctx.compress(img, 0.3);
            var byteLength = parseInt(
              compressed.replace(/=/g, "").length * 0.75
            );
            resolve(compressed);
          };
        };
      });
    },
    uploadToQiniu(file, messageIndex) {
      let ctx = this;
      let im = this.rongyun;
      var fileType = RongIMLib.FILE_TYPE.IMAGE;
      var config = {
        domain: "https://upload.qiniu.com",
        fileType: fileType,
        getToken: function (callback) {
          im.getFileToken(fileType)
            .then(function (data) {
              callback(data.token, data);
            })
            .catch(function (error) {});
        },
      };

      return new Promise((resolve, reject) => {
        var uploadCallbacks = {
          onProgress: function (loaded, total) {
            var percent = Math.floor((loaded / total) * 100);
            console.log("已上传: ", percent);
            ctx.messages[messageIndex].progress = percent;
          },
          onCompleted: function (data) {
            // 上传完成, 调用 getFileUrl 获取文件下载 url
            console.log("上传成功: ", data);
            resolve({ uploadData: data, uploadError: null });
          },
          onError: function (error) {
            console.error("上传失败", error);
            resolve({ uploadData: null, uploadError: error });
          },
        };

        UploadClient.initFile(config, function (uploadFile) {
          uploadFile.upload(file, uploadCallbacks);
        });
      });
    },
    sendMessage(targetId, type, content) {
      let im = this.rongyun;
      return new Promise((resolve, reject) => {
        im.Conversation.get({
          targetId: targetId,
          type: RongIMLib.CONVERSATION_TYPE.PRIVATE,
        })
          .send({
            messageType: type,
            content: content,
          })
          .then(function (message) {
            resolve({ sendResponse: message, sendError: null });
          })
          .catch((error) => {
            console.log("发送文字消息失败", error.code, error.msg);
            resolve({ sendResponse: null, sendError: error });
          });
      });
    },
    async onSelectChatFile(e) {
      let file = e.target.files[0];
      this.messages.push({
        isMe: true,
        timestamp: this.formatTime(new Date()),
        isImage: true,
        content: URL.createObjectURL(file),
        progress: 0,
      });

      let messageIndex = this.messages.length - 1;

      let base64 = await this.getCompressedBase64(file);
      let { uploadData, uploadError } = await this.uploadToQiniu(
        file,
        messageIndex
      );
      let { sendResponse, sendError } = await this.sendMessage(
        this.selectedUser.id,
        RongIMLib.MESSAGE_TYPE.IMAGE,
        {
          content: base64,
          imageUri: uploadData.downloadUrl,
          user: {
            id: this.adminChatUser.id,
            name: "nine你呢",
            portrait:
              "https://oss.meetnine.cn/uploads/albumPictures/62602685.jpg",
            isNine: true,
            receiveName: this.selectedUser.nickName,
            receiverHeadUrl: this.selectedUser.realPicture,
            receiverId: this.selectedUser.id,
            senderHeadUrl: this.adminChatUser.realPicture,
            senderId: this.adminChatUser.id,
            senderName: this.adminChatUser.name,
          },
          extra: JSON.stringify(this.getMessageUserObject()),
        }
      );
      if (!sendResponse) {
        this.messages[messageIndex].progress = -1;
      }

      var index = this.users
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.selectedUser.id);

      this.users[index].message = {
        content: uploadData.downloadUrl || "",
        type: RongIMLib.MESSAGE_TYPE.IMAGE,
        time: this.formatTime(new Date()),
      };

      this.sortChatHistory(this.selectedUser.id);
    },
    linkToRongs(token) {
      const that = this;
      that.rongyun = this.$im;
      const im = that.rongyun;
      im.watch({
        conversation(event) {
          console.log("CONVERSTATION EVENT");
        },
        message(event) {
          try {
            let msgObj = event.message;
            let content = msgObj.content;
            let sender = content.user;
            let isMe = false;

            if (that.adminChatUser.id === sender.id) {
              let e = JSON.parse(content.extra);
              sender = {
                id: e.receiverId,
                name: e.receiveName,
                realPicture: e.receiverHeadUrl,
              };

              if (e.senderName.includes("nine")) {
                isMe = true;
              } else {
                isMe = false;
              }
            }

            if (!sender) {
              console.log("sender is null");
              return;
            }

            if (that.selectedUser && sender.id == that.selectedUser.id) {
              that.messages.push({
                content: content.imageUri || content.content,
                isMe: isMe,
                isNew: false,
                timestamp: that.formatTime(msgObj.receivedTime),
                isImage: msgObj.messageType == "RC:ImgMsg",
              });

              var index = that.users
                .map(function (x) {
                  return x.id;
                })
                .indexOf(sender.id);

              that.users[index].isNew = isMe;
              that.users[index].message = {
                content: content.imageUri || content.content,
                type: content.imageUri
                  ? RongIMLib.MESSAGE_TYPE.IMAGE
                  : RongIMLib.MESSAGE_TYPE.TEXT,
                time: that.formatTime(msgObj.receivedTime),
              };
              that.sortChatHistory(sender.id);
              that.scrollChatToBottom();
            } else {
              var index = that.users
                .map(function (x) {
                  return x.id;
                })
                .indexOf(sender.id);
              if (index > -1) {
                that.users[index].isNew = true;
                that.users[index].message = {
                  content: content.imageUri || content.content,
                  type: content.imageUri
                    ? RongIMLib.MESSAGE_TYPE.IMAGE
                    : RongIMLib.MESSAGE_TYPE.TEXT,
                  time: that.formatTime(msgObj.receivedTime),
                };
                that.sortChatHistory(sender.id);
              } else {
                that.users.unshift({
                  id: sender.id,
                  nickName: sender.name,
                  isNew: true,
                  realPicture: sender.portrait,
                  message: {
                    content: content.imageUri || content.content,
                    type: content.imageUri
                      ? RongIMLib.MESSAGE_TYPE.IMAGE
                      : RongIMLib.MESSAGE_TYPE.TEXT,
                    time: msgObj.receivedTime,
                  },
                });
                that.filteredUser = that.users;
              }
            }
          } catch (error) {
            console.error(error);
          }
        },
        status(event) {
          console.log("connection status:", event.status);
        },
      });
    },
    sortChatHistory(userId) {
      let that = this;
      var index = this.users
        .map(function (x) {
          return x.id;
        })
        .indexOf(userId);
      let element = that.users[index];
      that.users.splice(index, 1);
      that.users.splice(0, 0, element);
      that.filteredUser = that.users;
    },
    disconnect() {
      let im = this.rongyun;
      im.disconnect().then(() => console.log("断开链接成功"));
    },
    formatTime(time) {
      if (typeof time == "string") {
        return time;
      }
      let weeksAgo = this.$moment
        .duration(this.$moment(time).diff(new Date()))
        .as("days");
      if (weeksAgo <= 1) {
        return this.$moment(time).fromNow().replace("a ", "");
      } else {
        return this.$moment(time).format("MM-DD hh:mm a");
      }
    },
  },
  created() {
    try {
      this.adminChatUser = JSON.parse(localStorage.getItem("chat"));
      let imToken = this.adminChatUser.imToken;
      this.linkToRongs(imToken);
    } catch (error) {
      this.$Notifications.showNotification("danger", error.msg);
      this.loadingHistory = false;
    }
    document.addEventListener("nine:onChatOff", (ev) => {
      this.chatOn = false;
      console.log("chat off in messages = ", this.chatOn);
    });
    document.addEventListener("nine:onChatOn", (ev) => {
      this.chatOn = true;
      this.getAllConverstations();
      this.loadingHistory = false;
    });
  },
  unmounted() {
    this.disconnect();
  },
};
</script>

<style lang="scss" scoped>
.chatoff {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 30%;
    margin-bottom: 2rem;
  }
}

.messages {
  width: 100%;
  height: calc(100% - 8.5rem);
  margin-top: 2rem;
  display: grid;
  grid-template-columns: calc(70% - 2rem) 30%;
  gap: 2rem;

  .chat {
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    overflow: hidden;
    padding: 0rem !important;
    display: flex;
    flex-direction: column;

    .no-user-selected {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      position: relative;

      img {
        width: 100%;
        margin-bottom: 4rem;
      }

      span {
        font-size: 1.2rem;
        font-family: "P-400", sans-serif;
        font-style: italic;
        color: gray;
      }
    }

    .title-container {
      width: 100%;
      padding: 1rem;
      border-bottom: 0.1rem solid var(--clr-nine-green);
      display: flex;
      align-items: center;

      .avatar-container {
        height: 3rem;
        width: 3rem;
        border: 0.1rem solid var(--clr-nine-green);
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1rem;

        img {
          width: 100%;
          height: 100%;
        }

        .initials {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--clr-skin-light);
          font-size: 1.3rem;
          font-family: "P-600", sans-serif;
          color: gray;
        }
      }

      button {
        background: transparent;
        color: var(--clr-nine-green);
        border: none;
        font-family: "P-400", sans-serif;
        margin-left: auto;
        font-size: 1.2rem;
        cursor: pointer;
        transition: 0.2s all ease-in-out;
        padding: 0.2rem 0.8rem;
        border: 0.1rem solid var(--clr-nine-green);
        border-radius: 0.4rem;

        &:hover {
          background: var(--clr-nine-green);
          color: white;
          padding: 0.2rem 0.8rem;
          border-radius: 0.4rem;
          box-shadow: 0rem 0rem 1rem 0rem lightgrey;
        }
      }

      b {
        color: black;
        font-size: 1.4rem;
        font-family: "P-500", sans-serif;
      }
    }

    .message-container {
      width: 100%;
      background: var(--clr-nine-green-light);
      height: 6rem;
      display: flex;
      align-items: center;
      margin-top: auto;

      .btn-container {
        height: 5rem;
        width: 5rem;
        display: flex;
        cursor: pointer;
        position: relative;

        img {
          height: 3rem;
          margin: auto;
        }

        &:hover {
          img {
            filter: invert(70%) sepia(34%) saturate(873%) hue-rotate(113deg)
              brightness(98%) contrast(100%);
          }
        }

        input {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }

      input {
        width: calc(100% - 10rem);
        height: 4rem;
        border: none;
        outline: none;
        border-radius: 5rem;
        padding: 1.5rem;
        font-size: 1.6rem;
        font-family: "P-300", sans-serif;
      }
    }

    .chat-container {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      scroll-behavior: smooth;

      .chat-cell {
        max-width: 75%;
        display: flex;
        flex-direction: column;
        border-radius: 1.6rem;
        padding: 0.6rem 1rem;
        margin-bottom: 1rem;

        .preview {
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 25rem;
          position: relative;
          border-radius: 1rem;
          overflow: hidden;

          img {
            max-height: 25rem;
            object-fit: contain;
            width: 100%;
            position: relative;
            top: 0;
            left: 0;
            z-index: 1;
          }

          .loading {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background: rgba($color: #000000, $alpha: 0.5);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 1rem;
            font-weight: 700;

            .spinner {
              width: 3rem;
              height: 3rem;
              background: transparent;
              border-radius: 50%;
              border-width: 0.3rem;
              border-color: gray;
              border-style: solid;
              border-top-color: white;
              animation: spin 1s linear infinite;
              margin-bottom: 1rem;
            }
          }
        }

        .message {
          font-size: 1.4rem;
          font-family: "P-300", sans-serif;
        }

        .timestamp {
          font-size: 1rem;
          font-family: "P-200", sans-serif;
          color: gray;
          margin-top: 0.4rem;
        }

        .err {
          font-size: 1.2;
          font-family: "P-800";
          color: red;
          margin: 0.4rem 0rem;
        }

        &:last-child {
          margin-bottom: 0rem;
        }
      }

      .me {
        background: var(--clr-nine-green-light);
        border-top-right-radius: 0rem !important;
        margin-left: auto;
        .message {
          margin-left: auto;
        }
        .timestamp {
          margin-left: auto;
        }
      }

      .not-me {
        background: rgba(211, 211, 211, 0.3);
        border-top-left-radius: 0rem !important;
        margin-right: auto;
      }
    }
  }

  .users {
    padding: 0rem !important;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    overflow: hidden;

    .users-list {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding-bottom: 6rem;

      .user {
        display: flex;
        align-items: center;
        min-height: 6rem;
        max-height: 10rem;
        padding: 1rem 0.5rem 1rem 0.15rem;
        border-bottom: 0.1rem solid #dbdde0;
        font-size: 1.4rem;
        color: black;
        transition: 0.2s all ease-in-out;
        border-left: 0.5rem solid transparent;

        .bubble-cotainer {
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;
          border-radius: 50%;
          overflow: hidden;

          div {
            width: 100%;
            height: 100%;
            background: var(--clr-nine-green);
          }
        }

        &:hover {
          background: #fafafa;
        }

        .avatar-container {
          height: 5rem !important;
          width: 5rem !important;
          border: 0.1rem solid var(--clr-nine-green);
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }

          .initials {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--clr-skin-light);
            font-size: 1.4rem;
            font-family: "P-600", sans-serif;
            color: gray;
          }
        }

        .info {
          width: calc(100% - 9rem);
          display: flex;
          flex-direction: column;
          margin-left: 1rem;
          margin-right: 0.4rem;
          overflow: hidden;

          .top {
            width: 100%;
            display: flex;
            align-items: center;

            .name {
              font-size: 1.4rem;
              color: black;
              font-family: "P-500";
            }

            .time {
              font-size: 1rem;
              color: gray;
              font-family: "P-400";
              margin-left: auto;
              margin-top: auto;
              margin-bottom: auto;
            }

            .delete {
              width: 1.5rem;
              height: 1.5rem;
              cursor: pointer;
              margin-left: 1rem;
              transition: 0.2s all ease-in-out;
              border-width: 0.1rem;
              border-style: solid;
              border-color: transparent;
              border-radius: 50%;

              &:hover {
                border-color: red;
                padding: 0.2rem;
              }
            }

            .flag {
              width: 1.5rem !important;
              height: 1.5rem !important;
              margin-left: 1rem;
            }
          }

          .message {
            margin-top: 0.4rem;
            font-size: 1.2rem;
            color: gray;
            font-family: "P-400";
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .selected-user {
        height: 7rem;
        border-left: 0.5rem solid var(--clr-nine-green);

        font-family: "P-600", sans-serif;

        .avatar-container {
          .initials {
            font-family: "P-800", sans-serif;
            color: black;
          }
        }
      }
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
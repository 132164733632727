<template>
  <div class="popup">
    <div class="card w-60">
      <h1 class="card-title">Quick Approve</h1>

      <div class="card-content">
        <h3>
          You can quickly approve or reject a user based on real pictures and
          album pictures
        </h3>

        <div class="current">
          <div class="basic">
            <img
              class="img"
              :src="model.realPicture"
              @click="preview(model.realPicture)"
            />

            <div class="grid">
              <div class="flex-column">
                <span class="key">ID</span>
                <span class="val">{{ model.id }}</span>
              </div>

              <div class="flex-column">
                <span class="key">Name</span>
                <span class="val">{{ model.nickName }}</span>
              </div>

              <div class="flex-column">
                <span class="key">Gender</span>
                <span class="val">{{ model.gender }}</span>
              </div>

              <div class="flex-column">
                <span class="key">Creation Date</span>
                <span class="val">{{ model.createdAt }}</span>
              </div>

              <div class="flex-column">
                <span class="key">Age</span>
                <span class="val">{{ model.age }}</span>
              </div>

              <div class="flex-column">
                <span class="key">Location</span>
                <span class="val">{{ model.location }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="album">
          <h3>
            Album Pictures ({{
              model && model.albumPictures && model.albumPictures.length
            }})
          </h3>
          <div class="pics" v-dragscroll="true">
            <img
              v-for="pic in model.albumPictures"
              v-bind:key="pic"
              :src="pic"
              alt=""
              @click="preview(pic)"
            />
          </div>
        </div>
      </div>

      <div class="actions">
        <div></div>
        <div></div>
        <button class="bt-simple" @click="onClickAbort">Abort</button>
        <buttonView
          title="Approve"
          :processing="processing"
          enabled="true"
          @click="onClickApprove"
        ></buttonView>
        <buttonView
          title="Reject"
          :processing="processing"
          enabled="true"
          @click="onClickReject"
        ></buttonView>
      </div>
    </div>

    <div class="preview" v-if="img" @click="img = null">
      <img :src="img" alt="" />
    </div>
  </div>
</template>

<script>
import InputView from "../InputView.vue";
import ButtonView from "../ButtonView.vue";

export default {
  name: "Popup",
  props: {
    model: Object,
  },
  components: {
    inputView: InputView,
    buttonView: ButtonView,
  },
  data() {
    return {
      processing: false,
      img: null,
    };
  },
  methods: {
    preview(img) {
      this.img = img;
    },
    onClickAbort() {
      this.$emit("onAbort");
    },
    onClickApprove() {
      this.updateStatus("fully_verified");
    },
    onClickReject() {
      this.updateStatus("verification_rejected");
    },
    updateStatus(status) {
      this.processing = true;

      var data = {
        registrationStatus: status,
      };

      this.$HTTP
        .patch(`${this.$URLS.USERS.UPDATE}?id=${this.model.id}`, data)
        .then((res) => {
          console.log("res");
          console.log(res);
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            "User updated successful !"
          );
          this.$emit("onSuccess");
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
          this.processing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 2rem;
}

.basic {
  display: flex;
  flex-direction: row;

  .grid {
    margin-left: 5rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 2rem;
  }
}

img {
  max-height: 16rem;
  &:hover {
    cursor: zoom-in;
  }
}

.album {
  margin-top: 3rem;
  h3 {
    font-family: "P-600";
  }

  .pics {
    display: flex;
    cursor: grab;
    margin-top: 1rem;
    overflow-x: scroll;
    scrollbar-track-color: 1rem;

    img {
      margin-right: 1rem;
      max-height: 30rem;
      margin-bottom: 1rem;
    }
  }
}

.preview {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 80vh;
    cursor: grabbing;
    min-height: 10rem;
    background: lightgray;
  }
}

html {
  ::-webkit-scrollbar {
    width: 1rem !important; /* Remove scrollbar space */
    background: red !important; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: orange !important;
  }
}
</style>

<template>
  <div class="switches">
    <div class="inner">
      <div
        v-for="item in items"
        v-bind:key="item"
        class="switch"
        :class="{ selected: switchIndex == items.indexOf(item) }"
        @click="
          switchIndex = items.indexOf(item);
          $emit('onClickItem', items.indexOf(item));
        "
      >
        {{ item }}
      </div>
    </div>

    <div class="line" :class="{ 'line-selected': switchIndex == 1 }"></div>
  </div>
</template>

<script>
export default {
  name: "NavSwitches",
  props: {
    items: Array,
    preSelectIndex: Number,
  },
  data() {
    return {
      switchIndex: 0,
    };
  },
  watch: {
    preSelectIndex: function (newVal, oldVal) {
      this.switchIndex = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
const REGISTRATION_STATUS = {
  id: "registrationStatus",
  name: "Registration Status",
  type: "select",
  placeholder: "Registration status of user",
  val: null,
  options: [
    {
      key: "fully_verified",
      value: "Verified",
    },
    {
      key: "incomplete_registration",
      value: "Incomplete",
    },
    {
      key: "unverified",
      value: "Un-Verified",
    },
    {
      key: "unverified_plus",
      value: "Un-Verified +",
    },
    {
      key: "unpushed",
      value: "Un-Pushed",
    },
    {
      key: "verification_rejected",
      value: "Rejected",
    },
    {
      key: "re_verifying",
      value: "Re-Verifying",
    },
    {
      key: "blacklisted",
      value: "Black Listed",
    }
  ],
  required: false,
};

const UPDATED_VERIFIED = {
  id: "updatedVerified",
  name: "Verified With Changes",
  type: "select",
  placeholder: "Mark  verified user as updated or not",
  val: null,
  options: [
    {
      key: "true",
      value: "Yes",
    },
    {
      key: "false",
      value: "No",
    },
  ],
  required: false,
};

const GENDER = {
  id: "gender",
  name: "Gender",
  val: null,
  type: "select",
  placeholder: "Select a value for gender",
  options: [
    {
      key: "male",
      value: "Male",
    },
    {
      key: "female",
      value: "Female",
    },
  ],
};

const UNEDITABLE_FIELDS = ["id", "albumPictures", "realPicture"];

const ESSENTIAL_FIELDS = [
  "realPicture",
  "albumPictures",
  "id",
  "nickName",
  "age",
  "registrationStatus",
  "agePreference",
  "gender",
  "genderPreference",
  "school",
  "work",
  "workPosition",
  "location",
  "terribleMemory",
  "selfDescription",
  "mostgratefulfor",
  "expect",
];

const EVENTS_ESSENTIAL_FIELDS = [
  "id",
  "title",
  "adderss",
  "location",
  "eventType",
  "createdAt",
  "gender",
  "genderPreference",
  "hidden",
  "registrationStatus",
  "updatedVerified",
  "verificationScore",
  "friendsCount",
];

const USER_FIELDS = {
  id: "field",
  name: "Field",
  type: "select",
  placeholder: "User model field",
  val: null,
  options: [
    {
      key: "workPosition",
      value: "WorkPosition",
    },
    {
      key: "work",
      value: "Work",
    },
    {
      key: "school",
      value: "School",
    },
    {
      key: "company",
      value: "Company",
    },
  ],
  required: false,
};

const VERIFICATION_SCORE = {
  id: "verificationScore",
  name: "Verification Score",
  type: "select",
  placeholder: "Score for the user based on profile",
  val: null,
  options: [
    {
      key: 1,
      value: "1",
    },
    {
      key: 2,
      value: "2",
    },
    {
      key: 3,
      value: "3",
    },
  ],
  required: true,
};

const CONFIRMATION_KEY = {
  id: "confirmationKey",
  name: "Confirmation Key",
  val: null,
  type: "password",
  placeholder: "Please input the secret password",
};

const GET_ORDER_STATE_NAME = (state) => {
  switch (state) {
    case 0:
      return "Not Paid";
    case 1:
      return "Paid";
    case 2:
      return "Refunded";
    case 3:
      return "Refund In Progress";
    case 4:
      return "Closed";
    case 5:
      return "Pay Error";
    case 6:
      return "Refund Error";
    default:
      return state;
  }
};

const POPOVER_CONDITION_TYPES = {
  id: "conditionType",
  name: "Condition Type",
  type: "select",
  placeholder: "Popover condition type",
  val: null,
  options: [
    {
      key: 1,
      value: "Version",
    },
    {
      key: 2,
      value: "Time Range",
    },
    {
      key: 3,
      value: "Gender",
    },
    {
      key: 4,
      value: "All",
    },
    {
      key: 5,
      value: "Join Time",
    },
  ],
  required: false,
};


const GET_POPOVER_CONDITION_TYPE = (state) => {
  switch (state) {
    case 1:
      return "Version";
    case 2:
      return "Time Range";
    case 3:
      return "Gender";
    case 4:
      return "All";
    case 5:
      return "Join Time";
    default:
      return "Others";
  }
};

const POPOVER_TYPES = {
  id: "popoverType",
  name: "Popover Type",
  type: "select",
  placeholder: "Popover type",
  val: null,
  options: [
    {
      key: 1,
      value: "图+按钮+关闭",
    },
  ],
  required: false,
};

const GET_POPOVER_TYPES = (state) => {
  switch (state) {
    case 1:
      return "图+按钮+关闭";
    default:
      return "Others";
  }
};


const POPOVER_CONFIRM_BTN_TYPES = {
  id: "confirmBtnType",
  name: "Confirm Button Type",
  type: "select",
  placeholder: "Popover confirm button type",
  val: null,
  options: [
    {
      key: 0,
      value: "Hide",
    },
    {
      key: 1,
      value: "Jump to App page",
    },
    {
      key: 2,
      value: "Jump to web page",
    },
    {
      key: 3,
      value: "Jump to activity interface",
    },
  ],
  required: false,
};

const GET_POPOVER_CONFIRM_BTN_TYPES = (state) => {
  switch (state) {
    case 0:
      return "Hide";
    case 1:
      return "Jump to App page";
    case 2:
      return "Jump to web page";
    case 3:
      return "Jump to activity interface";
    default:
      return "Others";
  }
};

const POPOVER_CLOSE_BTN_TYPES = {
  id: "closeBtnType",
  name: "Close Button Type",
  type: "select",
  placeholder: "Popover close button type",
  val: null,
  options: [
    {
      key: 0,
      value: "Hide",
    },
    {
      key: 1,
      value: "Show",
    }
  ],
  required: false,
};

const GET_POPOVER_CLOSE_BTN_TYPES = (state) => {
  switch (state) {
    case 0:
      return "Hide";
    case 1:
      return "Show";
    default:
      return "Others";
  }
};

module.exports = {
  EVENTS_ESSENTIAL_FIELDS,
  REGISTRATION_STATUS,
  UPDATED_VERIFIED,
  GENDER,
  UNEDITABLE_FIELDS,
  ESSENTIAL_FIELDS,
  USER_FIELDS,
  VERIFICATION_SCORE,
  CONFIRMATION_KEY,
  GET_ORDER_STATE_NAME,
  POPOVER_CONDITION_TYPES,
  GET_POPOVER_CONDITION_TYPE,
  POPOVER_TYPES,
  GET_POPOVER_TYPES,
  POPOVER_CONFIRM_BTN_TYPES,
  GET_POPOVER_CONFIRM_BTN_TYPES,
  POPOVER_CLOSE_BTN_TYPES,
  GET_POPOVER_CLOSE_BTN_TYPES
};

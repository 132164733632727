<template>
  <div class="full-page flex-column page-padding">
    <filters
      :fields="filters"
      :processing="processing"
      @onFiltersToggle="onFiltersToggle"
      @onApplyFilters="onApplyFilters"
      @onClearFilters="onClearFilters"
    ></filters>

    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-row">
          <span class="page-title">Events</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <toggler
          :preIndex="order"
          :preSortBy="sortBy"
          :schema="sortingTogglerData"
          @onToggleOrder="onToggleOrder"
          @onToggleSortBy="onToggleSortBy"
        />

        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
        ></pager>

        <div class="download" @click="onClickCreateEvent">
          <img src="../../../assets/icons/ic_add.png" />
          <span>Creat New Event</span>
        </div>

        <div class="download" @click="downloadData">
          <img src="../../../assets/icons/ic_download.png" />
          <span>Download</span>
        </div>
      </div>
      <div
        class="list-wrapper"
        v-if="events.length > 0"
        :class="{ 'loading-history-anim': processing }"
      >
        <table>
          <thead>
            <tr class="header">
              <th class="index">ID</th>
              <th>Creation Date</th>
              <th>Start Time</th>
              <th>Type &<br />Status</th>
              <th>Title</th>
              <th>Address & Location</th>

              <th>Host</th>
              <th>Allow SignUp</th>
              <th>
                SignUp Count &<br />
                SingUp Limit
              </th>
              <th>Publish Status</th>
              <th>Limit User Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in events" class="body">
              <td class="index" style="width: 5%">
                {{ item.id || "--" }}
              </td>
              <td>
                {{
                  item.createdAt
                    ? $momentTZ(item.createdAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY")
                    : "--"
                }}
                <br />
                {{
                  item.createdAt
                    ? $momentTZ(item.createdAt)
                        .tz("Asia/Shanghai")
                        .format("HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                {{
                  item.startTime
                    ? $momentTZ(item.startTime)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY")
                    : "--"
                }}
                <br />
                {{
                  item.startTime
                    ? $momentTZ(item.startTime)
                        .tz("Asia/Shanghai")
                        .format("HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                {{ item.eventType == 1 ? "Online" : "Offline" || "--" }}
                <br />
                {{
                  item.eventStatus ? item.eventStatus.replace("_", " ") : "--"
                }}
              </td>

              <td>
                {{ item.title || "--" }}
              </td>
              <td>
                {{ item.address || "--" }}<br />
                {{ item.location || "--" }}
              </td>
              <td>
                <div class="host">
                  <img
                    :src="item.host.realPicture"
                    alt=""
                    :key="item.host.id"
                  />
                  <div class="info">
                    <span class="title">{{
                      item.host.fullName || item.host.nickName
                    }}</span>
                    <span class="gender">{{ item.host.gender }}</span>
                    <span class="location">{{ item.host.location }}</span>
                  </div>
                </div>
              </td>
              <td>
                {{ item.allowSignUp || "--" }}
              </td>
              <td>
                {{ item.eventSignUps.length || "--" }}<br />
                {{ item.userNumber || "--" }}
              </td>
              <td>{{ item.publishStatus || "--" }}</td>
              <td>
                {{ getUserLimitValue(item.limitUserType) || "--" }}
              </td>

              <td style="width: 30rem">
                <div class="cell-actions">
                  <button @click="onClickDetail(item)">Details</button>
                  <button class="red" @click="onClickDeleteEvent(item)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no events to show here...</span>
      </div>
    </div>

    <transition>
      <updatePoup
        v-if="showUpdatePopup"
        :model="updateModel"
        @onAbort="
          showUpdatePopup = false;
          updateModel = {};
        "
        @onSuccess="
          showUpdatePopup = false;
          updateModel = {};
          getAllEvents();
        "
      ></updatePoup>
    </transition>

    <transition>
      <deleteEventPopup
        v-if="showDeleteEventPopup"
        :model="deletableEvent"
        @onAbort="
          showDeleteEventPopup = false;
          deletableEvent = {};
        "
        @onSuccess="
          showDeleteEventPopup = false;
          deletableEvent = {};
          getAllEvents();
        "
      ></deleteEventPopup>
    </transition>
  </div>
</template>

<script>
import Filters from "../../../components/Filters.vue";
import Pager from "../../../components/Pager.vue";
import UpdateUserStatusPopup from "../../../components/popups/UpdateUserStatusPopup.vue";
import Toggler from "../../../components/MultiToggler.vue";
import DeleteEventPopup from "../../../components/popups/DeleteEventPopup.vue";
const ExcelExporter = require("../../../utils/excel");

export default {
  name: "Events",
  components: {
    filters: Filters,
    pager: Pager,
    updatePoup: UpdateUserStatusPopup,
    toggler: Toggler,
    deleteEventPopup: DeleteEventPopup,
  },
  data() {
    return {
      showDeleteEventPopup: false,
      order: "ASC",
      sortBy: "createdAt",
      showUpdatePopup: false,
      updateModel: {},
      processing: false,
      sortingTogglerData: {
        title: "SORT BY",
        desc: "Creation Date",
        orderOptions: [
          {
            key: "ASC",
            val: "Ascending",
          },
          {
            key: "DESC",
            val: "Descending",
          },
        ],
        sortByOptions: [
          {
            key: "createdAt",
            value: "Creation Date",
          },
          {
            key: "updatedAt",
            value: "Last Updated",
          },
          {
            key: "startTime",
            value: "Start Time",
          },
        ],
      },
      filters: [
        {
          id: "id",
          name: "Event ID",
          type: "text",
          placeholder: "ID of the user",
          val: "",
          required: false,
        },
        {
          id: "title",
          name: "Event Title",
          type: "text",
          placeholder: "Title of the event",
          val: "",
          required: false,
        },
        {
          id: "location",
          name: "Location",
          type: "text",
          placeholder: "Event location",
          val: "",
          required: false,
        },
        {
          id: "address",
          name: "Address",
          type: "text",
          placeholder: "Event address",
          val: "",
          required: false,
        },
        {
          id: "userNumber",
          name: "Attendees Limit",
          type: "number",
          placeholder: "# of attendees allowed",
          val: "",
          required: false,
        },
        {
          id: "limitUserType",
          name: "User Type LImit",
          type: "select",
          placeholder: "Users type limit",
          val: "",
          options: [
            {
              key: 0,
              value: "All",
            },
            {
              key: 1,
              value: "Verified",
            },
            {
              key: 2,
              value: "VIP",
            },
          ],
          required: false,
        },
        {
          id: "eventType",
          name: "Event Type",
          type: "select",
          placeholder: "Online or Offline",
          val: "",
          options: [
            {
              key: 1,
              value: "Online",
            },
            {
              key: 2,
              value: "Offline",
            },
          ],
          required: false,
        },
        {
          id: "eventStatus",
          name: "Event Status",
          type: "select",
          placeholder: "In Progress , started or ended",
          val: "",
          options: [
            {
              key: "in_progress",
              value: "In Progress",
            },
            {
              key: "started",
              value: "Started",
            },
            {
              key: "ended",
              value: "Ended",
            },
          ],
          required: false,
        },
        {
          id: "publishStatus",
          name: "Publish Status",
          type: "select",
          placeholder: "UnPublished or Published",
          val: "",
          options: [
            {
              key: "unpublished",
              value: "UnPublished",
            },
            {
              key: "Published",
              value: "Published",
            },
          ],
          required: false,
        },
        {
          id: "allowSignUp",
          name: "Allow Sign Up",
          type: "select",
          placeholder: "Yes or No",
          val: "",
          options: [
            {
              key: true,
              value: "Yes",
            },
            {
              key: false,
              value: "No",
            },
          ],
          required: false,
        },
      ],
      events: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      filtersData: {},
    };
  },
  methods: {
    downloadData() {
      this.downloading = true;
      console.log("this.events.length = ", this.events.length);
      if (this.count === this.events.length) {
        ExcelExporter.JSONToCSVConvertor(
          this.getParsedList(this.events),
          "Events",
          true
        );
        this.downloading = false;
      } else {
        console.log("The count is not same");
        var order = `createdAt ${this.events}`;
        this.$HTTP
          .post(
            `${this.$URLS.EVENTS.LIST}?order=${order}&page=1&results_per_page=${this.count}`,
            {}
          )
          .then((res) => {
            let events = res.data.data.events;
            ExcelExporter.JSONToCSVConvertor(
              this.getParsedList(events),
              `Events`,
              true
            );
            this.downloading = false;
          })
          .catch(() => {
            this.downloading = false;
          });
      }
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          ID: item.id || "--",
          "Created At": item.createdAt
            ? this.$momentTZ(item.createdAt)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          "Start Time": item.startTime
            ? this.$momentTZ(item.startTime)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          Type: `${item.eventType}` || "--",
          Status: item.eventStatus || "--",
          Title: item.title || "--",
          Loaction: item.location || "--",
          Address: item.address || "--",
          "Host ID": item.host ? item.host.id : "--",
          "Host Name": item.host
            ? item.host.fullName || item.host.nickName
            : "--",
          "Sign Up Count": item.eventSignUps.length || "--",
          "Sign Up Limit": item.userNumber || "--",
          "Allowed Users Type":
            this.getUserLimitValue(item.limitUserType) || "--",
          Content: item.content || "--",
          "# of Images": item.eventImages ? item.eventImages.length : "--",
        });
      });
      return parsed;
    },
    onClickCreateEvent() {
      this.$router.push("/events/create");
    },
    getUserLimitValue(type) {
      switch (type) {
        case 0:
          return "All";
        case 1:
          return "Verified";
        case 2:
          return "VIP";
        default:
          return "UnKnown";
      }
    },
    onClickDeleteEvent(item) {
      this.deletableEvent = item;
      this.showDeleteEventPopup = true;
    },
    onToggleSortBy(e) {
      this.sortBy = e;
      localStorage.setItem("eventsSortBy", e);
      this.getAllEvents();
    },
    onToggleOrder(e) {
      this.order = e;
      localStorage.setItem("eventsOrder", e);
      this.getAllEvents();
    },
    onClearFilters() {
      localStorage.setItem("filters", null);
      this.filtersData = {};
      this.getAllEvents();
    },
    onApplyFilters() {
      var data = {};
      this.filters.forEach((item) => {
        if (item.val) {
          if (item.id == "id") {
            data[item.id] = item.val;
          } else if (item.type == "text") {
            data[item.id] = {
              like: item.val,
              options: "i",
            };
          } else {
            data[item.id] = item.val;
          }
        }
      });
      this.filtersData = data;
      this.getAllEvents();
      localStorage.setItem("filters", JSON.stringify(this.filters));
    },
    onClickDetail(item) {
      this.$router.push({
        name: "EventsDetail",
        query: { id: item.id },
      });
    },
    onClickUpdateStatus(item) {
      this.updateModel = item;
      this.showUpdatePopup = true;
    },
    onPageUpdated(page) {
      this.page = page;
      localStorage.setItem("eventsPage", parseInt(page));
      this.getAllEvents();
    },
    getAllEvents(data) {
      this.processing = true;
      var order = `${this.sortBy} ${this.order}`;
      this.$HTTP
        .post(
          `${this.$URLS.EVENTS.LIST}?order=${order}&page=${this.page}&results_per_page=${this.pageSize}`,
          this.filtersData || {}
        )
        .then((res) => {
          this.events = res.data.data.events;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onFiltersToggle(opened) {
      setTimeout(() => {
        this.processListHeight();
      }, 100);
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 40);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    let page = localStorage.getItem("eventsPage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("eventsOrder") || "DESC";
    this.order = order;

    let sortBy = localStorage.getItem("eventsSortBy") || "createdAt";
    this.sortBy = sortBy;

    let filters = localStorage.getItem("filters");
    if (filters && filters != "null") {
      this.filters = JSON.parse(filters);
      this.onApplyFilters();
    } else {
      this.getAllEvents();
    }
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
.body {
  td {
    overflow-wrap: break-word;

    &:nth-child(1) {
      width: 2rem !important;
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 7% !important;
    }

    &:nth-child(4) {
      width: 10rem;
    }

    &:nth-child(5) {
      max-width: 25rem;
    }

    &:nth-child(6) {
      max-width: 20rem;
    }

    &:nth-child(7) {
      width: 20rem;
    }

    &:last-child {
      max-width: 10rem;
    }
  }
}
.host {
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem;
  align-items: center;

  img {
    width: 6rem;
    height: 6rem;
    border-radius: 0.6rem;
    overflow: hidden;
    margin-right: 1rem;
  }

  .info {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 1.3rem;
      font-family: "P-600";
      color: black;
    }

    .gender,
    .location {
      font-size: 1.2rem;
      font-family: "P-300";
    }
  }
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import moment from "moment";
import momenttimezone from "moment-timezone";
const RongIMLib = require("@rongcloud/imlib-v4");
import { dragscrollNext } from "vue-dragscroll";

import { HTTP, URLS } from "./controllers/Network.js";
const Notifications = require("./controllers/Notifications.js");
import "./assets/styles.css";

import CKEditor from "@ckeditor/ckeditor5-vue";

const app = createApp(App);
app.use(CKEditor);
app.use(router);
app.directive('dragscroll', dragscrollNext);

app.config.globalProperties.$im = RongIMLib.init({
  appkey: process.env.VUE_APP_RONG_APP_KEY,
});

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$momentTZ = momenttimezone;
app.config.globalProperties.$HTTP = HTTP;
app.config.globalProperties.$URLS = URLS;
app.config.globalProperties.$Notifications = Notifications;
app.mount("#app");

<template>
  <div class="full-page flex-column page-padding">
    <filters
      :fields="filters"
      :processing="processing"
      @onFiltersToggle="onFiltersToggle"
      @onApplyFilters="onApplyFilters"
      @onClearFilters="onClearFilters"
    ></filters>

    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-row">
          <span class="page-title">Popovers</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <toggler
          :preIndex="order"
          :schema="sortingTogglerData"
          @onToggle="onToggleOrder"
        />

        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
        ></pager>

        <div
          class="download"
          @click="
            popoverPopupType = 1;
            showPopoverPopup = true;
          "
        >
          <img src="../../../assets/icons/ic_add.png" />
          <span>Creat New Popover</span>
        </div>

        <div class="download" @click="downloadData">
          <img src="../../../assets/icons/ic_download.png" />
          <span>Download</span>
        </div>
      </div>
      <div
        class="list-wrapper"
        v-if="popovers.length > 0"
        :class="{ 'loading-history-anim': processing }"
      >
        <table>
          <thead>
            <tr class="header">
              <th class="index">ID</th>
              <th>
                <br />
                <li style="color: #ec407a">Creation Date (CST)</li>
                <li style="color: #42a5f5">Expire At (CST)</li>
                <li style="color: #8d6e63">Start Time (CST)</li>
                <li style="color: #00c853">End Time (CST)</li>
                <br />
              </th>
              <th>Condition Type</th>
              <th>Confirm Btn Type</th>
              <th>Close Btn Type</th>
              <th>Image</th>
              <th>Payload</th>
              <th>
                <li style="color: #00796b">Confirm Text (EN)</li>
                <li style="color: #00c853">Confirm Text (CN)</li>
                <li style="color: #f44336">Close Text (EN)</li>
                <li style="color: #f50057">Close Text (CN)</li>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in popovers">
              <td class="index" style="width: 5%">
                {{ item.id || "--" }}
              </td>
              <td>
                <li style="color: #ec407a">
                  {{
                    item.createdAt
                      ? $momentTZ(item.createdAt)
                          .tz("Asia/Shanghai")
                          .format("DD-MM-YYYY HH:mm a")
                      : "--"
                  }}
                </li>
                <li style="color: #42a5f5">
                  {{
                    item.expireAt
                      ? $momentTZ(item.expireAt)
                          .tz("Asia/Shanghai")
                          .format("DD-MM-YYYY HH:mm a")
                      : "--"
                  }}
                </li>
                <li style="color: #8d6e63">
                  {{
                    item.startTime
                      ? $momentTZ(item.startTime)
                          .tz("Asia/Shanghai")
                          .format("DD-MM-YYYY HH:mm a")
                      : "--"
                  }}
                </li>
                <li style="color: #00c853">
                  {{
                    item.endTime
                      ? $momentTZ(item.endTime)
                          .tz("Asia/Shanghai")
                          .format("DD-MM-YYYY HH:mm a")
                      : "--"
                  }}
                </li>
              </td>
              <td>
                {{ GET_POPOVER_CONDITION_TYPE(item.conditionType) || "--" }}
              </td>
              <td>
                {{ GET_POPOVER_CONFIRM_BTN_TYPES(item.confirmBtnType) || "--" }}
              </td>
              <td>
                {{ GET_POPOVER_CLOSE_BTN_TYPES(item.closeBtnType) || "--" }}
              </td>
              <td>
                <img
                  :src="item.image"
                  :alt="item.id"
                  :id="item.id"
                  class="cover"
                />
              </td>
              <td>
                {{ item.payload || "--" }}
              </td>
              <td>
                <li style="color: #00796b">
                  {{ item.confirmTextEn || "--" }}
                </li>
                <li style="color: #00c853">
                  {{ item.confirmTextCn || "--" }}
                </li>
                <li style="color: #f44336">
                  {{ item.closeTextEn || "--" }}
                </li>
                <li style="color: #f50057">
                  {{ item.closeTextCn || "--" }}
                </li>
              </td>
              <td style="width: 30rem">
                <div class="cell-actions">
                  <button @click="onClickEdit(item)">Edit</button>
                  <button class="red" @click="onClickDeleteEvent(item)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no popovers to show here...</span>
      </div>
    </div>

    <transition>
      <deletePopup
        v-if="showDeletePopup"
        :model="deletablePopover"
        @onAbort="
          showDeletePopup = false;
          deletablePopover = {};
        "
        @onSuccess="
          showDeletePopup = false;
          deletablePopover = {};
          getAllPopovers();
        "
      ></deletePopup>
    </transition>

    <transition>
      <popoverPopup
        v-if="showPopoverPopup"
        :type="popoverPopupType"
        :model="editablePopoverModel"
        @onAbort="
          showPopoverPopup = false;
          popoverPopupType = -1;
          editablePopoverModel = null;
        "
        @onSuccess="
          showPopoverPopup = false;
          popoverPopupType = -1;
          editablePopoverModel = null;
          getAllPopovers();
        "
      ></popoverPopup>
    </transition>
  </div>
</template>

<script>
import Filters from "../../../components/Filters.vue";
import Pager from "../../../components/Pager.vue";
import Toggler from "../../../components/Toggler.vue";
import DeletePopoverPopup from "../../../components/popups/DeletePopoverPopup.vue";
import PopoverPopup from "../../../components/popups/PopoverPopup.vue";

import {
  POPOVER_CONDITION_TYPES,
  GET_POPOVER_CONDITION_TYPE,
  POPOVER_TYPES,
  GET_POPOVER_TYPES,
  POPOVER_CONFIRM_BTN_TYPES,
  GET_POPOVER_CONFIRM_BTN_TYPES,
  POPOVER_CLOSE_BTN_TYPES,
  GET_POPOVER_CLOSE_BTN_TYPES,
} from "../../../assets/Constants";
const ExcelExporter = require("../../../utils/excel");

export default {
  name: "Popover",
  components: {
    filters: Filters,
    pager: Pager,
    toggler: Toggler,
    deletePopup: DeletePopoverPopup,
    popoverPopup: PopoverPopup,
  },
  data() {
    return {
      showPopoverPopup: false,
      popoverPopupType: -1,
      editablePopoverModel: null,
      showDeletePopup: false,
      order: "ASC",
      processing: false,
      GET_POPOVER_TYPES: GET_POPOVER_TYPES,
      GET_POPOVER_CONFIRM_BTN_TYPES: GET_POPOVER_CONFIRM_BTN_TYPES,
      GET_POPOVER_CLOSE_BTN_TYPES: GET_POPOVER_CLOSE_BTN_TYPES,
      GET_POPOVER_CONDITION_TYPE: GET_POPOVER_CONDITION_TYPE,
      sortingTogglerData: {
        title: "SORT BY",
        desc: "Creation Date",
        options: [
          {
            key: "ASC",
            val: "Ascending",
          },
          {
            key: "DESC",
            val: "Descending",
          },
        ],
      },
      filters: [
        {
          id: "id",
          name: "Popover ID",
          type: "text",
          placeholder: "ID of the user",
          val: "",
          required: false,
        },
        POPOVER_CONDITION_TYPES,
        POPOVER_TYPES,
        POPOVER_CONFIRM_BTN_TYPES,
        POPOVER_CLOSE_BTN_TYPES,
      ],
      popovers: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      filtersData: {},
    };
  },
  methods: {
    downloadData() {
      this.downloading = true;
      if (this.count === this.popovers.length) {
        ExcelExporter.JSONToCSVConvertor(
          this.getParsedList(this.popovers),
          "Articles",
          true
        );
        this.downloading = false;
      } else {
        console.log("The count is not same");
        var order = `createdAt ${this.order}`;
        this.$HTTP
          .post(
            `${this.$URLS.POPOVERS.LIST}?order=${order}&page=1&results_per_page=${this.count}`,
            {}
          )
          .then((res) => {
            let popovers = res.data.data.popovers;
            ExcelExporter.JSONToCSVConvertor(
              this.getParsedList(popovers),
              `Popovers`,
              true
            );
            this.downloading = false;
          })
          .catch(() => {
            this.downloading = false;
          });
      }
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          ID: item.id || "--",
          "Created At": item.createdAt
            ? this.$momentTZ(item.createdAt)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          "Expire At": item.expireAt
            ? this.$momentTZ(item.expireAt)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          "Start Time": item.startTime
            ? this.$momentTZ(item.startTime)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          "End Time": item.endTime
            ? this.$momentTZ(item.endTime)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          "Condition Type":
            `${GET_POPOVER_CONDITION_TYPE(item.conditionType)}` || "--",
          "Confirmation Btn Type":
            `${GET_POPOVER_CONFIRM_BTN_TYPES(item.confirmBtnType)}` || "--",
          "Close Btn Type":
            `${GET_POPOVER_CLOSE_BTN_TYPES(item.closeBtnType)}` || "--",
          Image: item.image || "--",
          Payload: item.payload || "--",
          "Confirm Text EN": item.confirmTextEn || "--",
          "Confirm Text CN": item.confirmTextCn || "--",
          "Close Text CN": item.closeTextEn || "--",
          "Close Text CN": item.closeTextCn || "--",
          Remarks: item.remark || "--",
          Version: item.version || "--",
        });
      });
      return parsed;
    },

    onClickCreateArticle() {
      this.$router.push("/popovers/create");
    },
    getUserLimitValue(type) {
      switch (type) {
        case 0:
          return "All";
        case 1:
          return "Verified";
        case 2:
          return "VIP";
        default:
          return "UnKnown";
      }
    },
    onClickDeleteEvent(item) {
      this.deletablePopover = item;
      this.showDeletePopup = true;
    },
    onToggleOrder(e) {
      this.order = e;
      localStorage.setItem("popoversOrder", e);
      this.getAllPopovers();
    },
    onClearFilters() {
      localStorage.setItem("filters", null);
      this.filtersData = {};
      this.getAllPopovers();
    },
    onApplyFilters() {
      var data = {};
      this.filters.forEach((item) => {
        if (item.val) {
          if (item.id == "id") {
            data[item.id] = item.val;
          } else if (item.type == "text") {
            data[item.id] = {
              like: item.val,
              options: "i",
            };
          } else {
            data[item.id] = item.val;
          }
        }
      });
      this.filtersData = data;
      this.getAllPopovers();
      localStorage.setItem("filters", JSON.stringify(this.filters));
    },
    onClickEdit(item) {
      this.editablePopoverModel = item;
      this.popoverPopupType = 2;
      this.showPopoverPopup = true;
    },
    onPageUpdated(page) {
      this.page = page;
      localStorage.setItem("popoversPage", parseInt(page));
      this.getAllPopovers();
    },
    getAllPopovers(data) {
      this.processing = true;
      var order = `createdAt ${this.order}`;
      this.$HTTP
        .post(
          `${this.$URLS.POPOVERS.LIST}?order=${order}&page=${this.page}&results_per_page=${this.pageSize}`,
          this.filtersData || {}
        )
        .then((res) => {
          this.popovers = res.data.data.popovers;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onFiltersToggle(opened) {
      setTimeout(() => {
        this.processListHeight();
      }, 100);
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 40);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    let page = localStorage.getItem("popoversPage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("popoversOrder") || "DESC";
    this.order = order;

    let filters = localStorage.getItem("filters");
    if (filters && filters != "null") {
      this.filters = JSON.parse(filters);
      this.onApplyFilters();
    } else {
      this.getAllPopovers();
    }
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
.cover {
  height: 8rem;
  margin-top: 0.4rem;
  border-radius: 0.4rem;
}
</style>

<template>
  <div>
    <button v-if="!processing" class="base" :class="{ disabled: !enabled }">
      {{ title }}
    </button>
    <div v-else class="base flex-centered">
      <div class="processing"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonView",
  props: {
    processing: Boolean,
    title: String,
    enabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.base {
  width: 100%;
  height: var(--btn-height);
  background: var(--clr-nine-green);
  border-radius: var(--btn-radius);
  outline: none;
  border: none;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
button {
  color: white;
  cursor: pointer;
  font-size: 1.4rem;
  font-family: "P-600", sans-serif;
  text-transform: uppercase;
  transition: 0.25s all ease-in-out;

  &:hover {
    box-shadow: var(--shaodw);
    transform: scale(1.01);
  }
}

.flex-centered {
  .processing {
    width: 2.5rem;
    height: 2.5rem;
    border-top: 0.3em solid white;
    border-right: 0.3rem solid white;
    border-bottom: 0.3rem solid white;
    border-left: 0.3rem solid transparent;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    animation: rotating 1s linear infinite;
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
<template>
  <div class="popup">
    <div class="card w-30">
      <h1 class="card-title">Delete Article</h1>
      <div class="card-content">
        <h3>
          Are you sure you want to delete this article? This action is
          irreversible and all data related to this article will be lost
          forever.
        </h3>

        <div class="current">
          <h4>YOU ARE ABOUT TO DELETE THE FOLLOWING ARTICLE :</h4>
          <div class="grid">
            <div class="flex-column">
              <span class="key">ID</span>
              <span class="val">{{ model.id }}</span>
            </div>
            <div class="flex-column">
              <span class="key">Title</span>
              <span class="val">{{ model.title }}</span>
            </div>
          </div>
        </div>

        <div class="code-container">
          <inputView
            id="code"
            type="password"
            placeholder="Please input the secret password"
            name="Confirmation Key"
            v-model="code"
            class="input"
            :enableInput="!processing"
            :needBubble="false"
          ></inputView>
        </div>
      </div>

      <div class="actions">
        <button class="bt-simple" @click="$emit('onAbort')">Abort</button>
        <buttonView
          title="Delete"
          :processing="processing"
          :enabled="key == code"
          @click="onClickDelete"
        ></buttonView>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "./Popup.vue";
import InputView from "../InputView.vue";
import ButtonView from "../ButtonView.vue";

import VOtpInput from "vue3-otp-input";

export default {
  name: "DeleteEventPopup",
  components: {
    popup: Popup,
    inputView: InputView,
    buttonView: ButtonView,
    "v-otp-input": VOtpInput,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      processing: false,
      current: {
        for: [],
      },
      code: null,
      randomCode: null,
      key: null,
    };
  },
  methods: {
    onClickDelete() {
      if (this.code != this.key) return;
      console.log("onClickDelete");

      this.processing = true;

      this.$HTTP
        .delete(
          `${this.$URLS.ARTICLES.GET_BY_ID.replace("%id", this.model.id)}`
        )
        .then((res) => {
          console.log("res");
          console.log(res);
          this.processing = false;
          this.$Notifications.showNotification(
            "success",
            "Article deleted successful !"
          );
          this.$emit("onSuccess");
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
          this.processing = false;
        });
    },
    onUpdateCode(e) {
      console.log(`The code entered is ${e}`);
    },
  },
  mounted() {
    this.key = process.env.VUE_APP_REFUND_KEY;
  },
};
</script>

<style lang="scss" scoped>
.code-container {
  margin: 2rem 0rem;
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 1.2rem;
    color: black;
    margin: 1rem 0rem 0.6rem 0rem;
  }

  label {
    width: 310px;
    font-size: 6rem;
    text-align: center;
    font-family: "Big Shoulders Stencil Text", cursive;
    letter-spacing: 0.4rem;
    margin: 2rem auto;
    border-radius: 0.5rem;
    padding: 1rem;
    background: rgba(211, 211, 211, 0.199);
    border: 0.2rem dashed lightgrey;
  }

  .otp {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
}
</style>

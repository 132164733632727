<template>
  <div class="full-page flex-column page-padding">
    <div class="card list grid-list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-row">
          <span class="page-title">Crayons</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <div class="download" @click="onClickAddNew">
          <img src="../../../assets/icons/ic_add.png" />
          <span>Add New Setting</span>
        </div>
      </div>
      <div
        class="list-wrapper"
        v-if="settings.length > 0"
        :class="{ 'loading-history-anim': processing }"
      >
        <div class="grid">
          <div class="grid-item" v-for="item in settings">
            <div class="top">
              <span class="title" v-if="!item.edit">{{ item.title }}</span>

              <input-view
                v-if="item.edit"
                :id="item.id"
                type="text"
                placeholder="enter title"
                v-model="item.title"
                :hideReset="true"
                class="input"
                :enableInput="!processing"
              ></input-view>

              <div class="actions">
                <button
                  class="bt green"
                  v-if="!item.edit"
                  @click="item.edit = true"
                >
                  Edit
                </button>
                <button class="bt green" v-if="item.edit" @click="onSave(item)">
                  Save
                </button>
                <button
                  class="bt abort"
                  v-if="item.edit"
                  @click="onAbort(item)"
                >
                  Abort
                </button>
              </div>
            </div>
            <span class="description" v-if="!item.edit">{{
              item.description
            }}</span>
            <input-view
              v-if="item.edit"
              :id="item.id"
              type="text"
              placeholder="enter description"
              v-model="item.description"
              :hideReset="true"
              class="input"
              :enableInput="!processing"
            ></input-view>

            <div class="kvs">
              <div class="kv">
                <span class="k">Key</span>
                <span class="v" v-if="!item.edit">{{ item.key }}</span>
                <input-view
                  v-if="item.edit"
                  :id="item.id"
                  type="text"
                  placeholder="enter key"
                  v-model="item.key"
                  :hideReset="true"
                  class="input"
                  :enableInput="!processing"
                ></input-view>
              </div>
              <div class="kv">
                <span class="k">Type</span>
                <span class="v" v-if="!item.edit">{{ item.type }}</span>

                <input-view
                  v-if="item.edit"
                  :id="item.id"
                  type="select"
                  :options="typeOptions"
                  placeholder="select type"
                  v-model="item.type"
                  :hideReset="true"
                  class="input"
                  :enableInput="!processing"
                ></input-view>
              </div>
              <div class="kv">
                <span class="k">Value</span>
                <span class="v" v-if="!item.edit">{{ item.value }}</span>

                <input-view
                  v-if="item.edit"
                  :id="item.id"
                  :type="item.type === 'boolean' ? 'select' : 'text'"
                  :options="boolOptions"
                  placeholder="select type"
                  v-model="item.value"
                  :hideReset="true"
                  class="input"
                  :enableInput="!processing"
                ></input-view>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no Crayons to show here...</span>
      </div>
    </div>
  </div>
</template>

<script>
import InputView from "../../../components/InputView.vue";
export default {
  name: "Crayons",
  components: {
    InputView,
  },
  data() {
    return {
      processing: false,
      settings: [],
      typeOptions: [
        { key: "boolean", value: "Bool" },
        { key: "text", value: "Text" },
      ],
      boolOptions: [
        { key: true, value: "true" },
        { key: false, value: "false" },
      ],
    };
  },
  methods: {
    onAbort(item) {
      if (item.id === "new") {
        this.settings.splice(this.settings.indexOf(item), 1);
      } else {
        item.edit = false;
        getAllSettings();
      }
    },
    createNew(item) {
      this.processing = true;
      delete item["edit"];
      delete item["id"];
      // update the key to be in the format crayon_<key>
      item.key = `crayon_${item.key}`;
      this.$HTTP
        .post(this.$URLS.SETTINGS.CREATE, item)
        .then((res) => {
          this.$Notifications.showNotification(
            "success",
            "Setting added successfully"
          );
          this.getAllSettings();
        })
        .catch((e) => {
          this.$Notifications.showNotification("error", e.message);
          this.processing = false;
        });
    },
    onClickAddNew() {
      this.settings.push({
        id: "new",
        title: "",
        description: "",
        type: "boolean",
        value: false,
        edit: true,
      });
    },
    onSave(item) {
      if (item.id === "new") {
        this.createNew(item);
        return;
      }
      this.processing = true;
      delete item["edit"];
      this.$HTTP
        .patch(this.$URLS.SETTINGS.BY_ID.replace("%id", item.id), item)
        .then((res) => {
          this.$Notifications.showNotification("success", "Update successful");
          this.getAllSettings();
        })
        .catch((e) => {
          this.$Notifications.showNotification("error", e.message);
          this.processing = false;
        });
    },
    getAllSettings(data) {
      this.processing = true;
      var order = `createdAt ${this.order}`;
      this.$HTTP
        .post(this.$URLS.SETTINGS.LIST)
        .then((res) => {
          this.settings = res.data.data.filter((item) =>
            item.key.includes("crayon")
          );
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 40);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    this.getAllSettings();
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  margin-top: 0rem !important;
  height: 100% Im !important;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  padding: 2rem;

  .grid-item {
    width: 100%;
    padding: 1rem 0.8rem;
    background: white;
    border-radius: 0.4rem;
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;

    .top {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      .title {
        font-size: 1.4rem;
        color: black;
        font-family: "P-700";
      }

      .actions {
        display: flex;
        flex-direction: row;

        .bt {
          padding: 0.4rem 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1005;
          font-family: "P-500";
          font-size: 1.2rem;
          background: transparent;
          border-radius: 0.4rem;
          transition: all 0.2s ease-in-out;
          border: none;
        }

        .green {
          color: var(--clr-nine-green);
          &:hover {
            background: var(--clr-nine-green);
            color: white;
            font-family: "P-700";
          }
        }

        .abort {
          color: var(--red);
          &:hover {
            background: var(--red);
            color: white;
            font-family: "P-700";
          }
        }
      }
    }

    .description {
      font-size: 1.2rem;
      color: gray;
      margin-top: 1rem;
    }

    .kvs {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: rgba(211, 211, 211, 0.269);
      padding: 1rem;
      margin-top: 1rem;
      border-radius: 0.4rem;

      .kv {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 4rem;
        gap: 1rem;
        align-items: center;

        .k {
          font-size: 1.2rem;
          font-family: "P-600";
          color: black;
        }

        .v {
          font-size: 1.2rem;
          color: black;
        }

        input {
          border: none;
          padding: 0.4rem 1rem;
          outline: none;
        }
      }
    }
  }
}
.cover {
  height: 8rem;
  margin-top: 0.4rem;
  border-radius: 0.4rem;
}
</style>

<template>
  <div class="full-page flex-column page-padding">
    <filters
      :fields="filters"
      :processing="processing"
      @onFiltersToggle="onFiltersToggle"
      @onApplyFilters="onApplyFilters"
      @onClearFilters="onClearFilters"
    ></filters>

    <div class="card list" id="list" ref="list">
      <div class="title-container">
        <div class="flex-centered">
          <span class="page-title">Quitters</span>
          <div class="processing" v-if="processing"></div>
        </div>

        <toggler
          :preIndex="order"
          :schema="sortingTogglerData"
          @onToggle="onToggleOrder"
        />

        <pager
          :current="page"
          :total="totalPages"
          @onPageUpdated="onPageUpdated"
        ></pager>
        <div
          class="processing"
          style="margin-top: 0.5rem"
          v-if="downloading"
        ></div>
        <div class="download" @click="downloadData" v-else>
          <img src="../../../assets/icons/ic_download.png" />
          <span>Download</span>
        </div>
      </div>
      <div class="list-wrapper" v-if="users.length > 0">
        <table>
          <thead>
            <tr class="header">
              <th class="index" style="width: 10%">ID</th>
              <th>Deleted At (CST)</th>
              <th>User Created At (CST)</th>
              <th>Nickname</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Last Location</th>
              <th>Registration Status</th>
              <th>Verification Score</th>
              <th>Friends</th>
              <th>Reason Code</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="index" v-for="(item, index) in users">
              <td class="index" style="width: 10%; padding: 1rem">
                {{ item.id || "--" }}
              </td>
              <td>
                {{
                  item.updatedAt
                    ? $momentTZ(item.updatedAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                {{
                  item.userCreatedAt
                    ? $momentTZ(item.userCreatedAt)
                        .tz("Asia/Shanghai")
                        .format("DD-MM-YYYY HH:mm a")
                    : "--"
                }}
              </td>
              <td>
                {{ item.nickName || "--" }}
              </td>
              <td>
                {{ item.gender || "--" }}
              </td>
              <td>
                {{ item.age || "--" }}
              </td>
              <td>
                {{ item.lastLocation || "--" }}
              </td>
              <td>
                {{ item.registrationStatus || "--" }}
              </td>
              <td>
                {{ item.verificationScore || "--" }}
              </td>
              <td>
                {{ item.numberOfFriends || "--" }}
              </td>
              <td>
                {{ item.reasonCode || "--" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="loading-history"
        :class="{ 'loading-history-anim': processing }"
        v-else
      >
        <img
          src="../../../assets/svg/empty.svg"
          style="width: 30% !important"
          alt=""
        />
        <span>no users to show here...</span>
      </div>
    </div>

    <transition>
      <updatePoup
        v-if="showUpdatePopup"
        :model="updateModel"
        @onAbort="
          showUpdatePopup = false;
          updateModel = {};
        "
        @onSuccess="
          showUpdatePopup = false;
          updateModel = {};
          getAllUsers();
        "
      ></updatePoup>
    </transition>
  </div>
</template>

<script>
import Filters from "../../../components/Filters.vue";
import Pager from "../../../components/Pager.vue";
import UpdateUserStatusPopup from "../../../components/popups/UpdateUserStatusPopup.vue";
const ExcelExporter = require("../../../utils/excel");
import Toggler from "../../../components/Toggler.vue";

export default {
  name: "Quitters",
  components: {
    filters: Filters,
    pager: Pager,
    updatePoup: UpdateUserStatusPopup,
    toggler: Toggler,
  },
  data() {
    return {
      order: "ASC",
      showUpdatePopup: false,
      updateModel: {},
      processing: false,
      sortingTogglerData: {
        title: "SORT BY",
        desc: "Creation Date",
        options: [
          {
            key: "ASC",
            val: "Ascending",
          },
          {
            key: "DESC",
            val: "Descending",
          },
        ],
      },
      filters: [
        {
          id: "id",
          name: "User ID",
          type: "text",
          placeholder: "ID of the user",
          val: "",
          required: false,
        },
        {
          id: "nickName",
          name: "Nick Name",
          type: "text",
          placeholder: "Nick name of the user",
          val: "",
          required: false,
        },
        {
          id: "start",
          name: "Date Range Start",
          type: "date",
          placeholder: "Select the start date for date range",
          val: "",
          required: false,
        },
        {
          id: "end",
          name: "Date Range End",
          type: "date",
          placeholder: "Select the end date for date range",
          val: "",
          required: false,
        },
      ],
      users: [],
      pageSize: 20,
      page: 1,
      count: 0,
      totalPages: 1,
      filtersData: {},
      downloading: false,
    };
  },
  methods: {
    downloadData() {
      this.downloading = true;
      if (this.count === this.users.length) {
        ExcelExporter.JSONToCSVConvertor(
          this.getParsedList(this.users),
          "DeletedAccounts",
          true
        );
        this.downloading = false;
      } else {
        console.log("The count is not same");
        var order = `updatedAt ${this.order}`;
        this.$HTTP
          .post(
            `${this.$URLS.USERS.QUITTERS}?order=${order}&page=1&results_per_page=${this.count}`,
            {}
          )
          .then((res) => {
            let users = res.data.data.users;
            ExcelExporter.JSONToCSVConvertor(
              this.getParsedList(users),
              "DeletedAccounts",
              true
            );
            this.downloading = false;
          })
          .catch(() => {
            this.downloading = false;
          });
      }
    },
    getParsedList(list) {
      var parsed = [];
      list.forEach((item) => {
        parsed.push({
          ID: item.id || "--",
          "Delete At": item.updatedAt
            ? this.$momentTZ(item.updatedAt)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          "User Created At": item.userCreatedAt
            ? this.$momentTZ(item.userCreatedAt)
                .tz("Asia/Shanghai")
                .format("MM/DD/YY")
            : "--",
          Nickname: item.nickName || "--",
          Gender: item.gender || "--",
          Age: item.age || "--",
          "Last Location": item.lastLocation || "--",
          "# of Friends": item.numberOfFriends || "--",
          "Registration Status": item.registrationStatus || "--",
          "Verification Score": item.verificationScore || "--",
          Reason: item.reason || "--",
          "Reason Code": item.reasonCode || "--",
          "Last Location Coordinates": item.lastLocationCoordinates
            ? `Lat : ${item.lastLocationCoordinates.latitude} , Long : ${item.lastLocationCoordinates.longitude}`
            : "--",
        });
      });
      return parsed;
    },
    onToggleOrder(e) {
      this.order = e;
      localStorage.setItem("quittersOrder", e);
      this.getAllUsers();
    },
    onClearFilters() {
      localStorage.setItem("filters", null);
      this.filtersData = {};
      this.getAllUsers();
    },
    onApplyFilters() {
      var data = {};
      console.table(this.filters);

      if (
        (this.filters[2].val && this.filters[3].val == "") ||
        (this.filters[2].val == "" && this.filters[3].val)
      ) {
        this.$Notifications.showNotification(
          "warning",
          "Please select both start and end dates"
        );
        return;
      }

      this.filters.forEach((item) => {
        if (item.val) {
          if (item.type == "text") {
            data[item.id] = {
              like: item.val,
              options: "i",
            };
          } else if (item.type != "date") {
            data[item.id] = item.val;
          }
        }
      });

      if (this.filters[2].val != "" && this.filters[3].val != "") {
        let start = new Date(this.filters[2].val);
        let end = new Date(this.filters[3].val);
        end.setHours(28, 59, 59);
        if (start.getTime() > end.getTime()) {
          this.$Notifications.showNotification(
            "warning",
            "Start date cannot be later then end date"
          );
          return;
        }
        console.log("HAVE DATE");
        data["and"] = [
          { updatedAt: { gt: start } },
          { updatedAt: { lt: end } },
        ];
      }

      this.filtersData = data;
      console.log(JSON.parse(JSON.stringify(data)));
      this.getAllUsers();
      localStorage.setItem("filters", JSON.stringify(this.filters));
    },
    onClickDetail(item) {
      this.$router.push({
        name: "UserDetails",
        query: { id: item.id },
      });
    },
    onClickUpdateStatus(item) {
      this.updateModel = item;
      this.showUpdatePopup = true;
    },
    onPageUpdated(page) {
      this.page = page;
      localStorage.setItem("quittersPage", parseInt(page));
      this.getAllUsers();
    },
    getAllUsers(data) {
      this.processing = true;
      var order = `updatedAt ${this.order}`;
      this.$HTTP
        .post(
          `${this.$URLS.USERS.QUITTERS}?order=${order}&page=${this.page}&results_per_page=${this.pageSize}`,
          this.filtersData || {}
        )
        .then((res) => {
          this.users = res.data.data.users;
          let count = res.data.data.count;
          this.count = count;
          this.totalPages = Math.ceil(count / this.pageSize);
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
        });
    },
    onFiltersToggle(opened) {
      setTimeout(() => {
        this.processListHeight();
      }, 300);
    },
    processListHeight() {
      let bodyRect = document.body.getBoundingClientRect();
      let listRect = this.$refs["list"].getBoundingClientRect();
      let offset = listRect.top - bodyRect.top;
      let height = bodyRect.height - (offset + 30);
      document.getElementById("list").style.height = height + "px";
    },
  },
  mounted() {
    let page = localStorage.getItem("quittersPage") || 1;
    this.page = parseInt(page);

    let order = localStorage.getItem("quittersOrder") || "ASC";
    this.order = order;

    let filters = localStorage.getItem("filters");
    if (filters && filters != "null") {
      this.filters = JSON.parse(filters);
      this.onApplyFilters();
    } else {
      this.getAllUsers();
    }
    this.processListHeight();
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div
    class="full-page flex-column page-padding"
    style="transition: 0.25s all ease-in"
  >
    <div class="flex-row" style="margin-bottom: 2rem">
      <div class="page-nav" @click="$router.back()">
        <img
          src="../../../assets/icons/ic_arrow_down.png"
          alt="Back"
          class="back-btn"
        />
        <span class="page-title">Create Article</span>
        <div class="processing" v-if="processing"></div>
      </div>

      <div class="page-actions" style="margin-left: auto">
        <button class="negative">ABORT</button>
        <button @click="onClickSave">Save</button>
      </div>
    </div>

    <div class="wrapper">
      <div class="left">
        <div class="card flex-column">
          <span class="page-title">DETAILS</span>

          <div class="inner">
            <inputView
              id="title"
              name="Title"
              v-model="editable.title"
              class="input"
              :enableInput="!processing"
              placeholder="Article article title"
              type="string"
            ></inputView>

            <inputView
              id="guidance"
              name="Sub Title"
              v-model="editable.guidance"
              class="input"
              :enableInput="!processing"
              placeholder="Event event sub title"
              type="string"
            ></inputView>

            <inputView
              id="status"
              name="Article Status"
              v-model="editable.status"
              class="input"
              :enableInput="!processing"
              placeholder="Users type allowed"
              type="select"
              :options="articleStatuses"
            ></inputView>
          </div>
        </div>

        <div class="card flex-column" style="margin-top: 3rem">
          <span class="page-title">Cover Image</span>
          <div class="images">
            <div class="add-image">
              <img src="../../../assets/icons/ic_add.png" alt="" />
              <span>Click here to select cover image</span>
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                @change="addImage"
              />
            </div>

            <div class="preview">
              <img
                class="pre"
                :src="files[1] ? files[1].data : article.coverImage"
                alt=""
              />
            </div>
            {{ files[0] }}
          </div>
        </div>
      </div>

      <div class="flex-column">
        <span class="page-title">PREVIEW</span>
        <htmlView :h5="parseContent(editable.content)" />
      </div>

      <div class="right">
        <ckeditor
          id="editor"
          :editor="editor"
          v-model="editable.content"
          :config="editorConfig"
          @ready="onReady"
        ></ckeditor>
      </div>
    </div>

    <div class="uploading" v-if="saving">
      <div class="processing"></div>
      <span>{{ uploading_msgs }}</span>
    </div>
  </div>
</template>

<script>
class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }
  uploadFile(file) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append("file", file);
      formData.append("imgType", "articles");
      HTTP.post(URLS.UPLOAD, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          resolve({
            default: res.data.result,
          });
        })
        .catch((err) => {
          resolve({
            ok: false,
            data: null,
            err: err,
          });
          this.saving = false;
        });
    });
  }
  upload() {
    return this.loader.file.then((file) => this.uploadFile(file));
  }
  abort() {}
}

import { HTTP, URLS } from "../../../controllers/Network";
MyUploadAdapter.prototype.HTTP = HTTP;
MyUploadAdapter.prototype.URLS = URLS;
import InputView from "../../../components/InputView.vue";
import ButtonView from "../../../components/ButtonView.vue";
import KeyValView from "../../../components/KeyValView.vue";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import HtmlView from "../../../components/HtmlView.vue";
export default {
  name: "CreateEvent",
  components: {
    InputView,
    buttonView: ButtonView,
    KeyValView: KeyValView,
    htmlView: HtmlView,
  },
  data() {
    return {
      article: {},
      processing: false,
      data: {},
      articleStatuses: [
        {
          key: "unpublished",
          value: "UnPublished",
        },
        {
          key: "published",
          value: "Published",
        },
      ],
      files: [""],
      rawData: [],
      userSearchString: "",
      processing: false,
      users: [],
      filteredUser: [],
      selectedUser: null,
      uploading_msgs: "Please wait...",
      saving: false,
      editable: {},
      editorOption: {},
      editor: DecoupledEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        extraPlugins: [this.MyCustomUploadAdapterPlugin],
      },
    };
  },
  methods: {
    parseContent(content) {
      if (!content) return "";
      if (!content.includes("!DOCTYPE")) {
        return `<!DOCTYPE html><html lang="en"><head>  <link rel="stylesheet" href="https://oss.meetnine.cn/backend/h5-styles.css" type="text/css"><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0"></head>  <body> ${content}</body</html>`;
      } else {
        return content;
      }
    },
    MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
    addImage(e) {
      const tmpFiles = e.target.files;
      if (tmpFiles.length === 0) {
        return false;
      }
      let file = tmpFiles[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.files.push({
          file: file,
          data: reader.result,
        });
      };
    },
    async onClickSave() {
      if (!this.editable.title) {
        this.$Notifications.showNotification(
          "danger",
          "Please enter article title"
        );
        return;
      }

      if (!this.editable.guidance) {
        this.$Notifications.showNotification(
          "danger",
          "Please enter article title"
        );
        return;
      }

      if (!this.editable.status) {
        this.$Notifications.showNotification(
          "danger",
          "Please select a status"
        );
        return;
      }

      if (!this.editable.content) {
        this.$Notifications.showNotification(
          "danger",
          "Please enter some content"
        );
        return;
      }

      let content = `<!DOCTYPE html><html lang="en"><head>  <link rel="stylesheet" href="https://oss.meetnine.cn/backend/h5-styles.css" type="text/css"><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Document</title></head>  <body> ${this.editable.content}</body</html>`;
      this.saving = true;
      let uploads = await this.uploadImages();
      if (uploads == null) {
        this.$Notifications.showNotification(
          "danger",
          "Please select a cover image"
        );
        this.saving = false;
        return;
      }

      this.editable.coverImage = uploads;
      this.$HTTP
        .post(this.$URLS.ARTICLES.ADD, {
          title: this.editable.title,
          guidance: this.editable.guidance,
          status: this.editable.status,
          content: content,
          author: "nine",
          coverImage: uploads,
        })
        .then((res) => {
          this.saving = false;
          this.$router.back();
        })
        .catch((err) => {
          this.saving = false;
        });
    },
    uploadImages() {
      return new Promise(async (resolve, reject) => {
        if (this.files.length <= 1) {
          resolve(null);
          return;
        }
        if (
          this.files &&
          this.editable &&
          this.editable.eventImages &&
          this.files.length - 1 == this.editable.eventImages.length
        ) {
          resolve(null);
          return;
        }

        let index = 1;
        let uploadedFiles = [];
        while (index < this.files.length) {
          if (this.files[index].file == null && this.files[index].url) {
            uploadedFiles.push(this.files[index].url);
            index++;
            continue;
          }
          const { ok, data, err } = await this.uploadFile(this.files[index]);
          uploadedFiles.push(data.result);
          index++;
        }
        resolve(uploadedFiles[0]);
      });
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        var formData = new FormData();
        formData.append("file", file.file);
        formData.append("imgType", "articles");
        this.$HTTP
          .post(this.$URLS.UPLOAD, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            resolve({
              ok: true,
              data: res.data,
            });
          })
          .catch((err) => {
            resolve({
              ok: false,
              data: null,
              err: err,
            });
            this.saving = false;
          });
      });
    },
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.content-title {
  margin: -2rem;
  padding: 2rem;
  background: var(--clr-nine-green-very-light);
  border-bottom: 0.2rem solid var(--clr-nine-green);
  margin-bottom: 2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.uploading {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .processing {
    width: 10rem !important;
    height: 10rem !important;
  }
  span {
    margin-top: 3rem;
    color: white;
    font-size: 1.4rem;
  }
}
.top {
  width: 100%;
}

.top-wrapper {
  display: grid;
  grid-template-columns: 1fr 50rem;
  gap: 3rem;
}
.wrapper {
  width: 100%;
  height: 90vh;
  display: grid;
  grid-template-columns: 1fr 35rem 1fr;
  gap: 1rem;
  overflow: scroll;
  padding: 0.2rem;

  .left,
  .right {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .inner {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    padding-top: 3rem;
  }

  .contentLabel {
    font-size: 1.2rem;
    color: gray;
    font-family: "P-500", sans-serif;
    text-transform: uppercase;
    padding-left: 0.2rem;
    height: 2.5rem;
    margin-top: 3rem;
  }

  .notes {
    resize: none;
    max-width: 100vw;
    height: 20rem;
    padding: 1rem;
    border: 0.1rem solid lightgray !important;
  }

  .images {
    display: flex;
    padding-top: 3rem;
    transition: 0.25s all ease-in-out;

    .preview {
      height: 20rem;
      position: relative;
      border-radius: 0.4rem;
      overflow: hidden;
      box-shadow: var(--shadow);

      .pre {
        height: 100%;
        flex-shrink: 0;
        min-height: 100%;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        padding: 2rem;
        display: flex;
        width: 100%;
        height: 100%;
        transition: 0.15s all ease-in-out;

        &:hover {
          background: rgba($color: #fff, $alpha: 0.6);
        }

        .delete {
          width: 3rem !important;
          height: 3rem !important;
          margin-left: auto;
          border-radius: 50%;
          border: 0.4rem solid red;
          padding: 0.4rem;
          transition: 0.15s all ease-in-out;

          .del {
            width: 100%;
          }

          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }
    .add-image {
      width: 20rem;
      height: 20rem;
      margin-right: 2rem;
      text-align: center;

      background: var(--clr-nine-green-very-light);
      border: 0.4rem dashed var(--clr-nine-green);
      border-radius: 0.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: 0.15s all ease-in-out;
      padding: 1rem;

      &:hover {
        background: var(--clr-nine-green-light);
      }

      img {
        width: 4rem;
        height: 4rem;
        margin-bottom: 3rem;
      }

      span {
        font-size: 1.2rem;
        font-family: "P-500";
      }

      input {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent;
        opacity: 0;
      }
    }
  }
}
</style>

<template>
  <div class="analytics-title-container">
    <div class="switches">
      <div class="inner">
        <div
          class="switch"
          :class="{ selected: switchIndex == 0 }"
          @click="onClickSwitch(0)"
        >
          Trends
        </div>
        <div
          v-if="!hideDetails"
          class="switch"
          :class="{ selected: switchIndex == 1 }"
          @click="onClickSwitch(1)"
        >
          Details
        </div>
      </div>

      <div class="line" :class="{ 'line-selected': switchIndex == 1 }"></div>
    </div>

    <div class="download" @click="onClickDownload">
      <img src="../assets/icons/ic_download.png" />
      <span>Download</span>
    </div>

    <transition>
      <div class="date-container" v-if="switchIndex == 1">
        <picker
          v-model="internalDate"
          :allowedDates="allowedDates"
          :enableTimePicker="false"
          @update:modelValue="onDateSelected"
          :clearable="false"
        ></picker>
      </div>
    </transition>
  </div>
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

export default {
  name: "AnalyticsTitleContainer",
  props: ["title", "date", "allowedDates", "hideDetails"],
  components: {
    picker: Datepicker,
  },
  data() {
    return {
      switchIndex: 0,
      internalDate: new Date(),
    };
  },
  methods: {
    onClickSwitch(index) {
      this.switchIndex = index;
      this.$emit("onClickSwitch", index);
    },
    onDateSelected(date) {
      this.internalDate = date;
      this.$emit("onDateSelected", date);
    },
    onClickDownload() {
      this.$emit("onClickDownload", {});
    },
  },
  mounted() {
    this.internalDate = this.date;
  },
};
</script>

<style lang="scss" >
.analytics-title-container {
  position: relative;
  display: flex;

  .download {
    margin-left: auto;
  }
}
.date-container {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  border: 0.2rem solid var(--clr-nine-green);
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  background: var(--clr-nine-green-very-light);

  input {
    border: none;
    outline: none;
    font-size: 1.3rem;
    font-family: "P-600";
    text-align: center;
    background: transparent;
  }
}
</style>